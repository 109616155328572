<template>
    <Dialog
        v-model:visible="dialogShown"
        class="p-fluid"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '60vw' }"
    >
        <template #header>
            <h4>{{ headerTitle }}</h4>
        </template>

        <DataTable ref="dt" :total-records="totalRecords" :loading="loadingOffers" :row-hover="true" :value="offers" data-key="id" responsive-layout="scroll" :rows="rowsPerPage" :paginator="true">
            <template #header> </template>
            <template #empty> Δεν βρέθηκαν προσφορές για τον μαθητή. </template>
            <Column header="Ημερομηνία" field="createdAt" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ data.date }}</span>
                </template>
            </Column>
            <Column header="Ποσό" field="offer" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ Utils.toEUR(data.offer) }}</span>
                </template>
            </Column>
            <Column header="Αιτιολογία" field="reason" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ data.reason }}</span>
                </template>
            </Column>
            <Column v-if="isAdmin" header="Χρήστης" field="changedBy" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ data.changedBy }}</span>
                </template>
            </Column>
        </DataTable>

        <div class="col-12">
            <div class="field">
                <label :class="{ 'p-error': v$.offer.$invalid && submitted }">Νέα προσφορά*</label>
                <InputNumber
                    v-model="v$.offer.$model"
                    mode="decimal"
                    :min-fraction-digits="2"
                    locale="el-GR"
                    autocomplete="off"
                    spellcheck="off"
                    placeholder="0"
                    :class="{ 'p-invalid': v$.offer.$invalid && submitted }"
                />
                <small v-if="(v$.offer.$invalid && submitted) || v$.offer.$pending.$response" class="p-error">{{ v$.offer.required.$message }}</small>
            </div>
            <div class="field">
                <label for="reason" :class="{ 'p-error': v$.reason.$invalid && submitted }">Αιτιολογία*</label>
                <Textarea
                    id="reason"
                    v-model="v$.reason.$model"
                    autocomplete="off"
                    spellcheck="off"
                    :class="{ 'p-invalid': v$.reason.$invalid && submitted }"
                    placeholder="Αιτιολογία"
                    rows="3"
                    cols="30"
                />
                <small v-if="(v$.reason.$invalid && submitted) || v$.reason.$pending.$response" class="p-error">{{ v$.reason.required.$message }}</small>
            </div>
        </div>

        <template #footer>
            <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
            <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit(!v$.$invalid)" />
        </template>
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { onMounted, reactive, ref, computed, watchEffect, toRefs } from 'vue';
import { required, integer } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import moment, { utc } from 'moment-timezone';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';

const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const submitted = ref(false);
const headerTitle = ref('');
const state = reactive({
    id: null,
    offer: null,
    reason: null,
});
const rules = {
    offer: { required },
    reason: { required },
};
const totalRecords = ref(0);
const dt = ref(null);
const v$ = useVuelidate(rules, state, { $stopPropagation: true });
const loading = ref(false);
const loadingOffers = ref(false);
const toast = useToast();
const offers = ref([]);
const rowsPerPage = ref(10);
const requestID = ref(null);
const closeCB = ref(null);

// Check if the current user is an admin
const isAdmin = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Admin;
});

onMounted(() => {
    resetForm();
});

const show = async (data, onCloseCB) => {
    let { id, classTitle } = data;
    console.log('requestID:' + id);
    requestID.value = id;
    resetForm();
    closeCB.value = onCloseCB;
    dialogShown.value = true;
    submitted.value = false;
    headerTitle.value = `Δίδακτρα μαθητή για το τμήμα: ${classTitle}`;

    loadingOffers.value = true;
    const res = await RPCApi(RPCMethods.Offers.FetchOffersForRequest, { requestID: id });
    let arr = [];
    if (res.status === 'ok') {
        for (let obj of res.data.result.data) {
            arr.push({
                id: obj.id,
                date: moment(Utils.datetimeToDate(obj.date)).format('DD/MM/YYYY') + ' - ' + moment(Utils.datetimeToDate(obj.date)).format('HH:mm'),
                offer: obj.offer,
                reason: obj.reason,
                changedBy: obj.changed_by_username,
            });
        }
        offers.value = arr;
        totalRecords.value = arr.length;
    }

    loadingOffers.value = false;
};

const onHide = (action = false) => {
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const handleSubmit = async (isFormValid) => {
    submitted.value = true;

    if (!isFormValid) return;

    console.log('ok submit');
    loading.value = true;
    let res;
    let payload = {
        id: requestID.value,
        offer: state.offer,
        reason: state.reason,
    };

    res = await RPCApi(RPCMethods.Offers.AddOfferForRequest, payload);

    if (res.status !== 'ok') {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε ξανά!', life: 3000 });
        loading.value = false;
        return;
    }
    toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });

    loading.value = false;
    onHide(true);
};
const resetForm = () => {
    state.id = null;
    state.offer = null;
    state.reason = null;

    submitted.value = false;
    loading.value = false;
};

defineExpose({
    show,
});
</script>
