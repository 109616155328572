import { defineStore } from 'pinia';
import useStorage from '@/composables/useStorage';
import { ref, computed, watch, readonly } from 'vue';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
const { RPCApi, RPCMethods } = useRPC();

export const useNotificationsStore = defineStore('NotificationsStore', () => {
    const totalUnread = ref(0);
    const totalPostingsExpired = ref(0);
    const intervalID = ref(null);

    const init = () => {
        // console.log('init notifications store');
        if (intervalID.value != null) return;

        intervalID.value = setInterval(async () => {
            // console.log('Count unread messages...');
            if (Utils.UserInfo == null) return;
            try {
                let res = await RPCApi(RPCMethods.Notifications.FetchTotalUnread);
                if (res && res.status === 'ok' && res.data.result) {
                    const { totalUnread: unreadNum } = res.data.result.data;
                    // console.log('unread notifications:' + unreadNum);
                    totalUnread.value = unreadNum;
                }
            } catch (err) {
                console.error(err);
            }
        }, 5 * 60 * 1000);

        intervalID.value = setInterval(async () => {
            // console.log('Count pending postings...');
            if (Utils.UserInfo == null || Utils.UserInfo.role === Utils.Roles.Student) return;
            try {
                let res = await RPCApi(RPCMethods.JobPostings.FetchTotalExpired);
                if (res && res.status === 'ok' && res.data.result) {
                    totalPostingsExpired.value = res.data.result.data.expired;
                }
            } catch (err) {
                console.error(err);
            }
        }, 20 * 60 * 1000);
    };

    const clear = () => {
        console.log('clear notifications store');
        if (intervalID.value != null) {
            clearInterval(intervalID.value);
            intervalID.value = null;
        }
    };

    return { totalUnread: readonly(totalUnread), totalPostingsExpired: readonly(totalPostingsExpired), init, clear };
});
