import moment from 'moment-timezone';
import el from 'moment/locale/el'; // Import the Greek locale data directly

export default () => {
    // Register and set the Greek locale
    moment.locale('el', el);

    // Create a mapping for Greek months
    const greekMonths = {
        January: 'Ιανουαρίου',
        February: 'Φεβρουαρίου',
        March: 'Μαρτίου',
        April: 'Απριλίου',
        May: 'Μαΐου',
        June: 'Ιουνίου',
        July: 'Ιουλίου',
        August: 'Αυγούστου',
        September: 'Σεπτεμβρίου',
        October: 'Οκτωβρίου',
        November: 'Νοεμβρίου',
        December: 'Δεκεμβρίου',
    };

    const formatGreekDate = (date) => {
        const englishDate = moment(date).format('DD MMMM YYYY');
        const [day, month, year] = englishDate.split(' ');
        return `${day} ${greekMonths[month]} ${year}`;
    };

    const convertToEUR = (value) => {
        if (value == null) return '0,00 €';
        return value.toLocaleString('el-GR', { style: 'currency', currency: 'EUR' });
    };

    const PaymentGatewayMode2 = `
        <p>
        <span style="color: rgb(0, 128, 0)"><u>ΤΡΟΠΟΙ ΚΑΤΑΒΟΛΗΣ ΔΙΔΑΚΤΡΩΝ:</u></span>
        <br/>
        Μπορείτε να προχωρήσετε στην αποπληρωμή των διδάκτρων σας:
        <ul>
            <li><u><b>στο ταμείο</b> σε ένα από τα εκπαιδευτήρια μας</u> <i>(Σύνταγμα, Πειραιά)</i></li>
            <li><b>ή με Τραπεζική κατάθεση</b> σε λογαριασμό του Φροντιστηρίου:</li>
        </ul>
        <br/>
        </p>
        <p>
        <b>ALPHA BANK</b>
        <ul>
            <li>IBAN : GR80 0140 1150 1150 0233 0000 360</li>
            <li>Δικαιούχος :  <b>Χ.ΒΙΤΑΛΗ & ΣΙΑ ΟΕ / ΠΑΝΕΠΙΣΤΗΜΙΑΚΑ ΦΡΟΝΤΙΣΤΗΡΙΑ ΒΙΤΑΛΗ</b></li>
        </ul>
        </p>
        <br/><br/>
        <p>
        <b>Τράπεζα Πειραιώς - <u><i> Για όσους καταθέτουν <span style="color: rgb(230, 0, 0)">από</span> Τράπεζα Πειραιώς <span style="color: rgb(230, 0, 0)">(και μόνο)</span></u></i></b>
        <ul>
            <li>IBAN : GR32 0172 0230 0050 2310 2707 093</li>
            <li>Δικαιούχος :  <b>Χ.ΒΙΤΑΛΗ & ΣΙΑ ΟΕ / ΠΑΝΕΠΙΣΤΗΜΙΑΚΑ ΦΡΟΝΤΙΣΤΗΡΙΑ ΒΙΤΑΛΗ</b></li>
        </ul>
        </p>
        <br/><br/>
        <p>
        Στο πεδίο: <b>ΔΙΚΑΙΟΛΟΓΙΑ-ΑΙΤΙΟΛΟΓΙΑ ΠΡΟΣ ΔΙΚΑΙΟΥΧΟ-ΠΛΗΡΟΦΟΡΙΕΣ</b> πρέπει να αναγράφετε <u><span style="color: rgb(0, 0, 230)">το ονοματεπώνυμο του φοιτητή.</span></u>
        </p>
        <br/><br/>
        <p>
        Το <b>ΚΑΤΑΘΕΤΗΡΙΟ</b> θα μας το στείλετε στο email : <span style="color: rgb(0, 0, 230)">info@vitali.gr</span> ώστε να πιστώσουμε άμεσα την καρτέλα σας.
        </p>
        <br/><br/>
        Παραμένουμε στην διάθεσή σας για κάθε διευκρίνιση. 
    `;

    const BankPaymentGateways = `
        <p>
        <span style="color: rgb(0, 128, 0)"><u>ΤΡΟΠΟΙ ΚΑΤΑΒΟΛΗΣ ΔΙΔΑΚΤΡΩΝ:</u></span>
        <br/>
        Μπορείτε να προχωρήσετε στην αποπληρωμή των διδάκτρων σας:
        <ul>
        <li><b>με Τραπεζική κατάθεση</b> <u>σε λογαριασμό του Φροντιστηρίου</u> <i>(Alpha Bank, Piraeus Bank)</i> <i><u>που θα σας αποστείλουμε κατόπιν αιτήματός σας</u></i>.</li>
        <li><u><b>στο ταμείο</b> σε ένα από τα εκπαιδευτήρια μας</u> <i>(Σύνταγμα, Πειραιά)</i></li>
        </ul>
        <br/>
        Για οτιδήποτε χρειάζεστε μην διστάσετε να επικοινωνήσετε μαζί μας στο 210 3001299.
        </p>
    `;

    const LessonReminder = (classTitle, startAt, zoomMeetingURL) => {
        const formattedDate = formatGreekDate(startAt);
        let title = zoomMeetingURL != null ? `Υπενθύμιση μαθήματος "${classTitle}"` : `Επόμενο μάθημα - "${classTitle}" - ${formattedDate}`;
        let body = `
        Το επόμενο μάθημα για το "${classTitle}" έχει προγραμματιστεί την <b> ${formattedDate} </b> και ώρα  <b>${moment(startAt).format('HH:mm')}</b>.
        <br>
        `;
        if (zoomMeetingURL != null) {
            body += `<br/>Για να παρακολουθήσετε το μάθημα σας, συνδεθείτε στην εφαρμογή <a href="https://webapp.vitali.gr">VitaliWebApp</a> την ημέρα και ώρα του μαθήματος και στην συνέχεια στο ημερήσιο πρόγραμμα να κάνετε κλικ πάνω στο μάθημα σας.`;
            body += `<br/><br/>Αν δεν έχετε ακόμα δημιουργήσει λογαριασμό στην εφαρμογή μας θα πρέπει να επικοινωνήσετε μαζί μας για να σας δημιουργήσουμε έναν.`;
        }
        if (zoomMeetingURL != null) {
            body += `<br/><br/>Αν αντιμετωπίζετε πρόβλημα με την σύνδεση σας μην διστάσετε να επικοινωνήσετε μαζί μας είτε τηλεφωνικά στο <a href="tel:2103001299">210-300.12.99</a> είτε μέσω email <a href = "mailto: info@vitali.gr">info@vitali.gr</a>`;
        } else {
            body += `<br/>Η πρόσκληση για το μάθημα θα σας αποσταλεί μια ώρα πριν από την έναρξη του προγράμματος.`;
            body += `<br/><br/>Σε περίπτωση που δεν λάβετε τη πρόσκληση του μαθήματος πρέπει να μας στείλετε άμεσα email στο <a href = "mailto: info@vitali.gr">info@vitali.gr</a> για να σας το στείλουμε ξανά.`;
        }
        body += `<br/><br/><i>*Αν θεωρείτε ότι το email αυτό σας έχει έρθει κατά λάθος μπορείτε να μας ενημερώσετε για να διορθώσουμε το λάθος μας</i>.`;

        return { title, body };
    };

    const FirstLessonReminder = (classTitle, teacherName, startAt) => {
        moment.locale('el');
        let title = `1ο ΜΑΘΗΜΑ - "${classTitle}"`;
        console.log('startAt:', startAt);
        let body = '';
        if (startAt != null) {
            const formattedDate = formatGreekDate(startAt);
            console.log('Formatted date:', formattedDate); // Should now show Greek month

            body = `
                Καλησπέρα σας
                <br>
                Σύμφωνα με την αίτηση σας, σας έχουμε εντάξει στο τμήμα <b>${classTitle}</b>.
                <br>
                Το πρώτο μάθημα για το τμήμα έχει προγραμματιστεί την ${formattedDate} και ώρα έναρξης ${moment(startAt).format('HH:mm')}.
                <br>
                Καθηγητής (-τρια): <b>${teacherName}</b>
                <br>
                `;
            body += `<br/><br/>Η πρόσκληση (σύνδεσμος) για το μάθημα θα έρθει στο email σας, μια ώρα πριν από την προγραμματισμένη έναρξη του μαθήματος. Την ώρα έναρξης απλά θα πρέπει να πατήσετε τον σύνδεσμο του μαθήματος και να συνδεθείτε στην διαδικτυακή μας πλατφόρμα.`;
            body += `<br/><br/>Σε περίπτωση που δεν λάβετε τη πρόσκληση του μαθήματος πρέπει να μας στείλετε άμεσα email στο <a href = "mailto: info@vitali.gr">info@vitali.gr</a> για να σας το στείλουμε ξανά.`;
            body += `<br/><br/>Σε οποιαδήποτε περίπτωση θεωρείτε ότι το email σας έχει αποσταλεί κατα λάθος ή δεν σας βολεύει το πρόγραμμα του μαθήματος μπορείτε να επικοινωνήσετε μαζί μας στο 210-300.12.99`;
            body += `<br/><br/><i>*Το email αυτό σας το στέλνουμε για να σας ενημερώσουμε άμεσα. Μέσα στην ημέρα θα σας καλέσουμε και τηλεφωνικά από το τηλέφωνο 210-300.12.99. Αλλαγή στο πρόγραμμα μπορεί να γίνει αν τελικά δεν σας βολεύει.</i>`;
        } else {
            body = `
                Καλησπέρα σας
                <br>
                Σύμφωνα με την αίτηση σας, σας έχουμε εντάξει στο τμήμα <b>${classTitle}</b>.
                <br>
                Καθηγητής (-τρια): <b>${teacherName}</b>
                <br>
                `;
            body += `<br/><br/>Η πρόσκληση (σύνδεσμος) για το μάθημα θα έρθει στο email σας, μια ώρα πριν από την προγραμματισμένη έναρξη του μαθήματος. Την ώρα έναρξης απλά θα πρέπει να πατήσετε τον σύνδεσμο του μαθήματος και να συνδεθείτε στην διαδικτυακή μας πλατφόρμα.`;
            body += `<br/><br/>Σε περίπτωση που δεν λάβετε τη πρόσκληση του μαθήματος πρέπει να μας στείλετε άμεσα email στο <a href = "mailto: info@vitali.gr">info@vitali.gr</a> για να σας το στείλουμε ξανά.`;
            body += `<br/><br/>Σε οποιαδήποτε περίπτωση θεωρείτε ότι το email σας έχει αποσταλεί κατα λάθος ή δεν σας βολεύει το πρόγραμμα του μαθήματος μπορείτε να επικοινωνήσετε μαζί μας στο 210-300.12.99`;
            body += `<br/><br/><i>*Το email αυτό σας το στέλνουμε για να σας ενημερώσουμε άμεσα. Μέσα στην ημέρα θα σας καλέσουμε και τηλεφωνικά από το τηλέφωνο 210-300.12.99. Αλλαγή στο πρόγραμμα μπορεί να γίνει αν τελικά δεν σας βολεύει.</i>`;
        }
        return { title, body };
    };

    const WelcomeInvitation = (isStudent, alreadyLoggedIn, email) => {
        console.warn('WelcomeInvitation:' + isStudent + ' alreadyLoggedIn? ' + alreadyLoggedIn + ' email:' + email);
        moment.locale('el');
        let title = `Ενεργοποίηση λογαριασμού στη διαδικτυακή εφαρμογή Vitali WebApp`;
        let bodyStudent = alreadyLoggedIn
            ? `
        <p>Καλησπέρα σας,</p>
        <p>Στην εφαρμογή του Φροντιστηρίου έχει δημιουργηθεί ο λογαριασμός σας, ώστε να σας προσφέρει ένα ολοκληρωμένο περιβάλλον εκπαίδευσης</p>
        <p>Για να συνδεθείτε, πατήστε εδώ: <a href='https://webapp.vitali.gr'>WebApp</a></p>
        <p>User Name : ${email}</p>
        <p>Αν έχετε ξεχάσει τον προσωπικό σας κωδικό απλά πατήστε υπενθύμιση και ακολουθήστε την διαδικασία.</p>
        `
            : `
        <p>Καλησπέρα σας,</p>
        <p>Στην εφαρμογή του Φροντιστηρίου έχει δημιουργηθεί ο λογαριασμός σας, ώστε να σας προσφέρει ένα ολοκληρωμένο περιβάλλον εκπαίδευσης</p>
        __temp_credentials__
        <p>Αναλυτικές οδηγίες για την ενεργοποίηση του λογαριασμού μπορείτε να βρείτε εδώ : <a href='https://vitali.gr/vitali-webapp/login-vitali-webapp/'>Οδηγίες</a></p>
        `;

        let bodyTeacher = alreadyLoggedIn
            ? `
            <p>Καλησπέρα σας,</p>
            <p>Σας υπενθυμίζουμε ότι πρέπει να συνδέεστε στον λογαριασμό σας στην διαδικτυακή μας εφαρμογή Vitali WebApp ώστε να μπορείτε να χειρίζεστε τα μαθήματά σας, τα παρουσιολόγια, τις νέες αιτήσεις για μαθήματα και το πρόγραμμα σας</p>
            <p>Η σύνδεση με την εφαρμογή γίνεται στην διεύθυνση <a href='https://webapp.vitali.gr'>WebApp</a></p>
            <p>User Name : ${email}</p>
            <p>Αν έχετε ξεχάσει τον προσωπικό σας κωδικό απλά πατήστε υπενθύμιση και ακολουθήστε την διαδικασία.</p>
            `
            : `
                <p>Καλησπέρα σας,</p>
                <p>Το email αυτό αποτελεί την πρόσκληση για την ενεργοποίηση του λογαριασμού σας στην διαδικτυακή εφαρμογή Vitali <a href='https://webapp.vitali.gr'>WebApp</a>.</p>
                <p>Η διαδικτυακή εφαρμογή έχει αναπτυχθεί ώστε να προσφέρει ένα ολοκληρωμένο σύστημα διαχείρισης του φροντιστηρίου</p>
                __temp_credentials__
                <p>Αναλυτικές οδηγίες για την ενεργοποίηση του λογαριασμού μπορείτε να βρείτε εδώ : <a href='https://vitali.gr/vitali-webapp/login-vitali-webapp/'>Οδηγίες</a></p>
                `;

        let body = isStudent ? bodyStudent : bodyTeacher;

        return { title, body };
    };

    const EconomicsNotification = () => {
        moment.locale('el');
        let title = `Ενημέρωση Οικονομικών`;
        let body = `
        Καλησπέρα σας,
        <br/><br/>
        <p>
        Σας ενημερώνουμε ότι μετά από τον έλεγχο της οικονομικής σας καρτέλας εμφανίζεται υπόλοιπο διδάκτρων για τα μαθήματα που έχετε παρακολουθήσει.
        </p>
        <br/>
        <p>
        <b>Μέσω της πλατφόρμας</b> <a href = "https://vitali.gr/vitali-webapp/">https://vitali.gr/vitali-webapp</a> <b>έχετε τη δυνατότητα δημιουργίας λογαριασμού και προβολής της οικονομικής σας καρτέλας </b> (χρεώσεις μαθημάτων, υπόλοιπο), καθώς και τις συνολικές ώρες που έχουν ολοκληρωθεί ανά μάθημα.
        </p>
        <br/>
        <p>
        <b>Παρακαλούμε για την άμεση τακτοποίηση των διδάκτρων σας και παραμένουμε στην διάθεσή σας για κάθε διευκρίνιση.</b>
        </p>
        `;

        return { title, body };
    };

    const ClassRegistration = (classTitle) => {
        moment.locale('el');
        let title = `Εγγραφή στο μάθημα`;
        let body = `
        Καλησπέρα σας,
        <br/>
        <p>
        <u>καθώς ολοκληρώθηκε</u> το πρώτο μάθημα <span style="color: rgb(230, 0, 0)"><i>"${classTitle}"</i></span>, θα πρέπει να τακτοποιήσετε την καταβολή των διδάκτρων σας.
        </p>
        `;

        return { title, body };
    };

    const SendNotes = () => {
        moment.locale('el');
        let title = `Αποστολή φυλλαδίων`;
        let body = `
        Καλησπέρα σας,
        <br/>
        <p>
        σας ενημερώνουμε ότι έχει δρομολογηθεί η αποστολή νέου πακέτου σημειώσεων μέσω ACS με tracking number: _TRACKING_NUMBER_
        </p>
        `;

        return { title, body };
    };

    const BankAccount = () => {
        moment.locale('el');
        let title = `Τραπεζικός λογαριασμός - Πανεπιστημιακά Φροντιστήρια ΒΙΤΑΛΗ`;
        let body = `
        Καλησπέρα σας,
        <br/><br/>
        <p>
        Οι Τραπεζικοί λογαριασμοί μας είναι :
        </p>
        <br/>
        <p>
        <b>ALPHA BANK</b>
        <ul>
            <li>IBAN : GR80 0140 1150 1150 0233 0000 360</li>
            <li>Δικαιούχος :  <b>Χ.ΒΙΤΑΛΗ & ΣΙΑ ΟΕ / ΠΑΝΕΠΙΣΤΗΜΙΑΚΑ ΦΡΟΝΤΙΣΤΗΡΙΑ ΒΙΤΑΛΗ</b></li>
        </ul>
        </p>
        <br/><br/>
        <p>
        <b>Τράπεζα Πειραιώς - <u><i> Για όσους καταθέτουν <span style="color: rgb(230, 0, 0)">από</span> Τράπεζα Πειραιώς <span style="color: rgb(230, 0, 0)">(και μόνο)</span></u></i></b>
        <ul>
            <li>IBAN : GR32 0172 0230 0050 2310 2707 093</li>
            <li>Δικαιούχος :  <b>Χ.ΒΙΤΑΛΗ & ΣΙΑ ΟΕ / ΠΑΝΕΠΙΣΤΗΜΙΑΚΑ ΦΡΟΝΤΙΣΤΗΡΙΑ ΒΙΤΑΛΗ</b></li>
        </ul>
        </p>
        <br/><br/>
        <p>
        Στο πεδίο: <b>ΔΙΚΑΙΟΛΟΓΙΑ-ΑΙΤΙΟΛΟΓΙΑ ΠΡΟΣ ΔΙΚΑΙΟΥΧΟ-ΠΛΗΡΟΦΟΡΙΕΣ</b> πρέπει να αναγράφετε <u><span style="color: rgb(0, 0, 230)">το ονοματεπώνυμο του φοιτητή.</span></u>
        </p>
        <br/><br/>
        <p>
        Το <b>ΚΑΤΑΘΕΤΗΡΙΟ</b> θα μας το στείλετε στο email : <span style="color: rgb(0, 0, 230)">info@vitali.gr</span> ώστε να πιστώσουμε άμεσα την καρτέλα σας.
        </p>
        `;

        return { title, body };
    };

    const PayClassHoursHalf = (classTitle) => {
        moment.locale('el');
        let title = `ΕΝΗΜΕΡΩΣΗ ΔΙΔΑΚΤΡΩΝ - ΤΜΗΜΑ (μισές διδακτικές ώρες)`;
        let body = `
        Καλησπέρα σας,
        <br/>
        <p>
        Σας ενημερώνουμε ότι θα πρέπει άμεσα να τακτοποιήσετε την <u>αποπληρωμή της δεύτερης δόσης των διδάκτρων</u> για το μάθημα <u><b>"${classTitle}"</u>, καθώς ολοκληρώθηκαν οι μισές διδακτικές ώρες.</b>
        </p>
        <br/>
        ${BankPaymentGateways}
        `;

        return { title, body };
    };

    const PayClassHours = (classTitle, classPrice) => {
        moment.locale('el');
        let classPriceLocalized = convertToEUR(classPrice);
        let classPriceHalfLocalized = convertToEUR(classPrice / 2);
        let title = 'ΕΝΗΜΕΡΩΣΗ ΔΙΔΑΚΤΡΩΝ - ΤΜΗΜΑ';
        let body = `
        Καλησπέρα σας,
        <br/>
        <p>
        το μάθημα ${classTitle} διεξάγεται υπό μορφή τμήματος.
        <br/>
        <b>Τα δίδακτρα (${classPriceLocalized}) καταβάλλονται σε δύο δόσεις.</b>
        <ul>
            <li><b>Η πρώτη (${classPriceHalfLocalized}) καταβάλλεται <u>μετά τη διεξαγωγή του πρώτου μαθήματος και πριν από το δεύτερο μάθημα.</u></b></li>
            <li>(Αν δε γίνει καταβολή διδάκτρων σε αυτό το διάστημα το σύστημα αυτοματοποιημένα μας "απαγορεύει" να σας εντάξουμε στο επόμενο μάθημα.)</li>
            <li><b>Η καταβολή της δεύτερης δόσης των διδάκτρων (${classPriceHalfLocalized}) <u>γίνεται όταν ολοκληρωθούν οι μισές διδακτικές ώρες.</u></b></li>
        </ul>
        </p>
        <br/>
        ${BankPaymentGateways}
        `;

        return { title, body };
    };

    const PayPrivateClassHoursOnePerson = (classTitle, classPrice) => {
        moment.locale('el');
        let classPriceLocalized = convertToEUR(classPrice);
        let priceX4 = convertToEUR(classPrice * 4);
        let title = 'ΕΝΗΜΕΡΩΣΗ ΔΙΔΑΚΤΡΩΝ - ΙΔΙΑΙΤΕΡΟ (1 ΑΤΟΜΟ)';
        let body = `
        Καλησπέρα σας,
        <br/>
        <p>
        το μάθημα ${classTitle} είναι ιδιαίτερο και η χρέωση είναι ${classPriceLocalized} ανά διδακτική ώρα.
        <br/>
        <ul>
            <li><b><u>Η πρώτη καταβολή των διδάκτρων πρέπει να πραγματοποιηθεί μόλις ολοκληρωθεί το πρώτο σας μάθημα</u></b></li>
            <li><b>Κάθε επόμενη καταβολή πρέπει να γίνεται ανά 4 διδακτικές ώρες (4ώρες * ${classPriceLocalized} = ${priceX4}).</b></li>
        </ul>
        </p>
        <br/>
        ${BankPaymentGateways}
        `;

        return { title, body };
    };

    const PayPrivateClassHoursTwoPersons = (classTitle, classPrice) => {
        moment.locale('el');
        let classPriceLocalized = convertToEUR(classPrice);
        let priceX4 = convertToEUR(classPrice * 4);
        let title = 'ΕΝΗΜΕΡΩΣΗ ΔΙΔΑΚΤΡΩΝ - (2 ΑΤΟΜΑ)';
        let body = `
        Καλησπέρα σας,
        <br/>
        <p>
        το μάθημα ${classTitle} είναι ιδιαίτερο 2 ΑΤΟΜΩΝ και η χρέωση είναι ${classPriceLocalized} ανά διδακτική ώρα/άτομο.
        <br/>
        <b><u>Σε περίπτωση απουσίας</b>, σας ενημερώνουμε ότι <b>ισχύει κανονικά η χρέωση, εφόσον πραγματοποιηθεί το μάθημα.</b></u>
        <br/>
        <ul>
            <li><b>Η παραπάνω χρέωση ισχύει εφόσον παραμείνετε 2 άτομα στο τμήμα, διαφορετικά το μάθημα είναι ιδιαίτερο ενός ατόμου με χρέωση ${classPriceLocalized}/διδακτική ώρα.</b></li>
            <li><b>Κάθε επόμενη καταβολή πρέπει να γίνεται ανά 4 διδακτικές ώρες (4ώρες * ${classPriceLocalized} = ${priceX4}). </b></li>
        </ul>
        </p>
        <br/>
        Αν δεν πραγματοποιηθεί καταβολή διδάκτρων σε αυτό το διάστημα, το σύστημα αυτοματοποιημένα μας "απαγορεύει" να ορίσουμε επόμενο μάθημα.
        <br/>
        <br/>
        ${BankPaymentGateways}
        `;

        return { title, body };
    };

    const PayPrivateClassHoursThreePersons = (classTitle, classPrice) => {
        moment.locale('el');
        let classPriceLocalized = convertToEUR(classPrice);
        let priceX4 = convertToEUR(classPrice * 4);
        let title = 'ΕΝΗΜΕΡΩΣΗ ΔΙΔΑΚΤΡΩΝ - ΙΔΙΑΙΤΕΡΟ (3 ΑΤΟΜΩΝ)';
        let body = `
        Καλησπέρα σας,
        <br/>
        <p>
        το μάθημα ${classTitle} είναι ιδιαίτερο 3 ΑΤΟΜΩΝ και η χρέωση είναι ${classPriceLocalized} ανά διδακτική ώρα/ άτομο.
        <br/>
        <b><u>Σε περίπτωση απουσίας</b>, σας ενημερώνουμε ότι <b>ισχύει κανονικά η χρέωση, εφόσον πραγματοποιηθεί το μάθημα.</b></u>
        <br/>
        <ul>
            <li><b>Η παραπάνω χρέωση ισχύει εφόσον παραμείνετε 3 άτομα στο τμήμα. Αν αυτό αλλάξει θα διαμορφωθούν αντίστοιχα και οι χρεώσεις.</b></li>
            <li><b>Κάθε επόμενη καταβολή πρέπει να γίνεται ανά 4 διδακτικές ώρες (4ώρες * ${classPriceLocalized} = ${priceX4} - στην περίπτωση 3 ατόμων). </b></li>
        </ul>
        </p>
        <br/>
        Αν δεν πραγματοποιηθεί καταβολή διδάκτρων σε αυτό το διάστημα, το σύστημα αυτοματοποιημένα μας "απαγορεύει" να ορίσουμε επόμενο μάθημα.
        <br/>
        <br/>
        ${BankPaymentGateways}
        `;

        return { title, body };
    };

    const PayClassPostGraduate = (classTitle, classPrice) => {
        moment.locale('el');
        let classPriceLocalized = convertToEUR(classPrice);
        let priceX4 = convertToEUR(classPrice * 4);
        let title = 'ΕΝΗΜΕΡΩΣΗ ΔΙΔΑΚΤΡΩΝ - ΙΔΙΑΙΤΕΡΟ (3 ΑΤΟΜΩΝ)';
        let body = `
        Καλησπέρα σας,
        <br/>
        <p>
        το μάθημα ${classTitle} είναι ιδιαίτερο (Μεταπτυχιακού Προγράμματος) και η χρέωση είναι ${classPriceLocalized} ανά διδακτική ώρα.
        <br/>
        <ul>
            <li><b><u>Η πρώτη καταβολή των διδάκτρων πρέπει να πραγματοποιηθεί μόλις ολοκληρωθεί το πρώτο σας μάθημα</u></b></li>
            <li><b>Κάθε επόμενη καταβολή πρέπει να γίνεται ανά 4 διδακτικές ώρες (4ώρες * ${classPriceLocalized} = ${priceX4}). </b></li>
        </ul>
        </p>
        <br/>
        Αν δεν πραγματοποιηθεί καταβολή διδάκτρων σε αυτό το διάστημα, το σύστημα αυτοματοποιημένα μας "απαγορεύει" να ορίσουμε επόμενο μάθημα.
        <br/>
        <br/>
        ${BankPaymentGateways}
        `;

        return { title, body };
    };

    const PrepareEsdda = (classTitle, classPrice) => {
        moment.locale('el');
        let title = 'ΕΓΓΡΑΦΗ ΣΤΟ ΠΡΟΓΡΑΜΜΑ ΠΡΟΕΤΟΙΜΑΣΙΑΣ ΓΙΑ ΤΟΝ ΔΙΑΓΩΝΙΣΜΟ ΤΗΣ ΕΣΔΔΑ';
        let body = `
        Καλησπέρα σας,
        <br/>
        <p>
        καθώς ολοκληρώθηκαν τα πρώτα δοκιμαστικά μαθήματα της προετοιμασίας για τον Διαγωνισμό της ΕΣΔΔΑ <b>θα πρέπει να δηλώσετε αν συνεχίζετε την παρακολούθηση των μαθημάτων  Α ΣΤΑΔΙΟΥ και να διευθετήσετε τα δίδακτρα σας, ως εξής:</b>
        <br/>
        <ul>
            <li><b>σε 5 ισόποσες μηνιαίες δόσεις των ${convertToEUR(300)}</b></li>
            <li><b>ή εφάπαξ</b> καταβάλλοντας το ποσό των <b>${convertToEUR(1425)} (έκπτωση 5%)</b></li>
        </ul>
        </p>
        <br/>
        ${PaymentGatewayMode2}
        `;

        return { title, body };
    };

    const NotesEsdda = (classTitle, classPrice) => {
        moment.locale('el');
        let title = 'ΑΡΧΕΙΑ/ΣΗΜΕΙΩΣΕΙΣ ΜΑΘΗΜΑΤΩΝ ΕΣΔΔΑ';
        let body = `
        Καλησπέρα σας,
        <br/>
        <p>
        σας ενημερώνουμε ότι <u>με την εγγραφή σας</u> στο πρόγραμμα προετοιμασίας για τον διαγωνισμό της ΕΣΔΔΑ <b>έχετε άμεση πρόσβαση στα ΑΡΧΕΙΑ & στα ΒΙΝΤΕΟ των μαθημάτων μέσω της πλατφόρμας</b> <a href = "https://vitali.gr/vitali-webapp/">https://vitali.gr/vitali-webapp</a>
        <br/>
        <ul>
            <li>Οι σημειώσεις είναι διαθέσιμες μόνο σε ηλεκτρονική μορφή και <i>όχι σε έντυπη</i>. </li>
            <li>Η πρόσβαση γίνεται από το menu "ΑΡΧΕΙΑ" κάθε τμήματος και έχετε την δυνατότητα αποθήκευσης ή απλά ανάγνωσής τους.</li>
            <li>Κάθε μήνα θα αναρτώνται νέα φυλλάδια ανάλογα με την πρόοδο  των μαθημάτων, πάντα κατόπιν σχετικής ενημέρωσης και αφού πρώτα έχετε καταβάλλει την μηνιαία δόση των διδάκτρων.</li>
        </ul>
        </p>
        <br/>
        <p>
        Συγκεκριμένα αυτή τη στιγμή υπάρχουν διαθέσιμα στην πλατφόρμα:
        <ul>
            <li><b><u>το ΦΥΛΛΑΔΙΟ 1</u></b> του μαθήματος <b>Οργάνωση και Λειτουργία του Κράτους</b></li>
            <li><b><u>το ΦΥΛΛΑΔΙΟ 1</u></b> του μαθήματος <b>Μικροοικονομικής – Μακροοικονομικής και Δημόσιας Οικονομικής</b></li>
        </ul>
        </p>
        <br/>
        <br/>
        Παραμένουμε στην διάθεσή σας για κάθε διευκρίνιση
        `;

        return { title, body };
    };

    /*
        Περιγραφή:

        Το μήνυμα πρέπει να φεύγει αυτόματα για τον καθηγητή μέσα από τον διάλογο των αναθέσεων 
        (δεν χρειάζεται να φεύγει χειροκίνητα). Αν σε μια ανάθεση προσθέσουμε και νέο καθηγητή να
        φεύγει μετά το κουμπί “Επόμενο” και στον νέο καθηγητή (ή να ψάχνει σε ποιους καθηγητές δεν 
        έχει σταλεί το συγκεκριμένο μήνυμα για αυτήν την ανάθεση και να φεύγει).
    */
    const JobPostings = (jobPostingID, subCategory1, subCategory2, courseTitle) => {
        moment.locale('el');
        let title = `Νέα ανάθεση [${jobPostingID}]`;
        let body = `
        Καλησπέρα σας,
        <br />
        σας ενημερώνουμε ότι σας έχουμε στείλει προς έλεγχο ένα νέο μάθημα <b>[${subCategory1} / ${subCategory2} / ${courseTitle}]</b>.
        <br />
        <br />
        Μπορείτε να το ελέγξετε μέσω της εφαρμογής <a href="https://webapp.vitali.gr">Vitali WebApp</a>.
        <br />
        Το προς ανάθεση μάθημα θα το βρείτε κάτω από την επιλογή <b>Αναθέσεις</b>.
        <br />
        <br />
        Αν μέχρι στιγμής δεν έχουν ανέβει σημειώσεις στο σύστημα για να μην χάνουμε χρόνο μπορείτε να δείτε την περιγραφή του μαθήματος στην αντίστοιχη σελίδα του Πανεπιστημίου.
        <br />
        Στην συνέχεια ο φοιτητής θα έχει χρόνο να μας στείλει τις απαραίτητες σημειώσεις. 
        <br />
        <br />
        <b>Στην απάντηση σας καλό είναι να συμπληρώσετε όλα τα απαραίτητα πεδία.</b>
        <br />
        <br />
        Παραμένουμε στην διάθεσή σας για κάθε διευκρίνιση.
        `;

        return { title, body };
    };

    const ZoomLink = (zoomMeetingLink) => {
        moment.locale('el');
        let title = 'Zoom Link';
        let body = `
        Καλησπέρα σας,
        <p>
        σας ενημερώνουμε ότι το zoom link για το μάθημά σας είναι : ${zoomMeetingLink}
        </p>
        <p>
        Παραμένουμε στην διάθεσή σας για κάθε διευκρίνιση
        </p>
        `;

        return { title, body };
    };

    /*
    Το μήνυμα δημιουργείται αυτόματα όταν κάποια από τις αιτήσεις ενός μαθητή μπαίνει σε φραγή. Τότε μπαίνουν σε φραγή όλες οι αιτήσεις του μαθητή και φεύγει το εμαιλ. 
    Η γραμματεία δεν ελέγχει το μήνυμα αλλά μόνο την αλλαγή status της αίτησης.
    */
    const OnHoldReminder = () => {
        moment.locale('el');
        const title = `Ενημερωτικό μήνυμα φραγής καρτέλας σπουδαστή`;
        let body = '';
        body = `
                [αυτοματοποιημένο μήνυμα - λογιστήριο]
                <br />
                <br />
                Καλησπέρα σας.
                <br />
                <br />
                Σε συνέχεια προηγούμενης γραπτής μας ενημέρωσης, σας γνωστοποιούμε πως ο λογαριασμός σας στην διαδικτυακή πλατφόρμα <a href='https://webapp.vitali.gr'>Vitali web app</a>, έχει τεθεί <b>προσωρινά</b> σε κατάσταση <b>φραγής</b>, λόγω ανεξόφλητων διδάκτρων.
                <br />
                <br />
                Στην κατάσταση φραγής δεν μπορείτε να συνδεθείτε σε επόμενα μαθήματα και δεν έχετε πρόσβαση στα αρχεία των μαθημάτων (σημειώσεις), ούτε και στα βιντεοσκοπημένα μαθήματα.
                <br />
                <br />
                Προκειμένου να αποκτήσετε ξανά πλήρη πρόσβαση στον λογαριασμό και στα μαθήματα σας, παρακαλείσθε όπως τακτοποιήσετε άμεσα την οικονομική σας εκκρεμότητα.
                <br />
                <br />
                Παραμένουμε στην διάθεση σας για κάθε διευκρίνιση.
                `;

        return { title, body };
    };

    /*
        Το μήνυμα πρέπει να δημιουργείται μόνο αυτόματα από το κουμπί του διαλόγου στο παρουσιολόγιο. 
        Μόλις πατήσουμε το κουμπί ανοίγει όπως τώρα το παράθυρο με το μήνυμα για τον καθηγητή με προκαθορισμένο κείμενο 
        το παρακάτω (νέο κείμενο). Το μήνυμα βέβαια η γραμματεία μπορεί να το αλλάξει κάνοντας edit. 
        Για τον λόγο αυτό στον τίτλο έχω βάλει κάτι πιο γενικό (ενημερωτικό μήνυμα - τμήμα [τμημα]) ώστε η γραμματεία να μπορεί να γράψει ότι θέλει.
    */
    const NextLessonNotSetReminder = (classTitle) => {
        moment.locale('el');
        const title = `Ενημερωτικό μήνυμα - Τμήμα [${classTitle}]`;
        let body = '';
        body = `
                    Καλησπέρα σας.
                    <br />
                    <br />
                    κατόπιν ελέγχου διαπιστώθηκε ότι για το τμήμα [${classTitle}] δεν ορίστηκε επόμενο μάθημα.
                    <br />
                    <br />
                    Παρακαλείσθε όπως ενημερώσετε την γραμματεία του φροντιστηρίου για το πότε έχει οριστεί το επόμενο μάθημα ώστε να προβεί στις απαραίτητες ενέργειες.
                    <br />
                    <br />
                    <i>Υπενθύμιση :</i>
                    <br />
                    <br />
                    Στα παρουσιολόγια υπάρχει ειδική ένδειξη που δείχνει ότι το μάθημα ήταν το <b>προτελευταίο</b> ή το <b>τελευταίο</b> για το συγκεκριμένο τμήμα. Είναι πολύ σημαντική η σωστή ενημέρωση της γραμματείας για την ορθή λειτουργία του φροντιστηρίου.
                    <br />
                    <br />
                    Παραμένουμε στην διάθεση σας για κάθε διευκρίνιση.
                    `;

        return { title, body };
    };

    /*
    Το μήνυμα δημιουργείται από τον αλγόριθμο οικονομικών αυτόματα χωρίς την μεσολάβηση της γραμματείας. 
    */
    const InstallmentReminder = (amount) => {
        let nowDate = formatGreekDate(new Date());
        let currencyFormatAmount = amount.toLocaleString('el-GR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        const title = `Ενημέρωση καταβολής δόσης διδάκτρων`;
        let body = '';
        body = `
                [αυτοματοποιημένο μήνυμα - λογιστήριο]
                <br />
                <br />
                Καλημέρα σας.
                <br />
                <br />
                Σας υπενθυμίζουμε πως για τα μαθήματα που παρακολουθείτε, σήμερα <b>${nowDate}</b> εκπνέει η περίοδος εξόφλησης της δόσης των διδάκτρων <b>${currencyFormatAmount}€</b>.
                <br />
                <br />
                Μέσω της ηλεκτρονικής πλατφόρμας του φροντιστηρίου <a href='https://webapp.vitali.gr'>Vitali web app</a> έχετε την δυνατότητα προβολής της οικονομικής σας καρτέλας (χρεώσεις μαθημάτων, υπόλοιπο διδάκτρων, όριο ανεξόφλητων διδάκτρων, δόσεις μαθημάτων).
                <br />
                <br />
                Παρακαλούμε για την άμεση τακτοποίηση των διδάκτρων σας.
                <br />
                <br />
                Παραμένουμε στην διάθεση σας για κάθε διευκρίνιση.
                `;

        return { title, body };
    };

    /*
    Το μήνυμα δημιουργείται από τον αλγόριθμο οικονομικών αυτόματα χωρίς την μεσολάβηση της γραμματείας. 
    */
    const RedFlagReminder = () => {
        moment.locale('el');
        const title = `Ενημέρωση πιθανής φραγής καρτέλας σπουδαστή`;
        const body = `
                [αυτοματοποιημένο μήνυμα - λογιστήριο]
                <br />
                <br />
                Καλησπέρα σας.
                <br />
                <br />
                Σας ενημερώνουμε πως ο λογαριασμός σας στην εφαρμογή του φροντιστηρίου <a href='https://webapp.vitali.gr'>Vitali web app</a>, πρόκειται να μπει <b>προσωρινά</b> σε <b>κατάσταση φραγής</b>, λόγω ανεξόφλητων διδάκτρων.
                <br />
                <br />
                Στην κατάσταση φραγής δεν θα μπορείτε να συνδεθείτε σε επόμενα μαθήματα και δεν θα έχετε πρόσβαση στα αρχεία των μαθημάτων, ούτε και στα βιντεοσκοπημένα μαθήματα.
                <br />
                <br />
                Προκειμένου να παραμείνει ο λογαριασμός σας ενεργός, παρακαλείστε να τακτοποιήσετε άμεσα την οικονομική σας εκκρεμότητα.
                <br />
                <br />
                Σας θυμίζουμε ότι μέσω της ηλεκτρονικής πλατφόρμας του φροντιστηρίου <a href='https://webapp.vitali.gr'>Vitali web app</a>, έχετε την δυνατότητα προβολής της οικονομικής σας καρτέλας (χρεώσεις μαθημάτων, υπόλοιπο διδάκτρων, όριο ανεξόφλητων διδάκτρων, δόσεις μαθημάτων).
                <br />
                <br />
                Παραμένουμε στην διάθεση σας για κάθε διευκρίνιση.
                `;

        return { title, body };
    };

    /*
    Πότε δημιουργείται. 
    Το μήνυμα δημιουργείται από τον αλγόριθμο οικονομικών αυτόματα χωρίς την μεσολάβηση της γραμματείας. 
    */
    const AmountDuesReminder = (amount, limit) => {
        moment.locale('el');
        let currencyFormatAmount = amount.toLocaleString('el-GR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
        const title = `Ενημέρωση καταβολής διδάκτρων`;
        const body = `
                [αυτοματοποιημένο μήνυμα - λογιστήριο]
                <br />
                <br />
                Καλησπέρα σας.
                <br />
                <br />
                Σας ενημερώνουμε πως για τα μαθήματα που παρακολουθείτε με χρέωση ανά διδακτική ώρα, το ανεξόφλητο ποσό των διδάκτρων σας ανέρχεται στα <b>[${currencyFormatAmount}€]</b>.
                <br />
                <br />
                Όπως έχετε ήδη ενημερωθεί το όριο διδάκτρων ανέρχεται σε <b>[${limit}€]</b>.
                <br />
                <br />
                Μέσω της ηλεκτρονικής πλατφόρμας του φροντιστηρίου <a href='https://webapp.vitali.gr'>Vitali web app</a> έχετε την δυνατότητα προβολής της οικονομικής σας καρτέλας (χρεώσεις μαθημάτων, υπόλοιπο διδάκτρων, όριο ανεξόφλητων διδάκτρων, δόσεις μαθημάτων).
                <br />
                <br />
                Παρακαλούμε για την άμεση τακτοποίηση των διδάκτρων σας.
                <br />
                <br />
                Παραμένουμε στην διάθεση σας για κάθε διευκρίνιση.
                `;

        return { title, body };
    };

    const UnreadChatMessages = (unreadMessagesForUser) => {
        const title = `Έχετε αδιάβαστα μηνύματα στο chat!`;
        const body = `
                Έχετε ${unreadMessagesForUser} αδιάβαστα μηνύματα στο support chat, παρακαλώ ελέγξτε τα.
                <br />
                <br />
                Μπορείτε να δείτε τα αδιάβαστα μηνύματα εδώ: <a href='https://webapp.vitali.gr/chat'>https://webapp.vitali.gr/chat</a>
                `;
        return { title, body };
    };

    const TeacherDeletedFile = (username, courseTitle, fileName, classTitle) => {
        const title = `Έγινε προσπάθεια διαγραφής αρχείου από καθηγητή`;
        const body = `
                Καθηγητής: ${username}
                <br/>
                Μάθημα: ${courseTitle}
                <br/>
                Αρχείο: ${fileName}
                <br/>
                Τμήμα: ${classTitle}`;
        return { title, body };
    };

    const RequestPasswordReset = (email, link) => {
        const title = `Αλλαγή κωδικού πρόσβασης`;
        const body = `
                <p style="margin: 0px">Γειά σας,</p>
                <br />
                <p style="margin: 0px">Ακολουθήστε το παρακάτω link για να αλλάξετε τον κωδικό σας για τον λογαριασμό <b>${email}</b>.</p>
                <br />
                <p style="margin: 0px"><a href='${link}'>${link}</a></p>
                <br />
                <p style="margin: 0px">Σε περίπτωση που δεν ζητήσατε αλλαγή κωδικού μπορείτε απλά να αγνοήσετε αυτό το email.</p>
                `;
        return { title, body };
    };

    const PasswordResetSuccess = (email) => {
        const title = `Επιτυχής Αλλαγή Password`;
        const body = `
                <p style="margin: 0px">O κωδικός για τον λογαριασμό <b>${email}</b> άλλαξε με επιτυχία!</p>
                <br />
                `;
        return { title, body };
    };

    const AvailableTemplates = [
        { id: 1, value: LessonReminder, label: 'Υπενθύμιση Μαθήματος' },
        { id: 2, value: FirstLessonReminder, label: 'Πρώτο Μάθημα' },
        { id: 3, value: WelcomeInvitation, label: 'Πρόσκληση WebApp' },
        { id: 4, value: EconomicsNotification, label: 'Ενημέρωση Οικονομικών' },
        { id: 5, value: ClassRegistration, label: 'Εγγραφή στο μάθημα' },
        { id: 6, value: SendNotes, label: 'Αποστολή φυλλαδίων' },
        { id: 7, value: BankAccount, label: 'Τραπεζικός λογαριασμός' },
        { id: 8, value: PayClassHoursHalf, label: 'Ενημέρωση Διδάκτρων - Τμήμα (μισές διδακτικές ώρες)' },
        { id: 9, value: PayClassHours, label: 'Ενημέρωση Διδάκτρων - Τμήμα' },
        { id: 10, value: PayPrivateClassHoursOnePerson, label: 'Ενημέρωση Διδάκτρων - Ιδιαιτερό (1 Ατομό)' },
        { id: 11, value: PayPrivateClassHoursTwoPersons, label: 'Ενημέρωση Διδάκτρων - Ιδιαιτερό (2 Ατομα)' },
        { id: 12, value: PayPrivateClassHoursThreePersons, label: 'Ενημέρωση Διδάκτρων - Ιδιαιτερό (3 Ατομα)' },
        { id: 13, value: PayClassPostGraduate, label: 'Ενημέρωση Διδάκτρων - Μεταπτυχιακό' },
        { id: 14, value: PrepareEsdda, label: 'Εγγραφή στο πρόγραμμα προετοιμασίας για τον διαγωνισμό της ΕΣΔΔΑ' },
        { id: 15, value: NotesEsdda, label: 'Αρχεία/Σημειώσεις μαθημάτων ΕΣΔΔΑ' },
        { id: 16, value: ZoomLink, label: 'Zoom Link' },
        { id: 17, value: OnHoldReminder, label: 'Φραγή Καρτέλας Σπουδαστή' },
        { id: 18, value: NextLessonNotSetReminder, label: 'Μη ορισμένο επόμενο μάθημα' },
        { id: 19, value: null, label: 'Άλλο' },
    ];

    return {
        LessonReminder,
        FirstLessonReminder,
        WelcomeInvitation,
        EconomicsNotification,
        ClassRegistration,
        SendNotes,
        BankAccount,
        PayClassHoursHalf,
        PayClassHours,
        PayPrivateClassHoursOnePerson,
        PayPrivateClassHoursTwoPersons,
        PayPrivateClassHoursThreePersons,
        PayClassPostGraduate,
        PrepareEsdda,
        NotesEsdda,
        JobPostings,
        ZoomLink,
        OnHoldReminder,
        NextLessonNotSetReminder,
        AmountDuesReminder,
        InstallmentReminder,
        RedFlagReminder,
        UnreadChatMessages,
        TeacherDeletedFile,
        RequestPasswordReset,
        PasswordResetSuccess,
        AvailableTemplates,
    };
};
