<template>
    <Dialog v-model:visible="dialogShown" :close-on-escape="false" :dismissable-mask="false" :modal="true" style="width: 95vw" @hide="onHide">
        <template #header>
            <div class="flex flex-row justify-content-between align-content-center">
                <h4>Νέα Ανάθεση</h4>
            </div>
        </template>
        <transition :name="transitionName" mode="out-in">
            <div v-if="!requestSpecific && viewPage === 0">
                <div v-if="totalSelectedRequests.length > 0" class="card">
                    <div class="flex flex-column gap-2">
                        <div class="flex flex-row gap-4 w-full">
                            <div class="flex flex-column gap-2">
                                <span style="font-weight: 800">Μάθημα: </span>
                                <span style="font-weight: 800">Εξεταστική: </span>
                                <span style="font-weight: 800">Αιτήσεις ({{ totalSelectedRequests.length }}):</span>
                                <span v-if="selectedTeachers.length > 0" style="font-weight: 800">Επιλεγμένοι ({{ selectedTeachers.length }}):</span>
                            </div>
                            <div class="flex flex-column gap-2">
                                <div v-if="state.selectedCourse != null" class="w-full">
                                    <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showCourseDialog(state.selectedCourse.id)">
                                        <span>
                                            {{ state.selectedCourse.subCateg1 }} / {{ state.selectedCourse.subCateg2 }} / {{ state.selectedCourse.title }}
                                            {{ state.selectedCourse.semester != null ? '(' + state.selectedCourse.semester + ')' : '' }}</span
                                        >
                                    </a>
                                </div>
                                <span style="font-weight: normal">{{ totalSelectedRequests.length > 0 ? state.examPeriod : '' }}</span>
                                <div class="flex flex-row gap-2 flex-wrap">
                                    <div v-for="(request, index) in totalSelectedRequests" :key="request" class="flex flex-row gap-2 align-items-center">
                                        <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showRequestsDialog(request)">
                                            <span>{{ request }}{{ index == totalSelectedRequests.length - 1 ? '' : ',' }}</span>
                                        </a>
                                    </div>
                                </div>
                                <div v-if="selectedTeachers.length > 0" class="flex flex-row gap-2 flex-wrap">
                                    <div v-for="(teacher, index) in selectedTeachers" :key="teacher.id" class="flex flex-row gap-2 align-items-center">
                                        <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showTeacherDialog(teacher.id)">
                                            <span>{{ teacher.fullname }}{{ index === selectedTeachers.length - 1 ? '' : ',' }}</span>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row justify-content-between">
                    <div class="flex flex-column w-full p-2">
                        <h5>Επιλέξτε τις αιτήσεις προς ανάθεση</h5>
                        <DataTable
                            ref="dt1"
                            :total-records="totalCourses"
                            :row-hover="true"
                            :value="coursesRequests"
                            data-key="id"
                            responsive-layout="scroll"
                            :rows="coursesRequestsRowsPerPage"
                            :paginator="true"
                        >
                            <template #header>
                                <div class="flex flex-row justify-content-between mb-2">
                                    <div class="h-3 pr-2">
                                        <AutoComplete
                                            v-model="selectedCoursesFilters['category'].value"
                                            :suggestions="filteredCategories"
                                            force-selection
                                            field="name"
                                            placeholder="Κατηγορία"
                                            :dropdown="true"
                                            class="w-25rem"
                                            @item-select="onCategorySelect($event, 'course')"
                                            @complete="searchCategory($event, 'course')"
                                        >
                                        </AutoComplete>
                                    </div>
                                    <div class="h-3 pr-2">
                                        <AutoComplete
                                            v-model="selectedCoursesFilters['subCateg1'].value"
                                            force-selection
                                            :suggestions="filteredSubCategories1"
                                            placeholder="Υπ/ρία Ι"
                                            :dropdown="true"
                                            class="w-25rem"
                                            @item-select="onSubCateg1Select($event, 'course')"
                                            @complete="searchSubCategory1($event, 'course')"
                                        >
                                        </AutoComplete>
                                    </div>
                                    <div class="h-3 pr-2">
                                        <AutoComplete
                                            v-model="selectedCoursesFilters['subCateg2'].value"
                                            force-selection
                                            :suggestions="filteredSubCategories2"
                                            placeholder="Υπ/ρία ΙI"
                                            :dropdown="true"
                                            class="w-25rem"
                                            @item-select="onSubCateg2Select($event, 'course')"
                                            @complete="searchSubCategory2($event, 'course')"
                                        >
                                        </AutoComplete>
                                    </div>
                                    <div class="h-3 pr-2">
                                        <Dropdown
                                            v-model="selectedCoursesFilters['examPeriod'].value"
                                            :show-clear="true"
                                            option-label="label"
                                            :options="examPeriods"
                                            placeholder="Εξεταστική"
                                            @change="filterCoursesByExamPeriod"
                                        >
                                            <template #value="slotProps">
                                                <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                                                <span v-else>Εξεταστική</span>
                                            </template>
                                            <template #option="slotProps">
                                                <span>{{ slotProps.option.label }}</span>
                                            </template>
                                        </Dropdown>
                                    </div>
                                    <Button icon="pi pi-filter-slash" class="p-button-text" @click="resetGlobalFilters('course')" />
                                </div>
                            </template>
                            <template #empty> Δεν βρέθηκαν μαθήματα με αιτήσεις. </template>
                            <Column header="Υπ/ρία Ι" filter-field="subCateg1" field="subCateg1" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                                <template #body="{ data }">
                                    {{ data.subCateg1 }}
                                </template>
                            </Column>
                            <Column header="Υπ/ρία ΙI" filter-field="subCateg2" field="subCateg2" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                                <template #body="{ data }">
                                    {{ data.subCateg2 }}
                                </template>
                            </Column>
                            <Column header="Μάθημα" filter-field="title" field="title" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                                <template #body="{ data }"> {{ data.title }} {{ data.semester != null ? '(' + data.semester + ')' : '' }} </template>
                            </Column>
                            <Column header="Αιτήσεις" filter-field="requests" field="requests" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                                <template #body="{ data }">
                                    <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showCoursesRequestsDialog(data.id)">{{ data.requests }}</a>
                                </template>
                            </Column>
                            <Column :header="`Δεν Ανατέθηκαν (${sumNumNotPosted})`" field="numNotPosted" style="min-width: 10rem" :show-filter-menu="false" :sortable="true">
                                <template #body="{ data }"> {{ data.numNotPosted }}</template>
                            </Column>
                        </DataTable>
                    </div>
                </div>
            </div>

            <div v-else-if="totalSelectedRequests.length > 0 && viewPage === 1">
                <div class="card">
                    <div v-if="totalSelectedRequests.length > 0">
                        <div class="flex flex-column gap-2">
                            <div class="flex flex-row gap-4 w-full">
                                <div class="flex flex-column gap-2">
                                    <span style="font-weight: 800">Μάθημα: </span>
                                    <span style="font-weight: 800">Εξεταστική: </span>
                                    <span style="font-weight: 800">Αιτήσεις ({{ totalSelectedRequests.length }}):</span>
                                    <span v-if="selectedTeachers.length > 0" style="font-weight: 800">Επιλεγμένοι ({{ selectedTeachers.length }}):</span>
                                </div>
                                <div class="flex flex-column gap-2">
                                    <div v-if="state.selectedCourse != null" class="w-full">
                                        <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showCourseDialog(state.selectedCourse.id)">
                                            <span>
                                                {{ state.selectedCourse.subCateg1 }} / {{ state.selectedCourse.subCateg2 }} / {{ state.selectedCourse.title }}
                                                {{ state.selectedCourse.semester != null ? '(' + state.selectedCourse.semester + ')' : '' }}</span
                                            >
                                        </a>
                                    </div>
                                    <span style="font-weight: normal">{{ totalSelectedRequests.length > 0 ? state.examPeriod : '' }}</span>
                                    <div class="flex flex-row gap-2 flex-wrap">
                                        <div v-for="(request, index) in totalSelectedRequests" :key="request" class="flex flex-row gap-2 align-items-center">
                                            <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showRequestsDialog(request)">
                                                <span>{{ request }}{{ index == totalSelectedRequests.length - 1 ? '' : ',' }}</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div v-if="selectedTeachers.length > 0" class="flex flex-row gap-2 flex-wrap">
                                        <div v-for="(teacher, index) in selectedTeachers" :key="teacher.id" class="flex flex-row gap-2 align-items-center">
                                            <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showTeacherDialog(teacher.id)">
                                                <span>{{ teacher.fullname }}{{ index === selectedTeachers.length - 1 ? '' : ',' }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <h5>Επιλέξτε τους καθηγητές προς ανάθεση</h5>
                <DataTable
                    ref="dt"
                    v-model:filters="tableTeachersFilters"
                    v-model:selection="selectedTeachers"
                    :total-records="totalTeachers"
                    :lazy="true"
                    :row-hover="true"
                    :value="teachers"
                    filter-display="row"
                    data-key="id"
                    responsive-layout="scroll"
                    @sort="onTeachersSort($event)"
                    @filter="onTeachersFilter($event)"
                >
                    <template #empty> Δεν βρέθηκαν καθηγητές. </template>
                    <Column selection-mode="multiple" header-style="width: 3rem"></Column>
                    <Column header="Ονοματεπώνυμο" field="fullname" filter-field="lname" :sortable="true" :show-filter-menu="false">
                        <template #body="{ data }">
                            <div class="flex cursor-pointer" style="color: blue" @click="showTeacherDialog(data.id)">
                                <Avatar :image="Utils.GetGenericAvatar(data.id)" class="flex align-items-center border-1 border-300 ml-4" size="medium" shape="circle" />
                                <span class="flex-initial flex align-items-center justify-content-center ml-2">{{ data.fullname }}</span>
                            </div>
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Εύρεση βάση ονόματος" @input="filterCallback()" />
                        </template>
                    </Column>
                    <Column header="Ειδικότητα" field="departments" filter-field="departments" :sortable="true" :show-filter-menu="false" style="max-width: 22rem">
                        <template #body="{ data }">
                            <span v-for="(item, index) in data.departments" :key="item.departmentID">{{ item.departmentName }} <span v-if="index < data.departments.length - 1">, </span></span>
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <MultiSelect v-model="filterModel.value" :options="departments" placeholder="Όλα" class="p-column-filter" :showClear="true" @change="filterCallback()">
                                <template #value="slotProps">
                                    <span v-if="slotProps.value && slotProps.value.length">
                                        {{ slotProps.value.map((option) => option.label).join(', ') }}
                                    </span>
                                    <span v-else>Όλα</span>
                                </template>
                                <template #option="slotProps">
                                    <span>{{ slotProps.option?.label }}</span>
                                </template>
                            </MultiSelect>
                        </template>
                    </Column>
                    <Column header="Μαθήματα" field="departmentCourses" filter-field="departmentCourses" :show-filter-menu="false">
                        <template #body="{ data }">
                            {{ data.departmentCourses }}
                        </template>
                        <template #filter="{ filterModel, filterCallback }">
                            <InputText v-model="filterModel.value" type="text" class="p-column-filter" placeholder="Εύρεση βάση μαθήματος" @input="filterCallback()" />
                        </template>
                    </Column>
                    <Column header="Ενεργά τμήματα" :show-filter-menu="false">
                        <template #body="{ data }">
                            <div class="flex flex-row gap-2 flex-wrap">
                                {{ data.activeClasses?.length }}
                                <!-- <span v-for="cls in data.activeClasses" :key="cls.classID">
                                    <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showClassDialog(cls.classID)">
                                        <span>{{ cls.classID }}</span>
                                    </a>
                                </span> -->
                            </div>
                        </template>
                    </Column>
                    <Column header="Ίδια τμήματα" field="classesForCourse" :show-filter-menu="false">
                        <template #body="{ data }">
                            <div class="flex flex-row gap-2 flex-wrap">
                                {{ data.classesForCourse?.length }}
                                <!-- <span v-for="cls in data.classesForCourse" :key="cls.classID">
                                    <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showClassDialog(cls.classID)">
                                        <span>{{ cls.classID }}</span>
                                    </a>
                                </span> -->
                            </div>
                        </template>
                    </Column>
                    <Column header="Θετικές απαντήσεις" :show-filter-menu="false">
                        <template #body="{ data }"> {{ data.positiveApplications }} </template>
                    </Column>
                </DataTable>
            </div>

            <div v-else-if="selectedTeachers.length > 0 && viewPage === 2">
                <div class="card">
                    <div v-if="totalSelectedRequests.length > 0">
                        <div class="flex flex-column gap-2">
                            <div class="flex flex-row gap-4 w-full">
                                <div class="flex flex-column gap-2">
                                    <span style="font-weight: 800">Μάθημα: </span>
                                    <span style="font-weight: 800">Εξεταστική: </span>
                                    <span style="font-weight: 800">Αιτήσεις ({{ totalSelectedRequests.length }}):</span>
                                    <span v-if="selectedTeachers.length > 0" style="font-weight: 800">Επιλεγμένοι ({{ selectedTeachers.length }}):</span>
                                </div>
                                <div class="flex flex-column gap-2">
                                    <div v-if="state.selectedCourse != null" class="w-full">
                                        <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showCourseDialog(state.selectedCourse.id)">
                                            <span>
                                                {{ state.selectedCourse.subCateg1 }} / {{ state.selectedCourse.subCateg2 }} / {{ state.selectedCourse.title }}
                                                {{ state.selectedCourse.semester != null ? '(' + state.selectedCourse.semester + ')' : '' }}</span
                                            >
                                        </a>
                                    </div>
                                    <span style="font-weight: normal">{{ totalSelectedRequests.length > 0 ? state.examPeriod : '' }}</span>
                                    <div class="flex flex-row gap-2 flex-wrap">
                                        <div v-for="(request, index) in totalSelectedRequests" :key="request" class="flex flex-row gap-2 align-items-center">
                                            <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showRequestsDialog(request)">
                                                <span>{{ request }}{{ index == totalSelectedRequests.length - 1 ? '' : ',' }}</span>
                                            </a>
                                        </div>
                                    </div>
                                    <div v-if="selectedTeachers.length > 0" class="flex flex-row gap-2 flex-wrap">
                                        <div v-for="(teacher, index) in selectedTeachers" :key="teacher.id" class="flex flex-row gap-2 align-items-center">
                                            <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showTeacherDialog(teacher.id)">
                                                <span>{{ teacher.fullname }}{{ index === selectedTeachers.length - 1 ? '' : ',' }}</span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex flex-column card">
                    <h5>Συμπληρώστε τα πεδία</h5>
                    <div class="field">
                        <label style="padding-right: 1rem" :class="{ 'p-error': v$.deadline.$invalid && submitted }">Απάντηση μέχρι*</label>
                        <Calendar
                            v-model="v$.deadline.$model"
                            :showIcon="true"
                            :showButtonBar="true"
                            :monthNavigator="false"
                            :yearNavigator="false"
                            date-format="dd/mm/yy"
                            hour-format="24"
                            placeholder="dd/mm/yyyy"
                            :show-time="true"
                            :time-only="false"
                            :stepMinute="30"
                            :class="{ 'p-invalid': v$.deadline.$invalid && submitted }"
                        />
                        <small v-if="(v$.deadline.$invalid && submitted) || v$.deadline.$pending.$response" class="p-error">{{ v$.deadline.required.$message }}</small>
                    </div>
                    <!-- <div class="field">
                        <label style="padding-right: 2rem" :class="{ 'p-error': v$.title.$invalid && submitted }">Τίτλος*</label>
                        <InputText v-model="v$.title.$model" class="w-5" autocomplete="off" spellcheck="off" placeholder="Τίτλος" :class="{ 'p-invalid': v$.title.$invalid && submitted }" />
                        <small v-if="(v$.title.$invalid && submitted) || v$.title.$pending.$response" class="p-error">{{ v$.title.required.$message }}</small>
                    </div> -->
                    <div class="flex flex-row w-full gap-4">
                        <div class="flex flex-row gap-3 pt-2">
                            <span>Μηνύματα (προς όλους):</span>
                            <div class="flex flex-column gap-0">
                                <div ref="chatContainer" class="chat-container">
                                    <div class="virtual-scroller-content">
                                        <div class="messages-container">
                                            <div
                                                v-for="message in messages"
                                                :key="message.id"
                                                class="message"
                                                :data-value="isMyMsg(message.text) ? 'mine' : 'other'"
                                                :style="{ display: message.text.trim().length === 0 ? 'none' : 'flex' }"
                                            >
                                                <span>{{ pureMsg(message.text) }}</span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="input-container">
                                    <input v-model="newMessage" type="text" placeholder="Πληκτρολόγησε ένα μήνυμα" @keyup.enter="appendAndSendMsg" />
                                    <Button label="" icon="pi pi-send" @click="appendAndSendMsg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </transition>

        <template #footer>
            <div v-if="viewPage === 2">
                <Button label="Προηγούμενο" icon="pi pi-arrow-left" class="p-button-outlined w-12rem mr-3" :disabled="viewPage === 0" @click="goToPreviousPage" />
                <Button
                    label="Αποστολή"
                    icon="pi pi-send"
                    class="p-button-primary w-10rem"
                    :disabled="selectedTeachers.length === 0 || v$.deadline.$invalid || totalSelectedRequests.length === 0"
                    @click="onSendPostings"
                />
            </div>
            <div v-else>
                <Button
                    v-if="viewPage > 0 && !requestSpecific"
                    label="Προηγούμενο"
                    icon="pi pi-arrow-left"
                    class="p-button-outlined w-10rem mr-3"
                    :disabled="viewPage === 0"
                    @click="goToPreviousPage"
                />
                <Button label="Επόμενο" icon="pi pi-arrow-right" class="p-button-primary w-10rem" :disabled="isNextBtnDisabled" @click="goToNextPage" />
            </div>
        </template>

        <div v-if="loading" class="flex flex-row justify-content-between align-content-center w-full h-full absolute top-0 left-0 z-5 bg-black-alpha-20">
            <Vue3Lottie :animationData="loaderCircle" :loop="true" :autoPlay="true" style="width: 100%" />
        </div>
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { required, integer, email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import { useDebounceFn } from '@vueuse/core';
import { usePopupStore } from '@/stores/PopupStore.js';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
import moment from 'moment-timezone';
import { onMounted, reactive, ref, computed, watchEffect, toRefs, nextTick, watch } from 'vue';
import EmailTemplates from '@/utils/EmailTemplates';
import { useConfirm } from 'primevue/useconfirm';
import { FilterMatchMode, FilterOperator, FilterService } from 'primevue/api';
import loaderCircle from '@/assets/lottie/loaderCircle.json';

const chatContainer = ref(null);
const messages = ref([]);
const newMessage = ref('');
const submitted = ref(false);
const state = reactive({
    examPeriod: null,
    deadline: null,
});
const rules = {
    examPeriod: { required },
    deadline: { required },
};
const v$ = useVuelidate(rules, state);
const requests = ref([]);
const requestsRowsPerPage = ref(10);
const fetchRequestsParams = ref({});
const filteredCategories = ref([]);
const filteredSubCategories1 = ref([]);
const filteredSubCategories2 = ref([]);
const { RPCApi, RPCMethods } = useRPC();
const headerTitle = ref('');
const loading = ref(false);
const totalNotifications = ref(0);
const notifications = ref([]);
const notificationsRowsPerPage = ref(10);
const popupStore = usePopupStore();
const paging = ref({ offset: 0, limit: 10 });
const tabIndex = ref(0);
const confirm = useConfirm();
const dt1 = ref(null);
const categories = ref([]);
const schools = ref([]);
const totalCourses = ref(0);
const coursesRequests = ref([]);
const coursesRequestsRowsPerPage = ref(10);
const totalSelectedRequests = ref([]);
const examPeriods = ref([]);
const filteredCourses = ref([]);
const selectedCoursesFilters = ref({
    courseTitle: { value: null },
    examPeriod: { value: null },
    category: { value: null },
    subCateg1: { value: null },
    subCateg2: { value: null },
});
const tableRequestsFilters = ref({
    title: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    school: { value: null, matchMode: FilterMatchMode.CONTAINS },
    examPeriod: { value: null, matchMode: FilterMatchMode.CONTAINS },
    studentName: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    accountableID: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    isPrivate: { value: null, matchMode: FilterMatchMode.EQUALS },
    status: { value: null, matchMode: FilterMatchMode.EQUALS },
    category: { value: null, matchMode: FilterMatchMode.EQUALS },
    subCateg1: { value: null, matchMode: FilterMatchMode.EQUALS },
    subCateg2: { value: null, matchMode: FilterMatchMode.EQUALS },
    courseTitle: { value: null, matchMode: FilterMatchMode.EQUALS },
});

const tableTeachersFilters = ref({
    lname: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    email: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    mobile: { value: null, matchMode: FilterMatchMode.STARTS_WITH },
    ban: { value: true, matchMode: FilterMatchMode.EQUALS },
    departments: { value: null, matchMode: FilterMatchMode.IN },
    lastLoginDatetime: { value: null, matchMode: FilterMatchMode.EQUALS },
    departmentCourses: { value: null, matchMode: FilterMatchMode.CONTAINS },
});
const departments = ref([]);
const toast = useToast();
const totalRequests = ref(0);
const dialogShown = ref(false);
const dt = ref();
const selectedTeachers = ref([]);
const totalTeachers = ref(0);
const teachers = ref([]);
const rowsPerPage = ref(10);
const closeCB = ref(null);
const requestStatus = ref(null);
const requestSpecific = ref(false);
const fetchTeachersParams = ref({
    paging: { offset: 0, limit: rowsPerPage },
    sort: null,
    filters: tableTeachersFilters.value,
});
const viewPage = ref(0);

let prevViewPage = ref(viewPage.value);

const transitionName = computed(() => (viewPage.value > prevViewPage.value ? 'slide-left' : 'slide-right'));

const sumNumNotPosted = ref(0);

watch(viewPage, (newVal, oldVal) => {
    prevViewPage.value = oldVal;
});

watch(
    () => totalSelectedRequests.value,
    async (val) => {
        if (val.length > 0) {
            await fetchTeachers();
        }
    }
);

watch(
    () => selectedTeachers.value,
    async (val) => {
        state.deadline = moment().add(1, 'days').toDate();
    }
);

const goToPreviousPage = () => {
    if (viewPage.value > 0) viewPage.value--;
};

const goToNextPage = () => {
    if (viewPage.value < 2) viewPage.value++;
};

const isNextBtnDisabled = computed(() => {
    if (viewPage.value === 0) {
        return totalSelectedRequests.value.length === 0;
    } else if (viewPage.value === 1) {
        return selectedTeachers.value.length === 0;
    } else if (viewPage.value === 2) {
        return v$.deadline.$invalid;
    }

    return false;
});

const show = async (data, onCloseCB) => {
    closeCB.value = onCloseCB;
    dialogShown.value = true;
    loading.value = true;
    requestSpecific.value = data.course != null;

    let examPeriodsRes = await RPCApi(RPCMethods.Periods.Fetch);
    if (examPeriodsRes.status === 'ok') {
        const { periods } = examPeriodsRes.data.result.data;
        let r = [];
        for (let period of periods) {
            if (period.active === 1)
                r.push({
                    label: `${period.title}`,
                    value: period.id,
                });
        }
        examPeriods.value = r;
    }

    if (!requestSpecific.value) {
        viewPage.value = 0;
        await loadCoursesAndRequests();
    } else {
        console.warn(data);
        viewPage.value = 1;
        requestStatus.value = data.status.value;
        state.selectedCourse = {
            id: data.course.id,
            category: data.category.name,
            subCateg1: data.subCateg1,
            subCateg2: data.subCateg2,
            title: data.course.title,
        };
        state.examPeriod = data.examPeriod.label;
        totalSelectedRequests.value = [data.id];
    }

    let departmentsRes = await RPCApi(RPCMethods.Departments.Fetch);
    let arr = [];
    if (departmentsRes.status == 'ok') {
        for (let s of departmentsRes.data.result.data) {
            if (!s.title || !s.title.trim()) continue;
            s.label = s.title;
            s.value = s.id;
            arr.push(s);
        }
    }
    departments.value = arr;

    loading.value = false;
};

const fetchTeachers = async () => {
    loading.value = true;
    const res = await RPCApi(RPCMethods.User.FetchTeachersWithParams, { ...fetchTeachersParams.value, extraInfo: { courseID: state.selectedCourse.id, examPeriod: state.examPeriod } });
    if (res.status == 'ok') {
        let d = [];
        if (res.status == 'ok') {
            const { teachersData, total } = res.data.result.data;
            totalTeachers.value = total;
            console.log('total:' + total);

            for (let obj of teachersData) {
                d.push({
                    id: obj.id,
                    fullname: obj.lname + ' ' + obj.fname,
                    lname: obj.lname,
                    email: obj.email,
                    mobile: obj.mobile,
                    school: obj.school,
                    ban: obj.ban === 1 ? true : false,
                    departments: obj.departments,
                    activeClasses: obj.activeClasses,
                    departmentCourses: obj.courses ? obj.courses.join(', ').replace(/,\s*$/, '') : '',
                    classesForCourse: obj.classesForCourse,
                    positiveApplications: obj.positiveApplications,
                    lastLoginDatetime: obj.lastLoginDatetime
                        ? moment(Utils.datetimeToDate(obj.lastLoginDatetime)).format('DD/MM/YYYY') + ' - ' + moment(Utils.datetimeToDate(obj.lastLoginDatetime)).format('HH:mm')
                        : null,
                });
            }
            teachers.value = d;
        } else {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
        }
    }
    loading.value = false;
};

const onTeachersPage = useDebounceFn((event) => {
    console.log('onPage', event);
    fetchTeachersParams.value.paging = { offset: event.first, limit: rowsPerPage };
    fetchTeachers();
}, 300);

const onTeachersSort = useDebounceFn((event) => {
    console.log('onSort', event);
    if (event.sortField === 'fullname') event.sortField = 'lname';
    fetchTeachersParams.value.paging = { offset: 0, limit: rowsPerPage };
    fetchTeachersParams.value.sort = { sortField: event.sortField, sortOrder: event.sortOrder };
    fetchTeachers();
}, 300);

const onTeachersFilter = useDebounceFn(() => {
    console.log('onFilter', tableTeachersFilters.value);
    fetchTeachersParams.value.paging = { offset: 0, limit: rowsPerPage };
    fetchTeachersParams.value.filters = {
        ...tableTeachersFilters.value,
        departments: { value: tableTeachersFilters.value.departments.value?.map((d) => d.value), matchMode: FilterMatchMode.IN },
        ban:
            tableTeachersFilters.value.ban.value == null
                ? { value: null, matchMode: FilterMatchMode.EQUALS }
                : tableTeachersFilters.value.ban.value === true
                ? { value: 0, matchMode: FilterMatchMode.EQUALS }
                : { value: 1, matchMode: FilterMatchMode.EQUALS },
        lastLoginDatetime:
            tableTeachersFilters.value.lastLoginDatetime.value == null
                ? { value: null, matchMode: FilterMatchMode.EQUALS }
                : tableTeachersFilters.value.lastLoginDatetime.value === true
                ? { value: 1, matchMode: FilterMatchMode.EQUALS }
                : { value: 0, matchMode: FilterMatchMode.EQUALS },
    };
    fetchTeachers();
}, 1000);

const loadCoursesAndRequests = async () => {
    moment.locale('el');
    totalSelectedRequests.value = [];

    let res = await RPCApi(RPCMethods.Courses.Fetch);
    if (res.status !== 'ok') {
        filteredCourses.value = [];
        return;
    }

    const { coursesData } = res.data.result.data;
    let d = [];
    for (let obj of coursesData) {
        d.push({
            id: obj.id,
            title: obj.title,
            categoryName: obj.categoryName,
            subCateg1: obj.subCateg1,
            subCateg2: obj.subCateg2,
            semester: obj.semester,
            price: obj.price,
        });
    }
    filteredCourses.value = d;

    await fetchSchoolsAndCategories();

    await fetchCoursesRequests();
};

const fetchSchoolsAndCategories = async () => {
    let schoolsRes = await RPCApi(RPCMethods.Schools.Fetch);
    if (schoolsRes.status == 'ok') {
        schools.value = schoolsRes.data.result.data.schools;
        categories.value = schoolsRes.data.result.data.categories;
    }
};

const filterCoursesByExamPeriod = (event) => {
    console.log('filterCoursesByExamPeriod: ', event.value);
    fetchCoursesRequests();
};

const onCoursesFilter = () => {
    console.log('onCoursesFilter', selectedCoursesFilters.value);
    state.selectedCourse = null;
    fetchCoursesRequests();
};

const resetGlobalFilters = (param) => {
    if (param === 'course') {
        selectedCoursesFilters.value.category.value = null;
        selectedCoursesFilters.value.subCateg1.value = null;
        selectedCoursesFilters.value.subCateg2.value = null;
        selectedCoursesFilters.value.courseTitle.value = null;
    } else {
        tableRequestsFilters.value.category.value = null;
        tableRequestsFilters.value.subCateg1.value = null;
        tableRequestsFilters.value.subCateg2.value = null;
        tableRequestsFilters.value.courseTitle.value = null;
    }

    filteredSubCategories1.value = [];
    filteredSubCategories2.value = [];
    filteredCourses.value = [];
    if (param === 'course') {
        onCoursesFilter();
    } else {
        onRequestsFilter();
    }
};

const fetchCoursesRequests = async () => {
    loading.value = true;
    let res = await RPCApi(RPCMethods.Courses.FetchCoursesRequests, { examPeriod: selectedCoursesFilters.value.examPeriod.value?.value });
    if (res.status == 'ok') {
        const requestsCoursesData = res.data.result.data;
        let d = [];
        sumNumNotPosted.value = 0;

        for (let obj of requestsCoursesData) {
            if (obj.requestStatus === Utils.RequestStatus.Waiting || obj.requestStatus == null) {
                if (obj.requestsNum > 0) {
                    let item = {
                        id: obj.courseID,
                        category: obj.category,
                        subCateg1: obj.subCateg1,
                        subCateg2: obj.subCateg2,
                        title: obj.courseTitle,
                        semester: obj.semester,
                        requests: obj.requestsNum,
                        hours: obj.hours,
                        price: obj.price,
                        jobPostingIds: obj.jobPostingIds.length,
                        numNotPosted: obj.requestsNum - obj.jobPostingIds.length,
                    };
                    d.push(item);

                    sumNumNotPosted.value += item.numNotPosted;
                }
            }
        }

        let filtered = [...d];
        if (selectedCoursesFilters.value.category.value) {
            filtered = filtered.filter((c) => c.category === selectedCoursesFilters.value.category.value.name);
        }
        if (selectedCoursesFilters.value.subCateg1.value) {
            filtered = filtered.filter((c) => c.subCateg1 === selectedCoursesFilters.value.subCateg1.value);
        }
        if (selectedCoursesFilters.value.subCateg2.value) {
            filtered = filtered.filter((c) => c.subCateg2 === selectedCoursesFilters.value.subCateg2.value);
        }
        coursesRequests.value = filtered;
        totalCourses.value = coursesRequests.value.length;
    }
    loading.value = false;
};

const onCourseRequestsDialogClosed = (action, selectedRequests, courseID, examPeriod, pickedRequestsWithJobPostings) => {
    console.log('selectedRequests:', selectedRequests);
    console.log('pickedRequestsWithJobPostings:' + pickedRequestsWithJobPostings);
    console.warn('examPeriod:', examPeriod);
    if (!action) return;
    if (pickedRequestsWithJobPostings) {
        toast.add({ severity: 'error', summary: 'Προσοχή', detail: 'Δεν μπορείτε να επιλέξετε αιτήσεις που έχουν ήδη αναρτηθεί!', life: 3000 });
        return;
    }
    totalSelectedRequests.value = selectedRequests;
    if (totalSelectedRequests.value.length > 0) {
        state.selectedCourse = coursesRequests.value.find((c) => c.id === courseID);
        state.examPeriod = examPeriod;
    }

    console.log('totalSelectedRequests:', totalSelectedRequests.value);
};

const searchCategory = async (event, param) => {
    console.log('searchCategory: ' + event.query, param);

    if (!event.query.trim().length) {
        filteredCategories.value = [...categories.value];
    } else {
        filteredCategories.value = categories.value.filter((d) => {
            return d.name.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }
};

const searchSubCategory1 = async (event, param) => {
    console.log('searchSubCategory1: ' + event.query, param);
    let categoryValue = param === 'course' ? selectedCoursesFilters.value.category.value : tableRequestsFilters.value.category.value;
    if (categoryValue == null) {
        filteredSubCategories1.value = [];
        return;
    }

    filteredSubCategories1.value = schools.value.filter((d) => {
        return d.category === categoryValue.name;
    });

    if (!event.query.trim().length) {
        filteredSubCategories1.value = [...filteredSubCategories1.value];
    } else {
        filteredSubCategories1.value = filteredSubCategories1.value.filter((d) => {
            return d.subCateg1.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }

    filteredSubCategories1.value = [...new Set(filteredSubCategories1.value.map((d) => d.subCateg1))];
};

const searchSubCategory2 = async (event, param) => {
    console.log('searchSubCategory2: ' + event.quer, param);

    let subCateg1Value = param === 'course' ? selectedCoursesFilters.value.subCateg1.value : tableRequestsFilters.value.subCateg1.value;
    if (subCateg1Value == null) {
        filteredSubCategories2.value = [];
        return;
    }

    filteredSubCategories2.value = schools.value.filter((d) => {
        return d.subCateg1 === subCateg1Value;
    });

    if (!event.query.trim().length) {
        filteredSubCategories2.value = [...filteredSubCategories2.value];
    } else {
        filteredSubCategories2.value = filteredSubCategories2.value.filter((d) => {
            return d.subCateg2.toLowerCase().startsWith(event.query.toLowerCase());
        });
    }

    filteredSubCategories2.value = [...new Set(filteredSubCategories2.value.map((d) => d.subCateg2))];
};

const onRequestsFilter = useDebounceFn(() => {
    console.log('onFilter', tableRequestsFilters.value);
    fetchRequestsParams.value.paging = { offset: 0, limit: requestsRowsPerPage };
    fetchRequestsParams.value.filters = {
        title: tableRequestsFilters.value.title,
        examsPeriodTitle: { value: tableRequestsFilters.value.examPeriod.value?.label, matchMode: FilterMatchMode.EQUALS },
        accountableID: { value: tableRequestsFilters.value.accountableID.value?.value, matchMode: FilterMatchMode.EQUALS },
        isPrivate: { value: tableRequestsFilters.value.isPrivate.value?.value, matchMode: FilterMatchMode.EQUALS },
        status: { value: tableRequestsFilters.value.status.value?.value, matchMode: FilterMatchMode.EQUALS },
        category: { value: tableRequestsFilters.value.category.value?.id, matchMode: FilterMatchMode.EQUALS },
        subCateg1: { value: tableRequestsFilters.value.subCateg1.value, matchMode: FilterMatchMode.EQUALS },
        subCateg2: { value: tableRequestsFilters.value.subCateg2.value, matchMode: FilterMatchMode.EQUALS },
        courseID: { value: tableRequestsFilters.value.courseTitle.value?.id, matchMode: FilterMatchMode.EQUALS },
    };
    fetchRequests();
}, 1000);

const onCategorySelect = (event, param) => {
    console.log('onCategorySelect: ', event.value, param);
    tableRequestsFilters.value.subCateg1.value = null;
    tableRequestsFilters.value.subCateg2.value = null;
    filteredSubCategories1.value = [];
    filteredSubCategories2.value = [];
    filteredCourses.value = [];

    if (param === 'course') {
        onCoursesFilter();
    } else {
        onRequestsFilter();
    }
};

const showCoursesRequestsDialog = (courseID) => {
    console.log('show all requests for course with id: ' + courseID);
    let examPeriod = selectedCoursesFilters.value['examPeriod'].value ? selectedCoursesFilters.value['examPeriod'].value.value : null;
    popupStore.show(
        Utils.Popups.CourseRequestsDialog,
        {
            courseID,
            totalSelectedRequests: totalSelectedRequests.value || [],
            examPeriod: examPeriod,
        },
        onCourseRequestsDialogClosed
    );
};

const onSubCateg1Select = (event, param) => {
    console.log('onSubCateg1Select: ', event.value, param);
    tableRequestsFilters.value.subCateg1.value = event.value;
    tableRequestsFilters.value.subCateg2.value = null;
    filteredSubCategories2.value = [];
    filteredCourses.value = [];

    if (param === 'course') {
        onCoursesFilter();
    } else {
        onRequestsFilter();
    }
};

const onSubCateg2Select = (event, param) => {
    console.log('onSubCateg2Select: ', event.value, param);
    tableRequestsFilters.value.subCateg2.value = event.value;
    filteredCourses.value = [];

    if (param === 'course') {
        onCoursesFilter();
    } else {
        onRequestsFilter();
    }
};

const fetchRequests = async () => {
    loading.value = true;
    requests.value = [];
    RPCApi(RPCMethods.Request.FetchRequests, fetchRequestsParams.value).then((res) => {
        console.log(res);
        let d = [];
        if (res.status == 'ok') {
            const { total, requests: requestsData } = res.data.result.data;
            totalRequests.value = total;

            for (let obj of requestsData) {
                d.push({
                    id: obj.requestID,
                    title: obj.title,
                    school: obj.school,
                    studentName: obj.studentName,
                    studentID: obj.studentID,
                    examsPeriodTitle: obj.examsPeriodTitle,
                    accountableName: obj.accountableName,
                    accountableID: obj.accountableID,
                    isPrivate: obj.isPrivate === 1 ? true : false,
                    status: obj.status,
                });
            }
            requests.value = d;
        } else {
            toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
        }
        loading.value = false;
    });
};

const showCourseDialog = (courseID) => {
    console.log('showCourseDialog for courseID: ' + courseID);
    popupStore.show(Utils.Popups.CourseDialog, courseID, (action) => {
        console.log('CourseDialog closed:' + action);
        fetchCoursesRequests();
    });
};

const showRequestsDialog = (requestID) => {
    console.log('showRequestsDialog for requestID: ' + requestID);
    popupStore.show(Utils.Popups.RequestDialog, { requestID }, (action) => {
        console.log('RequestDialog closed:' + action);
    });
};

const showClassDialog = (classID) => {
    console.log('showClassDialog for classID: ' + classID);
    popupStore.show(Utils.Popups.ClassDialog, { classID }, (action) => {
        console.log('ClassDialog closed:' + action);
    });
};

const onHide = (action = false) => {
    console.log('onHide');
    dialogShown.value = false;
    reset();
    closeCB.value(action);
};

const reset = () => {
    selectedCoursesFilters.value.category.value = null;
    selectedCoursesFilters.value.subCateg1.value = null;
    selectedCoursesFilters.value.subCateg2.value = null;
    selectedCoursesFilters.value.courseTitle.value = null;
    state.examPeriod = null;
    state.selectedCourse = null;
    coursesRequests.value = [];
    selectedTeachers.value = [];
    messages.value = [];
    newMessage.value = '';
    submitted.value = false;
    tableTeachersFilters.value.lname.value = null;
    tableTeachersFilters.value.email.value = null;
    tableTeachersFilters.value.mobile.value = null;
    tableTeachersFilters.value.ban.value = true;
    tableTeachersFilters.value.departments.value = null;
    tableTeachersFilters.value.lastLoginDatetime.value = null;
    tableTeachersFilters.value.departmentCourses.value = null;
    fetchTeachersParams.value.paging = { offset: 0, limit: rowsPerPage };
    fetchTeachersParams.value.sort = null;
    fetchTeachersParams.value.filters = tableTeachersFilters.value;
};

const showTeacherDialog = (teacherID) => {
    popupStore.show(Utils.Popups.TeacherDialog, { teacherID }, () => {
        console.log('teacher dialog closed');
    });
};

const onSendPostings = async () => {
    loading.value = true;
    console.log('selectedCoursesFilters.value::', state.selectedCourse);

    let examPeriodID = examPeriods.value.find((x) => x.label === state.examPeriod).value;
    let res = await RPCApi(RPCMethods.JobPostings.CreateJobPosting, {
        courseID: state.selectedCourse.id,
        examPeriodID: examPeriodID,
        deadline: state.deadline,
        teachers: selectedTeachers.value.map((x) => x.id),
        requests: totalSelectedRequests.value,
        comments: messages.value.map((x) => x.text),
        subCategory1: state.selectedCourse.subCateg1,
        subCategory2: state.selectedCourse.subCateg2,
        courseTitle: state.selectedCourse.title,
    });
    if (res.status == 'ok') {
        toast.add({ severity: 'success', summary: 'Επιτυχία', detail: 'Οι αναθέσεις στους καθηγητές έχουν αποσταλεί!', life: 3000 });
        onHide(true);
    } else {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
    }

    loading.value = false;
};

const isMyMsg = (msg) => {
    return msg.startsWith('_' + Utils.UserInfo.id + '_:');
};

const pureMsg = (msg) => {
    //replace using reg exp the '_xxxx_:' where xxxx is the user id as int, also remove '\n'
    return msg.replace(/_\d+_:/g, '').replace(/\n/g, '');
};

const appendAndSendMsg = async () => {
    console.log('appendAndSendMsg:' + newMessage.value);
    if (newMessage.value == null || newMessage.value.trim() === '') return;
    let tempMsg = newMessage.value;
    newMessage.value = '';

    messages.value.push({
        id: Utils.generateUUID(),
        text: tempMsg,
    });

    await nextTick();
    scrollToBottom();
};

const scrollToBottom = () => {
    console.warn('scrollToBottom', chatContainer.value.scrollHeight);
    chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
};

defineExpose({
    show,
});
</script>

<style lang="scss" scoped>
.slide-left-enter-active,
.slide-right-leave-active {
    transition: all 0.3s ease;
}
.slide-left-leave-active,
.slide-right-enter-active {
    transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-left-enter,
.slide-right-leave-to {
    transform: translateX(100px);
    opacity: 0;
}
.slide-left-leave-to,
.slide-right-enter {
    transform: translateX(-100px);
    opacity: 0;
}

.chat-container {
    display: flex;
    flex-direction: column;
    width: 600px;
    height: 330px;
    margin: auto;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    overflow-y: scroll;
    position: relative;

    .virtual-scroller-content {
        position: absolute;
        top: 0;
        left: 0;
        min-height: 100%;
        min-width: 100%;
        will-change: transform;
    }
}

.messages-container {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    padding: 15px;
    overflow-y: auto;
}

.message {
    max-width: 70%;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 10px;
    background-color: #e0f7fa;
    word-break: break-word;

    &[data-value='mine'] {
        align-self: flex-end;
        background-color: #b2ebf2;
    }

    &[data-value='other'] {
        align-self: flex-start;
        background-color: #eceff1;
    }
}

.input-container {
    display: flex;
    align-items: center;
    padding-top: 5px;

    input {
        flex-grow: 1;
        padding: 10px;
        border: 1px solid #e0e0e0;
        border-radius: 4px;
        margin-right: 10px;
    }

    button {
        padding: 10px 15px;
        background-color: #26a69a;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
    }

    button:hover {
        background-color: #00796b;
    }
}
</style>
