<template>
    <div class="flex align-items-middle align-content-between flex-wrap card-container gap-2 pb-4">
        <div class="flex-1">
            <div style="font-weight: 500; font-size: 1.25rem">Συνολικές Χρεώσεις:</div>
            <div class="dues-badge" :class="`status-1`" style="font-weight: 500; font-size: 1.2rem">{{ Utils.toEUR(sumGeneralDues + sumDues) }}</div>
        </div>
        <div class="flex-1">
            <div style="font-weight: 500; font-size: 1.25rem">Υπόλοιπο:</div>
            <div class="dues-badge" :class="`status-${dueType}`" style="font-weight: 500; font-size: 1.2rem">{{ Utils.toEUR(totalDues) }}</div>
        </div>
        <div class="flex-1">
            <div style="font-weight: 500; font-size: 1.25rem">Ρυθμισμένο:</div>
            <div class="dues-badge" :class="`status-with-installement`" style="font-weight: 500; font-size: 1.2rem">{{ Utils.toEUR(duesInstallements) }}</div>
        </div>
        <div class="flex-1">
            <div style="font-weight: 500; font-size: 1.25rem">Αρύθμιστο:</div>
            <div class="dues-badge" :class="`status-no-installement`" style="font-weight: 500; font-size: 1.2rem">{{ Utils.toEUR(duesNoInstallments) }}</div>
        </div>
    </div>
    <div class="card">
        <h5>Γενικές Χρεώσεις ({{ Utils.toEUR(sumGeneralDues) }})</h5>
        <DataTable
            ref="generalDuesTable"
            :total-records="totalGeneralDues"
            :loading="loadingGeneralDues"
            :row-hover="true"
            :value="generalDues"
            data-key="id"
            responsive-layout="scroll"
            :rows="generalDuesRowsPerPage"
            :paginator="true"
        >
            <template #header>
                <div class="flex justify-content-between flex-column sm:flex-row">
                    <Button icon="pi pi-plus" label="Νέα Χρέωση" class="mr-2 mb-2" @click="addGeneralDue" />
                </div>
            </template>
            <template #empty> Δεν βρέθηκαν γενικές χρεώσεις για τον μαθητή. </template>
            <Column header="Ημερομηνία" field="createdAt" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ data.createdAt }}</span>
                </template>
            </Column>
            <Column header="Ποσό" field="amount" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ Utils.toEUR(data.amount) }}</span>
                </template>
            </Column>
            <Column header="Αιτιολογία" field="reason" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ data.reason }}</span>
                </template>
            </Column>
            <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <Button type="button" icon="pi pi-pencil" class="p-button-text" @click="editGeneralDueDialog(data)"></Button>
                </template>
            </Column>
            <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <Button type="button" icon="pi pi-trash" class="p-button-text p-button-danger" @click="showDeleteGeneralDueDialog(data.dueId)"></Button>
                </template>
            </Column>
        </DataTable>
    </div>
    <div class="card">
        <h5>Αιτήσεις / Χρεώσεις ({{ Utils.toEUR(sumDues) }})</h5>
        <DataTable
            ref="duesTable"
            v-model:filters="filters"
            :total-records="totalDuesRecords"
            :loading="loadingDues"
            :row-hover="true"
            :value="dues"
            data-key="id"
            responsive-layout="scroll"
            :rows="duesRowsPerPage"
            :paginator="true"
            filter-display="row"
        >
            <template #header> <Button icon="pi pi-plus" label="Νέα Αίτηση" class="mr-2 mb-2" @click="addRequest" /></template>
            <template #empty> Δεν βρέθηκαν χρεώσεις για τον μαθητή. </template>
            <Column header="Εξεταστική" field="examPeriod" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #filter="{ filterModel, filterCallback }">
                    <Dropdown v-model="filterModel.value" :options="examPeriods" placeholder="Επιλέξτε Εξεταστική" class="p-column-filter" :showClear="true" @change="filterCallback">
                        <template #option="slotProps">
                            <span>{{ slotProps.option }}</span>
                        </template>
                    </Dropdown>
                </template>
                <template #body="{ data }">
                    <span>{{ data.examPeriod }}</span>
                </template>
            </Column>
            <Column field="requestStatus" filter-field="requestStatus" header="Αίτηση" body-class="text-left" style="min-width: 10rem">
                <template #body="{ data }">
                    <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showRequestDialog(data.id)">{{ data.courseTitle }}</a>
                    <div class="mt-3">
                        <span class="status-badge" :class="`status-${data.requestStatus}`">{{ Utils.RequestStatusToString(data.requestStatus) }}</span>
                        <!-- <span class="private-badge ml-1" :class="`private-${data.requestPrivate}`">{{ Utils.Types[Utils.FromBoolToTinyInt(data.requestPrivate)].label }}</span> -->
                    </div>
                </template>
            </Column>
            <Column header="Τμήμα" field="classTitle" style="min-width: 20rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showClassDialog(data.classID)">{{ data.classTitle }}</a>
                    <div v-if="data.classID > 0" class="mt-3">
                        <span class="cls-status-badge mt-3" :class="`cls-status-${data.status}`">{{ Utils.ClassStatusToString(data.status) }}</span>
                        <span class="private-badge ml-1" :class="`private-${data.isPrivate}`">{{ Utils.Types[Utils.FromBoolToTinyInt(data.isPrivate)].label }}</span>
                    </div>
                </template>
            </Column>
            <Column header="Πρ. ώρες" field="classPhaseTotal" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ data.classPhaseTotal }}</span>
                </template>
            </Column>
            <Column header="Έγιναν" field="classCurrentPhase" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ Utils.toHoursAndMinutes(data.classCurrentPhase) }}</span>
                </template>
            </Column>
            <Column header="Παρουσίες" field="attendances" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ Utils.toHoursAndMinutes(data.attendances) }}</span>
                </template>
            </Column>
            <Column header="Δίδακτρα" field="offer" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showOfferDialog(data.id, data.classTitle)">{{ Utils.toEUR(data.offer) }}</a>
                </template>
            </Column>
            <Column header="Σύνολο" field="classSum" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span style="font-weight: 800"> {{ Utils.toEUR(data.classSum) }}</span>
                </template>
            </Column>
        </DataTable>
    </div>
    <div class="card">
        <h5>Πληρωμές ({{ Utils.toEUR(sumPayments) }}) / ({{ Utils.toEUR(sumRegularPayments) }})</h5>
        <DataTable
            ref="paymentsTable"
            :total-records="totalPaymentsRecords"
            :loading="loadingPayments"
            :row-hover="true"
            :value="paymentsFiltered"
            data-key="id"
            responsive-layout="scroll"
            :rows="paymentsRowsPerPage"
            :paginator="true"
        >
            <template #header>
                <div class="flex justify-content-between flex-column sm:flex-row">
                    <Button icon="pi pi-plus" label="Νέα Πληρωμή" class="mr-2 mb-2" @click="addPayment" />
                </div>
            </template>
            <template #empty> Δεν βρέθηκαν πληρωμές για τον μαθητή. </template>
            <Column header="ID" field="id" style="min-width: 4rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showPaymentDialog(data)">{{ data.id }}</a>
                </template>
            </Column>
            <Column header="Τρόπος Πληρωμής" field="code" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ data.code }}</span>
                </template>
            </Column>
            <Column header="Ποσό" field="amount" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ Utils.toEUR(data.amount) }}</span>
                </template>
            </Column>
            <!-- <Column header="Τύπος" field="type" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ data.type }}</span>
                </template>
            </Column> -->
            <Column header="Ημερομηνία" field="createdAt" style="min-width: 10rem" :sortable="false" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ moment(Utils.datetimeToDate(data.createdAt)).format('DD/MM/YYYY') }}</span>
                </template>
            </Column>
            <Column header="Σχόλια" field="comment" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ data.comment }}</span>
                </template>
            </Column>
            <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <Button type="button" icon="pi pi-pencil" class="p-button-text" @click="editPaymentDialog(data)"></Button>
                </template>
            </Column>
            <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <Button type="button" icon="pi pi-trash" class="p-button-text p-button-danger" @click="showDeletePaymentDialog(data.id)"></Button>
                </template>
            </Column>
        </DataTable>
    </div>
    <div class="card">
        <h5>Παραστατικά ({{ Utils.toEUR(sumInvoices) }}), Διαφορά ({{ Utils.toEUR(invoicesPaymentsDiff) }})</h5>
        <DataTable
            ref="invoicesTable"
            :total-records="totalInvoicesRecords"
            :loading="loadingInvoices"
            :row-hover="true"
            :value="invoices"
            data-key="id"
            responsive-layout="scroll"
            :rows="invoicesRowsPerPage"
            :paginator="true"
        >
            <template #header>
                <div class="flex justify-content-between flex-column sm:flex-row">
                    <Button icon="pi pi-plus" label="ΑΠΥ" class="mr-2 mb-2 p-button-danger" @click="addReceiptImmediate" />
                    <!-- <Button icon="pi pi-plus" label="ΑΠΥ (Προγραμματισμένη)" class="mr-2 mb-2 p-button-warning" @click="addReceiptScheduled" /> -->
                    <Button icon="pi pi-plus" label="ΤΠΥ" class="mr-2 mb-2 p-button-info" @click="addInvoiceImmediate" />
                    <Button icon="pi pi-plus" label="Απόδειξη Επιστροφής" class="mr-2 mb-2 p-button-danger" @click="addReceiptCredit" />
                    <Button icon="pi pi-plus" label="Πιστωτικό Τιμολόγιο" class="mr-2 mb-2 p-button-info" @click="addInvoiceCredit" />
                </div>
            </template>
            <template #empty> Δεν βρέθηκαν παραστατικά για τον μαθητή. </template>
            <Column header="Α.Α." field="id" style="min-width: 4rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <a class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="printElorusPDF(data)">{{ data.elorusSequenceNumber }}</a>
                </template>
            </Column>
            <Column header="Τύπος" field="type" style="min-width: 20rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span class="invoice-type-badge" :class="`type-${data.type}`">{{ data.credit ? Utils.InvoiceCreditTypeToString(data.type) : Utils.InvoiceTypeToString(data.type) }}</span>
                </template>
            </Column>
            <Column header="Κατάσταση" field="pending" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span class="invoice-status-badge" :class="`invoice-status-${data.pending}`">{{ Utils.InvoiceStatusToString(data.pending) }}</span>
                </template>
            </Column>
            <Column header="Ημ/νία δημιουργίας" field="createdAtDate" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ data.createdAt }}</span>
                </template>
            </Column>
            <Column header="Ημ/νία έκδοσης (elorus)" field="scheduledAtDate" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ data.scheduledAt }}</span>
                </template>
            </Column>
            <!-- <Column field="skipMydata" filter-field="skipMydata" header="myDATA (elorus)" data-type="boolean" body-class="text-left">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'true-icon pi-check-circle': !data.skipMydata, 'false-icon pi-times-circle': data.skipMydata }"></i>
                </template>
            </Column> -->
            <Column field="mydataUid" header="myDATA uid" body-class="text-left">
                <template #body="{ data }">
                    <span>{{ data.mydataUid }}</span>
                </template>
            </Column>
            <Column header="Ποσό" field="amount" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ Utils.toEUR(data.amount) }}</span>
                </template>
            </Column>
            <!-- <Column header="Τρόπος Πληρωμής" field="paymentMethodName" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ data.paymentMethodName }}</span>
                </template>
            </Column> -->
            <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <Button v-if="data.pending === 1" type="button" icon="pi pi-pencil" class="p-button-text" @click="editInvoiceDialog(data)"></Button>
                </template>
            </Column>
            <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <Button v-if="data.pending === 1" type="button" icon="pi pi-trash" class="p-button-text p-button-danger" @click="showDeleteInvoiceDialog(data.id)"></Button>
                </template>
            </Column>
        </DataTable>
    </div>
    <div class="card">
        <h5>Δοσολόγιο (Αρύθμιστο: {{ Utils.toEUR(duesNoInstallments) }})</h5>
        <DataTable
            ref="installmentsTable"
            :total-records="totalInstallmentsRecords"
            :loading="loadingInstallments"
            :row-hover="true"
            :value="installements"
            data-key="id"
            responsive-layout="scroll"
            :rows="installmentsRowsPerPage"
            :paginator="true"
        >
            <template #header>
                <div class="flex justify-content-between flex-column sm:flex-row">
                    <Button icon="pi pi-plus" label="Νέα Δόση" class="mr-2 mb-2" @click="addInstallment" />
                </div>
            </template>
            <template #empty> Δεν βρέθηκαν δόσεις για τον μαθητή. </template>
            <Column header="Ημ/νία δημιουργίας" field="createdAt" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ data.createdAt }}</span>
                </template>
            </Column>
            <Column header="Ποσό" field="amount" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ Utils.toEUR(data.amount) }}</span>
                </template>
            </Column>
            <Column header="Προθεσμία (ημ/νία)" field="dueDate" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ data.dueDate != null ? moment(Utils.datetimeToDate(data.dueDate)).format('DD/MM/YYYY') : '--' }}</span>
                </template>
            </Column>
            <!-- <Column header="Προθεσμία (ώρες)" field="dueHours" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ data.dueHours != null ? data.dueHours : '--' }}</span>
                </template>
            </Column> -->
            <Column header="Τμήμα" field="dueHours" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <span>{{ data.classTitle || '--' }}</span>
                </template>
            </Column>
            <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <Button type="button" icon="pi pi-pencil" class="p-button-text" @click="editInstallmentDialog(data)"></Button>
                </template>
            </Column>
            <Column header-style="width: 4rem; text-align: center" body-style="text-align: center; overflow: visible">
                <template #body="{ data }">
                    <Button type="button" icon="pi pi-trash" class="p-button-text p-button-danger" @click="showDeleteInstallmentDialog(data.id)"></Button>
                </template>
            </Column>
        </DataTable>
    </div>
</template>
<script setup>
import { onMounted, reactive, ref, computed, watch, toRefs, nextTick } from 'vue';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
import moment from 'moment-timezone';
import { useConfirm } from 'primevue/useconfirm';
import { usePopupStore } from '@/stores/PopupStore.js';
import { useGlobalStore } from '@/stores/GlobalStore';
import generateInvoicePDF from '@/utils/InvoiceCreator.js';
import { FilterMatchMode } from 'primevue/api';

const { RPCApi, RPCMethods } = useRPC();
const totalDues = ref(0);
const duesInstallements = ref(0);
const duesNoInstallments = ref(0);
const generalDues = ref([]);
const loadingGeneralDues = ref(false);
const generalDuesTable = ref(null);
const totalGeneralDues = ref(0);
const generalDuesRowsPerPage = ref(10);
const userId = ref(null);
const sumGeneralDues = ref(0);
const confirm = useConfirm();
const invoicesTable = ref(null);
const totalInvoicesRecords = ref(0);
const loadingInvoices = ref(false);
const invoices = ref([]);
const invoicesRowsPerPage = ref(10);
const fetchingEconomics = ref(false);
const sumDues = ref(0);
const duesTable = ref(null);
const loadingDues = ref(false);
const dues = ref([]);
const totalDuesRecords = ref(0);
const duesRowsPerPage = ref(10);

const paymentsTable = ref(null);
const totalPaymentsRecords = ref(0);
const loadingPayments = ref(false);
const payments = ref([]);
const paymentsRowsPerPage = ref(10);

const installmentsTable = ref(null);
const totalInstallmentsRecords = ref(0);
const loadingInstallments = ref(false);
const installements = ref([]);
const installmentsRowsPerPage = ref(10);
const popupStore = usePopupStore();
const globalStore = useGlobalStore();
const sumInvoices = ref(0);
const sumPayments = ref(0);
const panicPaymentsNotAllowed = [7, 9, 10];

const filters = ref({
    examPeriod: { value: null, matchMode: FilterMatchMode.EQUALS },
});

const examPeriods = computed(() => {
    return [...new Set(dues.value.map((due) => due.examPeriod))];
});

const invoicesPaymentsDiff = computed(() => {
    return sumRegularPayments.value - sumInvoices.value;
});

const paymentsFiltered = computed(() => {
    if (globalStore.hasPanicMode) {
        return payments.value.filter((item) => !panicPaymentsNotAllowed.includes(item.codeID));
    }
    return payments.value;
});

const sumRegularPayments = computed(() => {
    return paymentsFiltered.value.filter((item) => !panicPaymentsNotAllowed.includes(item.codeID)).reduce((a, b) => a + b.amount, 0);
});

const isAdmin = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Admin;
});

const dueType = computed(() => {
    return totalDues.value < 0 ? 'negative' : totalDues.value > 0 ? 'positive' : 'zero';
});

const fetchEconomics = async (id) => {
    if (fetchingEconomics.value) return;

    fetchingEconomics.value = true;
    userId.value = id;
    const res = await RPCApi(RPCMethods.User.FetchDuesForStudent, { userId: userId.value });
    console.log(res);
    if (res.status == 'ok') {
        const { dues } = res.data.result.data;
        totalDues.value = dues;
    }
    await fetchGeneralDues();
    await fetchDues();
    await fetchPayments();
    await fetchInstallments();
    await fetchInvoicesAndCredits();

    fetchingEconomics.value = false;
};

const fetchInvoicesAndCredits = async () => {
    sumInvoices.value = 0;
    loadingInvoices.value = true;
    let res = await RPCApi(RPCMethods.Invoice.Fetch, { userId: userId.value });
    let data = [];
    if (res.status == 'ok') {
        const { invoices: invoicesData } = res.data.result.data;
        let arr = [];
        for (let obj of invoicesData) {
            console.log(obj.skipMydata);
            arr.push({
                id: obj.id,
                type: obj.type,
                createdAt: moment(Utils.datetimeToDate(obj.createdAt)).format('DD/MM/YYYY'),
                createdAtDate: obj.createdAt,
                scheduledAt: moment(Utils.datetimeToDate(obj.scheduledAt)).format('DD/MM/YYYY'),
                scheduledAtDate: obj.scheduledAt,
                amount: obj.amount,
                pending: obj.pending,
                skipMydata: obj.skipMydata === 1,
                elorusLink: obj.elorusLink,
                paymentMethodName: Utils.ElorusPaymentMethods[obj.paymentMethod],
                paymentMethod: obj.paymentMethod,
                elorusSequenceNumber: obj.elorusSequenceNumber,
                mydataUid: obj.mydataUid,
                comment: obj.comments,
                username: obj.username,
                email: obj.email,
                mobile: obj.mobile,
                address: obj.address,
                afm: obj.afm,
            });

            if (obj.pending === 0) sumInvoices.value += obj.amount;
        }
        data = arr;
        totalInvoicesRecords.value = arr.length;
    }

    res = await RPCApi(RPCMethods.InvoiceCredit.Fetch, { userId: userId.value });
    console.warn(res);
    if (res.status == 'ok') {
        const { creditsData } = res.data.result.data;
        let arr = [];
        for (let obj of creditsData) {
            arr.push({
                id: obj.id,
                type: obj.type,
                credit: true,
                createdAt: moment(Utils.datetimeToDate(obj.createdAt)).format('DD/MM/YYYY'),
                createdAtDate: obj.createdAt,
                amount: obj.amount,
                skipMydata: obj.skipMydata === 1,
                elorusLink: obj.elorusLink,
                elorusSequenceNumber: obj.elorusSequenceNumber,
                mydataUid: obj.mydataUid,
                comment: obj.comments,
                username: obj.username,
                email: obj.email,
                mobile: obj.mobile,
                address: obj.address,
                afm: obj.afm,
            });

            sumInvoices.value -= obj.amount;
        }
        data = data.concat(arr);
    }

    invoices.value = data;
    loadingInvoices.value = false;
};

const fetchGeneralDues = async () => {
    loadingGeneralDues.value = true;
    sumGeneralDues.value = 0;
    const res = await RPCApi(RPCMethods.User.FetchGeneralDues, { userId: userId.value });
    if (res.status == 'ok') {
        const { dues } = res.data.result.data;
        let arr = [];
        for (let obj of dues) {
            arr.push({
                dueId: obj.id,
                createdAt: moment(Utils.datetimeToDate(obj.createdAt)).format('DD/MM/YYYY') + ' - ' + moment(Utils.datetimeToDate(obj.createdAt)).format('HH:mm'),
                amount: obj.amount,
                reason: obj.reason,
            });
            sumGeneralDues.value += obj.amount;
        }
        generalDues.value = arr;
    }
    loadingGeneralDues.value = false;
};

const fetchDues = async () => {
    loadingDues.value = true;
    sumDues.value = 0;
    filters.value = {
        examPeriod: { value: null, matchMode: FilterMatchMode.EQUALS },
    };
    const res = await RPCApi(RPCMethods.User.FetchClassesDues, { userId: userId.value });
    console.warn(res);
    if (res.status == 'ok') {
        let arr = [];
        let d = {};
        for (let obj of res.data.result.data) {
            d = {
                id: obj.requestID,
                examPeriodID: obj.examPeriodID,
                examPeriod: obj.examPeriodTitle,
                classTitle: obj.classTitle,
                classID: obj.classID,
                classPhaseTotal: obj.classPhaseTotal,
                classCurrentPhase: obj.currentPhase,
                attendances: obj.attendances,
                offer: obj.lastOffer,
                isPrivate: obj.classPrivate === 1,
                status: obj.status,
                requestStatus: obj.requestStatus,
                requestPrivate: obj.requestPrivate === 1,
                courseTitle: obj.courseTitle,
            };
            if (d.isPrivate) {
                sumDues.value += obj.lastOffer * obj.attendances;
                d.classSum = obj.lastOffer * obj.attendances;
            } else if (d.requestStatus > 2 && d.requestStatus != Utils.RequestStatus.Canceled && d.requestStatus != Utils.RequestStatus.NotServed) {
                sumDues.value += obj.lastOffer;
                d.classSum = obj.lastOffer;
            }
            arr.push(d);
        }
        arr.sort((a, b) => {
            return a.examPeriodID - b.examPeriodID < 0 ? 1 : -1;
        });
        dues.value = arr;
    }
    loadingDues.value = false;
};

const fetchPayments = async () => {
    loadingPayments.value = true;
    const res = await RPCApi(RPCMethods.User.FetchPayments, { userId: userId.value });
    sumPayments.value = 0;
    if (res.status === 'ok') {
        let arr = [];
        for (let obj of res.data.result.data) {
            arr.push({
                id: obj.id,
                createdAt: obj.createdAt,
                amount: obj.amount,
                comment: obj.comment,
                code: obj.code,
                codeID: obj.codeID,
                type: Utils.PaymentTypeToString(obj.type),
            });
            sumPayments.value += obj.amount;
        }
        payments.value = arr;
    }
    loadingPayments.value = false;
};

const fetchInstallments = async () => {
    loadingInstallments.value = true;
    duesInstallements.value = 0;
    const res = await RPCApi(RPCMethods.Installments.Fetch, { userID: userId.value });
    if (res.status === 'ok') {
        let arr = [];
        for (let obj of res.data.result.data) {
            arr.push({
                id: obj.id,
                amount: obj.amount,
                createdAt: moment(Utils.datetimeToDate(obj.createdAt)).format('DD/MM/YYYY'),
                dueDate: obj.dueDate,
                dueHours: obj.dueHours,
                classID: obj.classID,
                classTitle: obj.classTitle,
            });
            duesInstallements.value += obj.amount;
        }
        installements.value = arr;
    }

    duesNoInstallments.value = totalDues.value > duesInstallements.value ? totalDues.value - duesInstallements.value : 0;
    loadingInstallments.value = false;
};

const addGeneralDue = async () => {
    console.log('addGeneralDue');
    popupStore.show(Utils.Popups.GeneralDueDialog, { userId: userId.value }, () => {
        onGeneralDueDialogClosed();
    });
};

const editGeneralDueDialog = async (data) => {
    console.log('editGeneralDueDialog');
    popupStore.show(Utils.Popups.GeneralDueDialog, { ...data, userId: userId.value }, () => {
        onGeneralDueDialogClosed();
    });
};

const showDeleteGeneralDueDialog = async (id) => {
    confirm.require({
        message: 'Είστε σίγουροι ότι θέλετε να διαγράψετε τη συγκεκριμένη χρέωση;',
        header: 'Επιβεβαίωση',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Διαγραφή',
        rejectLabel: 'Άκυρο',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
            console.log('delete!');
            await RPCApi(RPCMethods.User.DeleteGeneralDue, { id });
            onGeneralDueDialogClosed();
        },
        reject: () => {
            console.log('do nothing');
        },
    });
};

const onGeneralDueDialogClosed = async () => {
    console.log('onGeneralDueDialogClosed');
    fetchEconomics(userId.value);
};

const showOfferDialog = async (id, classTitle) => {
    console.log('showOfferDialog FOR requestID:' + id + ' classTitle: ' + classTitle);
    popupStore.show(Utils.Popups.OfferDialog, { id, classTitle }, () => {
        console.log('onGeneralOfferDialogClosed');
        fetchEconomics(userId.value);
    });
};

const showPaymentDialog = async (payment) => {
    console.log('showPaymentDialog FOR requestID:' + payment);
    popupStore.show(Utils.Popups.PaymentDialog, { payment, userId: userId.value }, () => {
        onPaymentDialogClosed();
    });
};

const editPaymentDialog = async (payment) => {
    console.log('editPaymentDialog', payment);
    popupStore.show(Utils.Popups.PaymentDialog, { payment, userId: userId.value }, () => {
        onPaymentDialogClosed();
    });
};

const showDeletePaymentDialog = async (id) => {
    confirm.require({
        message: 'Είστε σίγουροι ότι θέλετε να διαγράψετε τη συγκεκριμένη πληρωμή;',
        header: 'Επιβεβαίωση',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Διαγραφή',
        rejectLabel: 'Άκυρο',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
            console.log('delete!');
            await RPCApi(RPCMethods.User.DeletePayment, { id });
            onPaymentDialogClosed();
        },
        reject: () => {
            console.log('do nothing');
        },
    });
};

const addPayment = async () => {
    console.log('addPayment');
    popupStore.show(Utils.Popups.PaymentDialog, { payment: null, userId: userId.value }, () => {
        onPaymentDialogClosed();
    });
};

const onPaymentDialogClosed = async () => {
    console.log('onPaymentDialogClosed');
    fetchEconomics(userId.value);
};

const addInstallment = async () => {
    console.log('addInstallment');
    popupStore.show(Utils.Popups.InstallmentDialog, { userID: userId.value }, () => {
        onInstallmentDialogClosed();
    });
};

const editInstallmentDialog = async (data) => {
    console.log('editInstallmentDialog', data);
    popupStore.show(Utils.Popups.InstallmentDialog, { userID: userId.value, ...data }, () => {
        onInstallmentDialogClosed();
    });
};

const showDeleteInstallmentDialog = async (id) => {
    confirm.require({
        message: 'Είστε σίγουροι ότι θέλετε να διαγράψετε τη συγκεκριμένη δόση;',
        header: 'Επιβεβαίωση',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Διαγραφή',
        rejectLabel: 'Άκυρο',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
            console.log('delete!');
            await RPCApi(RPCMethods.Installments.Delete, { id });
            onInstallmentDialogClosed();
        },
        reject: () => {
            console.log('do nothing');
        },
    });
};

const onInstallmentDialogClosed = async () => {
    console.log('onInstallmentDialogClosed');
    fetchEconomics(userId.value);
};

const showClassDialog = async (classID) => {
    console.log('editClass:' + classID);

    popupStore.show(Utils.Popups.ClassDialog, { classID }, () => {
        fetchDues();
    });
};

const showRequestDialog = async (requestID) => {
    console.log('showRequestDialog:' + requestID);

    popupStore.show(Utils.Popups.RequestDialog, { requestID }, () => {
        fetchDues();
    });
};

const addReceiptImmediate = async () => {
    console.log('addReceiptImmediate');
    popupStore.show(Utils.Popups.InvoiceDialog, { invoiceData: null, userID: userId.value, type: 'receipt', date: null }, () => {
        fetchInvoicesAndCredits();
    });
};

const addReceiptScheduled = async () => {
    console.log('addReceiptScheduled');
    //tomorrow date
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    popupStore.show(Utils.Popups.InvoiceDialog, { invoiceData: null, userID: userId.value, type: 'receipt', date: tomorrow }, () => {
        fetchInvoicesAndCredits();
    });
};

const addInvoiceImmediate = async () => {
    console.log('addInvoiceImmediate');
    popupStore.show(Utils.Popups.InvoiceDialog, { invoiceData: null, userID: userId.value, type: 'invoice', date: null }, () => {
        fetchInvoicesAndCredits();
    });
};

const editInvoiceDialog = async (data) => {
    console.log('editInvoiceDialog', data);
    popupStore.show(Utils.Popups.InvoiceDialog, { invoiceData: data, userID: userId.value }, () => {
        console.log('editInvoiceDialog closed');
        fetchInvoicesAndCredits();
    });
};

const showDeleteInvoiceDialog = async (id) => {
    confirm.require({
        message: 'Είστε σίγουροι ότι θέλετε να διαγράψετε τo συγκεκριμένo παραστατικό;',
        header: 'Επιβεβαίωση',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Διαγραφή',
        rejectLabel: 'Άκυρο',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
            await RPCApi(RPCMethods.Invoice.Delete, { id });
            fetchInvoicesAndCredits();
        },
        reject: () => {
            console.log('do nothing');
        },
    });
};

const addReceiptCredit = async () => {
    console.log('addReceiptCredit');

    popupStore.show(Utils.Popups.InvoiceCreditDialog, { creditData: null, userID: userId.value, type: 'receipt' }, () => {
        fetchInvoicesAndCredits();
    });
};

const addInvoiceCredit = async () => {
    console.log('addInvoiceCredit');

    popupStore.show(Utils.Popups.InvoiceCreditDialog, { creditData: null, userID: userId.value, type: 'invoice' }, () => {
        fetchInvoicesAndCredits();
    });
};

const printElorusPDF = async (data) => {
    console.log('printElorusPDF!!!', data);
    let { elorusLink, mydataUid } = data;
    if (mydataUid) window.open(elorusLink, '_blank');
    else generateInvoicePDF({ ...data, userId: userId.value });
};

const printElorusCreditPDF = async (data) => {
    console.log(data);
    let { elorusLink, mydataUid } = data;
    window.open(elorusLink, '_blank');
};

const addRequest = async () => {
    console.log('addRequest');
    popupStore.show(Utils.Popups.RequestDialog, { studentID: userId.value }, (action) => {
        if (action) fetchDues();
    });
};

defineExpose({
    fetchEconomics,
});
</script>
