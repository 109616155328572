import logo from '@/assets/images/LogoFullDark.png';
import pdfMake from 'pdfmake/build/pdfmake.js';
import Utils from '@/utils/Utils';
import { vfs } from '@/assets/fonts/vfs_fonts.js';
import moment from 'moment-timezone';

pdfMake.vfs = vfs;

const company = {
    row1: 'ΠΑΝΕΠΙΣΤΗΜΙΑΚΑ ΦΡΟΝΤΙΣΤΗΡΙΑ ΒΙΤΑΛΗ',
    row2: "ΑΦΜ: 998094229, ΔΟΥ: Δ' Αθηνών",
    row3: 'Αρ. ΓΕΜΗ: 69927603000',
    row4: 'Νίκης 2 & Καραγεώργη Σερβίας, ΑΘΗΝΑ 10563 ΣΥΝΤΑΓΜΑ, Ελλάδα',
    row5: 'Τηλ: 2103001299',
};

// Helper function to safely format dates
function safeFormatDate(dateValue) {
    if (!dateValue) return '--';
    const date = moment(dateValue);
    return date.isValid() ? date.format('DD/MM/YYYY') : '--';
}

async function convertImageToDataURL(logo) {
    const response = await fetch(logo);
    const blob = await response.blob();
    const fileReader = new FileReader();
    return new Promise((resolve, reject) => {
        fileReader.onerror = () => {
            fileReader.abort();
            reject(new DOMException('Problem parsing input file.'));
        };
        fileReader.onload = () => {
            resolve(fileReader.result);
        };
        fileReader.readAsDataURL(blob);
    });
}

export default async function generateFinancialStatementPDF(data) {
    const { studentInfo, generalDues, classDues, hourlyClassDues, payments, totalDues, sumGeneralDues, sumClassDues, sumHourlyClassDues, sumPayments } = data;

    const logoRes = await convertImageToDataURL(logo);

    // Separate private classes from regular classes
    const regularClasses = classDues.filter((due) => !due.isPrivate);
    const privateClasses = classDues.filter((due) => due.isPrivate);

    // Convert private classes to hourly class format
    const privateHourlyClasses = privateClasses.map((privateClass) => ({
        title: `${privateClass.courseTitle} (${privateClass.examPeriod || 'Χωρίς εξεταστική'})`,
        lessons: [
            {
                date: privateClass.startDate || privateClass.firstLessonDate || privateClass.createdAt || new Date(),
                hours: privateClass.attendances || 0,
                charge: (privateClass.offer || 0) * (privateClass.attendances || 0),
            },
        ],
    }));

    // Combine original hourly classes with private classes
    const allHourlyClasses = [...hourlyClassDues, ...privateHourlyClasses];

    // Sort all financial transactions by date
    const allTransactions = [
        ...generalDues.map((due) => {
            // Extract just the date part from the formatted string (which is in format "DD/MM/YYYY - HH:mm")
            const dateStr = due.createdAt ? due.createdAt.split(' - ')[0] : '';
            const date = moment(dateStr, 'DD/MM/YYYY');
            return {
                type: 'generalDue',
                date: date.isValid() ? date : moment(),
                formattedDate: date.isValid() ? dateStr : '--',
                amount: due.amount,
                description: '--',
                isPayment: false,
            };
        }),
        ...classDues.map((due) => {
            const date = moment(due.startDate || due.createdAt || new Date());
            // Calculate amount based on class type and status
            let amount = 0;
            if (due.isPrivate) {
                amount = (due.offer || 0) * (due.attendances || 0);
            } else if (
                due.requestStatus > 2 &&
                due.requestStatus !== 5 && // Utils.RequestStatus.Canceled
                due.requestStatus !== 6
            ) {
                // Utils.RequestStatus.NotServed
                amount = due.offer || 0;
            }

            return {
                type: 'classDue',
                date: date.isValid() ? date : moment(),
                formattedDate: safeFormatDate(due.startDate || due.createdAt || new Date()),
                amount: amount,
                description: `${due.courseTitle} (${due.examPeriod || 'Χωρίς εξεταστική'})`,
                isPayment: false,
            };
        }),
        ...hourlyClassDues.flatMap((hourlyClass) =>
            hourlyClass.lessons.map((lesson) => {
                const date = moment(lesson.date);
                return {
                    type: 'hourlyClassDue',
                    date: date.isValid() ? date : moment(),
                    formattedDate: safeFormatDate(lesson.date),
                    amount: lesson.charge,
                    description: `${hourlyClass.title} - ${lesson.hours} ώρες`,
                    isPayment: false,
                };
            })
        ),
        ...privateClasses.map((privateClass) => {
            const date = moment(privateClass.startDate || privateClass.firstLessonDate || privateClass.createdAt || new Date());
            const amount = (privateClass.offer || 0) * (privateClass.attendances || 0);
            return {
                type: 'hourlyClassDue',
                date: date.isValid() ? date : moment(),
                formattedDate: safeFormatDate(privateClass.startDate || privateClass.firstLessonDate || privateClass.createdAt || new Date()),
                amount: amount,
                description: `${privateClass.courseTitle} (${privateClass.examPeriod || 'Χωρίς εξεταστική'}) - ${privateClass.attendances || 0} ώρες`,
                isPayment: false,
            };
        }),
        ...payments.map((payment) => {
            const date = moment(Utils.datetimeToDate(payment.createdAt));
            return {
                type: 'payment',
                date: date.isValid() ? date : moment(),
                formattedDate: safeFormatDate(Utils.datetimeToDate(payment.createdAt)),
                amount: payment.amount,
                description: 'Πληρωμή',
                isPayment: true,
            };
        }),
    ];

    // Sort by date
    allTransactions.sort((a, b) => a.date - b.date);

    // Create the document definition
    const docDefinition = {
        content: [
            {
                columns: [
                    [
                        {
                            image: logoRes,
                            width: 180,
                        },
                    ],
                    [
                        {
                            text: 'ΟΙΚΟΝΟΜΙΚΗ ΚΑΡΤΕΛΑ ΜΑΘΗΤΗ',
                            fontSize: 16,
                            color: '#4487D4',
                            bold: true,
                            alignment: 'right',
                            margin: [0, 0, 0, 20],
                        },
                        {
                            text: 'ΗΜΕΡΟΜΗΝΙΑ: ' + moment().format('DD/MM/YYYY'),
                            fontSize: 12,
                            bold: false,
                            alignment: 'right',
                        },
                    ],
                ],
            },
            {
                margin: [0, 30, 0, 0],
                columns: [
                    [
                        {
                            text: 'ΣΤΟΙΧΕΙΑ ΦΡΟΝΤΙΣΤΗΡΙΟΥ',
                            bold: true,
                            color: '#4487D4',
                            margin: [0, 0, 0, 10],
                            fontSize: 14,
                        },
                        { text: company.row1, fontSize: 10, bold: true, margin: [0, 0, 0, 5] },
                        { text: company.row2, fontSize: 10, margin: [0, 0, 0, 5] },
                        { text: company.row3, fontSize: 10, margin: [0, 0, 0, 5] },
                        { text: company.row4, fontSize: 10, margin: [0, 0, 0, 5] },
                        { text: company.row5, fontSize: 10 },
                    ],
                    [
                        {
                            text: 'ΣΤΟΙΧΕΙΑ ΜΑΘΗΤΗ',
                            bold: true,
                            color: '#4487D4',
                            margin: [0, 0, 0, 10],
                            fontSize: 14,
                        },
                        { text: studentInfo.username, fontSize: 10, bold: true, margin: [0, 0, 0, 5] },
                        { text: 'Email: ' + studentInfo.email, fontSize: 10, margin: [0, 0, 0, 5] },
                        { text: 'Τηλ: ' + studentInfo.mobile, fontSize: 10 },
                    ],
                ],
            },
            {
                text: 'ΑΝΑΛΥΤΙΚΗ ΚΑΤΑΣΤΑΣΗ ΟΙΚΟΝΟΜΙΚΩΝ ΚΙΝΗΣΕΩΝ',
                bold: true,
                fontSize: 14,
                color: '#4487D4',
                margin: [0, 30, 0, 10],
            },
            {
                table: {
                    headerRows: 1,
                    widths: ['auto', '*', 'auto', 'auto'],
                    body: [
                        [
                            { text: 'Ημερομηνία', style: 'tableHeader' },
                            { text: 'Περιγραφή', style: 'tableHeader' },
                            { text: 'Χρέωση', style: 'tableHeader' },
                            { text: 'Πίστωση', style: 'tableHeader' },
                        ],
                        ...allTransactions.map((transaction) => [
                            transaction.formattedDate,
                            transaction.description,
                            transaction.isPayment ? '' : Utils.toEUR(transaction.amount),
                            transaction.isPayment ? Utils.toEUR(transaction.amount) : '',
                        ]),
                    ],
                },
                layout: {
                    fillColor: function (i, node) {
                        return i === 0 ? '#E9EAEB' : null;
                    },
                },
            },
            {
                margin: [0, 30, 0, 0],
                columns: [
                    [
                        { text: 'Σύνολο Χρεώσεων:', alignment: 'left', margin: [0, 0, 10, 5], fontSize: 12 },
                        { text: 'Σύνολο Πληρωμών:', alignment: 'left', fontSize: 12, margin: [0, 0, 10, 5] },
                        { text: 'Τρέχον Υπόλοιπο:', alignment: 'left', fontSize: 12, margin: [0, 0, 10, 5], bold: true },
                    ],
                    [
                        { text: Utils.toEUR(sumGeneralDues + sumClassDues + sumHourlyClassDues), bold: false, margin: [0, 0, 0, 5], fontSize: 12 },
                        { text: Utils.toEUR(sumPayments), bold: false, margin: [0, 0, 0, 5], fontSize: 12 },
                        { text: Utils.toEUR(totalDues), bold: true, color: totalDues > 0 ? '#FF0000' : '#008000', margin: [0, 0, 0, 5], fontSize: 12 },
                    ],
                ],
            },
            {
                text: 'ΑΝΑΛΥΤΙΚΗ ΚΑΤΑΣΤΑΣΗ ΑΝΑ ΚΑΤΗΓΟΡΙΑ',
                bold: true,
                fontSize: 14,
                color: '#4487D4',
                margin: [0, 30, 0, 10],
            },
            // Επιπλέον χρεώσεις
            {
                text: 'Α. ΕΠΙΠΛΕΟΝ ΧΡΕΩΣΕΙΣ',
                bold: true,
                fontSize: 12,
                margin: [0, 10, 0, 5],
            },
            generalDues.length > 0
                ? {
                      table: {
                          headerRows: 1,
                          widths: ['auto', '*', 'auto'],
                          body: [
                              [
                                  { text: 'Ημερομηνία', style: 'tableHeader' },
                                  { text: 'Αιτιολογία', style: 'tableHeader' },
                                  { text: 'Ποσό', style: 'tableHeader' },
                              ],
                              ...generalDues.map((due) => {
                                  // Extract just the date part from the formatted string
                                  const dateStr = due.createdAt ? due.createdAt.split(' - ')[0] : '--';
                                  return [dateStr, '--', Utils.toEUR(due.amount)];
                              }),
                          ],
                      },
                      layout: {
                          fillColor: function (i, node) {
                              return i === 0 ? '#E9EAEB' : null;
                          },
                      },
                  }
                : { text: 'Δεν υπάρχουν επιπλέον χρεώσεις.', italics: true, margin: [0, 0, 0, 10] },

            // Χρεώσεις από κανονικά τμήματα
            {
                text: 'Β. ΧΡΕΩΣΕΙΣ ΑΠΟ ΚΑΝΟΝΙΚΑ ΤΜΗΜΑΤΑ',
                bold: true,
                fontSize: 12,
                margin: [0, 20, 0, 5],
            },
            regularClasses.length > 0
                ? {
                      table: {
                          headerRows: 1,
                          widths: ['auto', '*', 'auto', 'auto'],
                          body: [
                              [
                                  { text: 'Ημερομηνία', style: 'tableHeader' },
                                  { text: 'Μάθημα', style: 'tableHeader' },
                                  { text: 'Εξεταστική Περίοδος', style: 'tableHeader' },
                                  { text: 'Χρέωση', style: 'tableHeader' },
                              ],
                              ...regularClasses.map((due) => {
                                  // Calculate the correct amount based on class status
                                  let amount = 0;
                                  if (
                                      due.requestStatus > 2 &&
                                      due.requestStatus !== 5 && // Utils.RequestStatus.Canceled
                                      due.requestStatus !== 6
                                  ) {
                                      // Utils.RequestStatus.NotServed
                                      amount = due.offer || 0;
                                  }

                                  return [safeFormatDate(due.startDate || due.firstLessonDate || due.createdAt), due.courseTitle, due.examPeriod || '-', Utils.toEUR(amount)];
                              }),
                          ],
                      },
                      layout: {
                          fillColor: function (i, node) {
                              return i === 0 ? '#E9EAEB' : null;
                          },
                      },
                  }
                : { text: 'Δεν υπάρχουν χρεώσεις από κανονικά τμήματα.', italics: true, margin: [0, 0, 0, 10] },

            // Χρεώσεις από τμήματα με χρέωση με την ώρα
            {
                text: 'Γ. ΧΡΕΩΣΕΙΣ ΑΠΟ ΤΜΗΜΑΤΑ ΜΕ ΧΡΕΩΣΗ ΜΕ ΤΗΝ ΩΡΑ',
                bold: true,
                fontSize: 12,
                margin: [0, 20, 0, 5],
            },
            allHourlyClasses.length > 0
                ? allHourlyClasses
                      .map((hourlyClass) => [
                          {
                              text: hourlyClass.title,
                              bold: true,
                              margin: [0, 5, 0, 5],
                          },
                          {
                              table: {
                                  headerRows: 1,
                                  widths: ['auto', 'auto', 'auto'],
                                  body: [
                                      [
                                          { text: 'Ημερομηνία', style: 'tableHeader' },
                                          { text: 'Ώρες', style: 'tableHeader' },
                                          { text: 'Χρέωση', style: 'tableHeader' },
                                      ],
                                      ...hourlyClass.lessons.map((lesson) => [safeFormatDate(lesson.date), lesson.hours, Utils.toEUR(lesson.charge)]),
                                  ],
                              },
                              layout: {
                                  fillColor: function (i, node) {
                                      return i === 0 ? '#E9EAEB' : null;
                                  },
                              },
                              margin: [0, 0, 0, 10],
                          },
                      ])
                      .flat()
                : { text: 'Δεν υπάρχουν χρεώσεις από τμήματα με χρέωση με την ώρα.', italics: true, margin: [0, 0, 0, 10] },

            // Πληρωμές
            {
                text: 'Δ. ΠΛΗΡΩΜΕΣ',
                bold: true,
                fontSize: 12,
                margin: [0, 20, 0, 5],
            },
            payments.length > 0
                ? {
                      table: {
                          headerRows: 1,
                          widths: ['auto', 'auto'],
                          body: [
                              [
                                  { text: 'Ημερομηνία', style: 'tableHeader' },
                                  { text: 'Ποσό', style: 'tableHeader' },
                              ],
                              ...payments.map((payment) => [safeFormatDate(Utils.datetimeToDate(payment.createdAt)), Utils.toEUR(payment.amount)]),
                          ],
                      },
                      layout: {
                          fillColor: function (i, node) {
                              return i === 0 ? '#E9EAEB' : null;
                          },
                      },
                  }
                : { text: 'Δεν υπάρχουν πληρωμές.', italics: true, margin: [0, 0, 0, 10] },

            // Σύνολο
            {
                text: 'Ε. ΣΥΝΟΛΟ',
                bold: true,
                fontSize: 12,
                margin: [0, 20, 0, 5],
            },
            {
                table: {
                    headerRows: 0,
                    widths: ['*', 'auto'],
                    body: [
                        ['Σύνολο Χρεώσεων', Utils.toEUR(sumGeneralDues + sumClassDues + sumHourlyClassDues)],
                        ['Σύνολο Πληρωμών', Utils.toEUR(sumPayments)],
                        ['Τρέχον Υπόλοιπο', Utils.toEUR(totalDues)],
                    ],
                },
                layout: {
                    fillColor: function (i, node) {
                        return i === 2 ? '#E9EAEB' : null;
                    },
                },
            },
        ],
        styles: {
            tableHeader: {
                bold: true,
                fontSize: 11,
                color: 'black',
            },
        },
        defaultStyle: {
            font: 'Roboto',
        },
    };

    pdfMake.createPdf(docDefinition).download(`Οικονομική_Καρτέλα_${studentInfo.username.replace(/\s+/g, '_')}.pdf`);
}
