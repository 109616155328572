import firebase from 'firebase/compat/app';
import 'firebase/compat/database';
import 'firebase/compat/messaging';
import 'firebase/compat/firestore';
import 'firebase/compat/storage';
import useStorage from '@/composables/useStorage';
import { getMessaging, getToken, onMessage } from 'firebase/messaging';
import { getAnalytics } from 'firebase/analytics';
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check';
import 'firebase/compat/auth';
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword, createUserWithEmailAndPassword, deleteUser } from 'firebase/auth';
const { GetStorage, StorageKeys, SetStorage } = useStorage();

let messaging = {};
export default () => {
    const firebaseInit = async () => {
        console.log('firebaseInit!');
        const firebaseConfig = {
            apiKey: 'AIzaSyAOG-z8CWCm7tYhJTQlfPfSbaf1GrhAXRI',
            authDomain: 'vitali-backend.firebaseapp.com',
            databaseURL: 'https://vitali-backend-default-rtdb.europe-west1.firebasedatabase.app',
            projectId: 'vitali-backend',
            storageBucket: 'vitali-backend.appspot.com',
            messagingSenderId: '848349034674',
            appId: '1:848349034674:web:2a2022354430ceb2ffb1ab',
            measurementId: 'G-2RSEVL7SRH',
        };

        //check if firebase is initialized
        if (!firebase.apps.length) {
            // console.log('firebase is not initialized');
            const app = firebase.initializeApp(firebaseConfig);
            try {
                const analytics = getAnalytics(app);
                // Initialize Firebase Cloud Messaging and get a reference to the service
                messaging = getMessaging(app);
                let fcmToken = await getToken(messaging, { vapidKey: 'BKw44hcx_HDzIbww2td62J4KMW_vrBeG68Petf1M_mqacuVrrU6t4Sj7j8B_f_MleR6Q3_ELnI1Zv2-ZPVEK-gk' });
                if (fcmToken) {
                    SetStorage(StorageKeys.FcmToken, fcmToken);
                } else {
                    console.log('No registration token available. Request permission to generate one.');
                }
            } catch (err) {
                // console.log(err);
            }

            let auth = getAuth();
            onAuthStateChanged(auth, (user) => {
                console.log('onAuthStateChanged!!', user);
            });
        }
    };

    const firebaseSignIn = async (email, password) => {
        await firebaseInit();
        let auth = getAuth();
        try {
            let res = await signInWithEmailAndPassword(auth, email, password);
            console.log('signInWithEmailAndPassword: ', res);
        } catch (error) {
            switch (error.code) {
                case 'auth/invalid-email':
                    console.log('The email address is not valid.');
                    break;
                case 'auth/user-disabled':
                    console.log('The user account has been disabled by an administrator.');
                    break;
                case 'auth/user-not-found':
                    console.log('There is no user record corresponding to this identifier. The user may have been deleted.');
                    try {
                        let res = await createUserWithEmailAndPassword(auth, email, password);
                        console.log('createUserWithEmailAndPassword: ', res);
                    } catch (error) {
                        switch (error.code) {
                            case 'auth/email-already-in-use':
                                console.log('The email address is already in use by another account.');
                                break;
                            case 'auth/invalid-email':
                                console.log('The email address is not valid.');
                                break;
                            case 'auth/operation-not-allowed':
                                console.log('Password sign-in is disabled for this project.');
                                break;
                            case 'auth/weak-password':
                                console.log('The password is not strong enough.');
                                break;
                            default:
                                console.log('createUserWithEmailAndPassword: ', error);
                        }
                    }

                    break;
                case 'auth/wrong-password':
                    console.log('The password is invalid or the user does not have a password.');
                    break;
                default:
                    console.log('signInWithEmailAndPassword: ', error);
                    break;
            }
        }
    };

    const firebaseLogout = async () => {
        console.log('firebaseLogout');
        await firebaseInit();
        try {
            let auth = getAuth();
            auth.signOut();
        } catch (error) {
            console.log(error);
        }
    };

    const firestoreRef = () => firebase.firestore;
    const realtimeDB = () => firebase.database;
    const realtimeDBInstance = () => firebase.database();
    const ChatRooms = () => firebase.app().firestore().collection('chat_rooms');
    const ChatRoomMessages = (roomId) => ChatRooms().doc(roomId).collection('messages');
    const DeleteDbField = () => firebase.firestore.FieldValue.delete();
    const onCloudMessaging = (cb) => onMessage(messaging, cb);

    return {
        realtimeDBInstance,
        realtimeDB,
        ChatRooms,
        ChatRoomMessages,
        DeleteDbField,
        firestoreRef,
        onCloudMessaging,
        firebaseSignIn,
        firebaseLogout,
        firebaseInit,
    };
};
