<template>
    <Dialog
        v-model:visible="dialogShown"
        class="p-fluid"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :style="{ width: '60vw' }"
        :pt="{
            closeButton: {
                style: {
                    position: 'absolute',
                    top: '0.5rem',
                    right: '0.5rem',
                },
            },
        }"
    >
        <div v-if="loading" class="flex flex-row justify-content-between align-content-center w-full h-full absolute top-0 left-0 z-5 bg-black-alpha-20">
            <Vue3Lottie :animationData="loaderCircle" :loop="true" :autoPlay="true" style="width: 100%" />
        </div>
        <template #header> </template>
        <div class="flex flex-column">
            <h4>{{ headerTitle }}</h4>
            <div v-if="isAdminOrOffice" class="flex flex-row w-full h-full" style="justify-content: space-between; padding-bottom: 2rem">
                <div class="flex flex-column flex-1">
                    <span style="font-weight: bold">Συμμετέχοντες</span>
                    <div class="w-full" style="max-height: 200px; overflow-y: scroll">
                        <div v-if="state.zoomMeetingParticipants" class="flex flex-column gap-1">
                            <span v-for="p in formatZoomParticipants()" :key="p">{{ p }}</span>
                        </div>
                    </div>
                </div>

                <div class="flex flex-column flex-1">
                    <span style="font-weight: bold">Recordings</span>
                    <div class="w-full" style="max-height: 200px; overflow-y: scroll">
                        <div v-if="state.zoomRecordings" class="flex flex-column gap-2">
                            <div v-for="r in state.zoomRecordings" :key="r.id" class="flex flex-column p-2 surface-100 border-round">
                                <div class="flex align-items-center justify-content-between">
                                    <span class="font-semibold">{{ formatRecordingType(r.recording_type) }}</span>
                                    <span
                                        class="status-badge"
                                        :class="{
                                            'status-success': r.status === 'completed',
                                            'status-warning': r.status === 'processing',
                                            'status-danger': r.status === 'failed',
                                            'status-info': r.status === 'cancelled',
                                            'status-secondary': r.status === 'trash',
                                        }"
                                    >
                                        {{ formatRecordingStatus(r.status) }}
                                    </span>
                                </div>
                                <div class="text-sm text-500">{{ formatRecordingDate(r.recording_start) }} - {{ formatDuration(r.recording_start, r.recording_end) }}</div>
                                <div class="flex align-items-center gap-3 mt-2">
                                    <a :href="r.play_url" target="_blank" class="no-underline">
                                        <Button type="button" icon="pi pi-play" label="Play" class="p-button-sm" />
                                    </a>
                                    <Button
                                        v-if="isAdminOrOffice"
                                        type="button"
                                        icon="pi pi-trash"
                                        class="p-button-sm p-button-danger p-button-outlined"
                                        :disabled="r.status !== 'completed'"
                                        @click="confirmDeleteRecording(r)"
                                    />
                                    <span class="text-sm">{{ formatFileSize(r.file_size) }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="flex flex-row w-full h-full gap-4" style="justify-content: space-between">
            <div class="flex flex-column box p-2 flex-1">
                <span style="font-weight: bold">Σημερινό μάθημα</span>
                <div class="field-checkbox pt-1">
                    <Checkbox id="lessonCanceled" v-model="state.lessonCanceled" :binary="true" @change="onLessonCanceled" />
                    <label for="lessonCanceled">Το μάθημα ακυρώθηκε ή μεταφέρθηκε</label>
                </div>
                <div v-if="!state.lessonCanceled" class="field flex flex-row gap-2">
                    <div class="flex flex-column">
                        <label>Έναρξη</label>
                        <Calendar v-model="state.startDatetime" :show-time="true" :time-only="true" :stepMinute="30" />
                    </div>
                    <div class="flex flex-column">
                        <label>Λήξη</label>
                        <Calendar v-model="state.endDatetime" :show-time="true" :time-only="true" :stepMinute="30" />
                    </div>
                </div>
            </div>

            <div class="flex flex-column box p-2 flex-1">
                <div class="flex" style="font-weight: bold; padding-right: 0.4rem">Επόμενο μάθημα</div>
                <div class="field-checkbox pt-2">
                    <Checkbox id="lastLesson" v-model="state.lastLesson" :binary="true" @change="onLastLessonChanged" />
                    <label for="lastLesson"> Το επόμενο μάθημα δεν θα γίνει γιατί το τμήμα ολοκληρώθηκε</label>
                </div>
                <div v-if="!state.lastLesson" class="field-checkbox">
                    <Checkbox id="nextLessonIsLast" v-model="state.nextLessonIsLast" :binary="true" />
                    <label for="nextLessonIsLast"> Το επόμενο μάθημα θα είναι το τελευταίο του τμήματος</label>
                </div>
                <div v-if="!state.lastLesson" class="field flex flex-row align-items-start gap-2">
                    <div class="flex flex-column">
                        <label>Ημερομηνία</label>
                        <Calendar v-model="state.nextLessonStart" date-format="dd/mm/yy" placeholder="dd/mm/yyyy" :min-date="new Date()" />
                    </div>
                    <div class="flex flex-column">
                        <label>Έναρξη</label>
                        <Calendar v-model="state.nextLessonStartTime" :show-time="true" :time-only="true" :stepMinute="30" @date-select="onNextLessonStartChanged" />
                    </div>
                    <div class="flex flex-column">
                        <label>Λήξη</label>
                        <Calendar v-model="state.nextLessonEndTime" :show-time="true" :time-only="true" :stepMinute="30" />
                    </div>
                </div>
            </div>
        </div>
        <div class="p-4"></div>
        <DataTable
            ref="studentsTable"
            v-model:selection="selectedStudents"
            :total-records="totalStudents"
            :loading="loadingStudents"
            :row-hover="true"
            :value="students"
            data-key="id"
            responsive-layout="scroll"
            :rows="studentsRowsPerPage"
            :paginator="true"
        >
            <template #header> Σύνολο μαθητών: {{ totalStudents }} </template>
            <template #empty> Δεν βρέθηκαν μαθητές. </template>
            <Column v-if="allowCheck" selection-mode="multiple" header-style="width: 3rem"></Column>
            <Column header="Ονοματεπώνυμο" field="fullname" filter-field="lname" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    <a v-if="isAdmin || isOffice" class="font-medium no-underline text-left cursor-pointer" style="color: blue" @click="showStudentDialog(data.id)">{{ data.fullname }}</a>
                    <span v-else class="font-medium no-underline text-left">{{ data.fullname }}</span>
                </template>
            </Column>
            <Column v-if="isAdminOrOffice" header="Email" filter-field="email" field="email" style="min-width: 10rem" :sortable="true" :show-filter-menu="false">
                <template #body="{ data }">
                    {{ data.email }}
                </template>
            </Column>
            <Column field="status" filter-field="status" header="Κατάσταση" body-class="text-left" style="min-width: 10rem" :show-filter-menu="false">
                <template #body="{ data }">
                    <span class="status-badge" :class="`status-${data.status}`">{{ Utils.RequestStatusToString(data.status) }}</span>
                </template>
                <template #filter="{ filterModel, filterCallback }">
                    <Dropdown v-model="filterModel.value" option-label="label" :options="Utils.RequestStatuses" placeholder="Όλες" class="p-column-filter" :show-clear="true" @change="filterCallback">
                        <template #value="slotProps">
                            <span v-if="slotProps.value != null" :class="`status-badge status-${slotProps.value.value}`">{{ slotProps.value.label }}</span>
                            <span v-else>Όλες</span>
                        </template>
                        <template #option="slotProps">
                            <span class="status-badge" :class="`status-${slotProps.option.value}`">{{ slotProps.option.label }}</span>
                        </template>
                    </Dropdown>
                </template>
            </Column>
            <Column v-if="isAdmin || isOffice" header="Υπόλοιπο" field="dues" style="min-width: 10rem" :sortable="true">
                <template #body="slotProps">
                    <span class="dues-badge" :class="`status-${slotProps.data.duesType}`">{{ Utils.toEUR(slotProps.data.dues) }}</span>
                </template>
            </Column>
            <Column field="ban" filter-field="ban" header="Ενεργός" data-type="boolean" body-class="text-left" :sortable="true">
                <template #body="{ data }">
                    <i class="pi" :class="{ 'true-icon pi-check-circle': !data.ban, 'false-icon pi-times-circle': data.ban }"></i>
                </template>
            </Column>
        </DataTable>
        <div class="p-4"></div>
        <div class="field pt-4">
            <label for="comments">Σχόλια παρουσιολογίου</label>
            <Textarea id="comments" v-model="state.comments" autocomplete="off" spellcheck="off" rows="3" cols="30" />
        </div>
        <template #footer>
            <div class="flex flex-row justify-content-between w-full">
                <div class="flex flex-row">
                    <Button v-if="isAdminOrOffice" label="Επικοινωνία" icon="pi pi-envelope" class="p-button-warning w-10rem mr-3" @click="onContactTeacher" />
                </div>
                <div class="flex flex-row">
                    <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
                    <Button
                        v-if="isAdminOrOffice || (isTeacher && state.commitedBy !== 2)"
                        label="Αποθήκευση"
                        icon="pi pi-check"
                        class="p-button-primary w-10rem"
                        :loading="loading"
                        :disabled="hasIncompleteRecordings"
                        @click="handleSubmit"
                    />
                </div>
            </div>
        </template>
    </Dialog>
</template>

<script setup>
import loaderCircle from '@/assets/lottie/loaderCircle.json';
import { useToast } from 'primevue/usetoast';
import { usePopupStore } from '@/stores/PopupStore.js';
import { onMounted, reactive, ref, computed, watchEffect, toRefs } from 'vue';
import { required, integer, helpers } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
import { FilterMatchMode } from 'primevue/api';
import { useConfirm } from 'primevue/useconfirm';
import moment from 'moment-timezone';
const studentsTable = ref(null);
const studentsRowsPerPage = ref(5);
const { RPCApi, RPCMethods } = useRPC();
const loadingStudents = ref(false);
const students = ref([]);
const dialogShown = ref(false);
const headerTitle = ref('');
const loading = ref(false);
const toast = useToast();
const lessonTitle = ref('');
const selectedStudents = ref();
const lessonID = ref(null);
const classID = ref(null);
const classTitle = ref(null);
const attendancesSubmitted = ref(false);
const confirm = useConfirm();
const closeCB = ref(null);
const popupStore = usePopupStore();
const submitted = ref(false);
const allowCheck = ref(true);

const state = reactive({
    startDate: null,
    startDatetime: null,
    endDatetime: null,
    comments: null,
    teacherID: null,
    zoomURL: null,
    lastLesson: false,
    nextLessonIsLast: false,
    lessonCanceled: false,
    nextLessonStart: null,
    nextLessonStartTime: null,
    nextLessonEndTime: null,
    classroomID: null,
    zoomMeetingData: null,
    zoomMeetingParticipants: null,
    commitedBy: null,
    zoomRecordings: null,
});

const formatRecordingStatus = (status) => {
    const statusMap = {
        completed: 'Completed',
        processing: 'Processing...',
        failed: 'Failed',
        cancelled: 'Cancelled',
        trash: 'In Trash',
    };
    return statusMap[status] || status;
};

const isAdmin = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Admin;
});

const isOffice = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Office;
});

const isStudent = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Student;
});

const isTeacher = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Teacher;
});

const isAdminOrOffice = computed(() => {
    return isAdmin.value || isOffice.value;
});

const onLastLessonChanged = (e) => {
    console.log('onLastLessonChanged:' + state.lastLesson);
    if (state.lastLesson) {
        state.nextLessonStart = null;
        state.nextLessonStartTime = null;
        state.nextLessonEndTime = null;
        state.nextLessonIsLast = false;
    }
};

const onLessonCanceled = (e) => {
    console.log('onLessonCanceledChanged:' + state.lessonCanceled);
    if (state.lessonCanceled) {
        selectedStudents.value = [];
    }
};

const formatZoomParticipants = () => {
    if (state.zoomMeetingParticipants) {
        return state.zoomMeetingParticipants.map((p) => {
            let joinTime = p.join_time ? moment.utc(p.join_time).tz('Europe/Athens').format('HH:mm:ss') : '';
            let leaveTime = p.leave_time ? moment.utc(p.leave_time).tz('Europe/Athens').format('HH:mm:ss') : '';
            let duration = formatSecondsToMinutes(p.duration);
            if (p.user_email == '') return `${p.name} - | ${joinTime} - ${leaveTime} | Duration: ${duration}`;
            return `${p.name} | ${joinTime} - ${leaveTime} | Duration: ${duration}`;
        });
    }
    return [];
};

const formatSecondsToMinutes = (seconds) => {
    const duration = moment.duration(seconds, 'seconds');
    const minutes = Math.floor(duration.asMinutes());
    const remainingSeconds = duration.seconds();
    return `${minutes}m ${remainingSeconds}s`;
};

const formatZoomData = () => {
    if (state.zoomMeetingData) {
        // Ensure the start_time is in UTC
        const utcStartTime = moment.utc(state.zoomMeetingData.start_time);
        return `Meeting ID: ${state.zoomMeetingData.id} | Topic: ${state.zoomMeetingData.topic} | Host: ${state.zoomMeetingData.host_email} | Status: ${
            state.zoomMeetingData.status
        } | Start Time: ${utcStartTime.tz('Europe/Athens').format('HH:mm:ss')} | Duration: ${state.zoomMeetingData.duration} minutes`;
    }
    return '';
};

const show = async (data, onCloseCB) => {
    resetForm();
    console.log('data:', data);
    loading.value = true;
    closeCB.value = onCloseCB;
    lessonID.value = data.lessonID;
    classID.value = data.classID;
    classTitle.value = data.classTitle;
    attendancesSubmitted.value = data.commited;
    lessonTitle.value = `${data.classroom} - ${data.lessonDate}`;
    dialogShown.value = true;
    headerTitle.value = `Καταχώρηση Παρουσιών (${classTitle.value})`;
    await loadStudentsForClass(data.classID);
    await loadAttendancesForLesson(data.lessonID);
    loading.value = false;

    if (data.isPrivate) {
        selectedStudents.value = [...students.value];
        allowCheck.value = false;
    }
};

const loadAttendancesForLesson = async (lessonID) => {
    const res = await RPCApi(RPCMethods.Attendances.FetchAttendancesForLesson, { lessonID });
    console.log(res);
    if (res.status == 'ok') {
        let { attendances, lesson } = res.data.result.data;
        console.warn('lesson:', lesson);
        console.warn('attendances:', attendances);
        selectedStudents.value = students.value.filter((x) => attendances.find((s) => s.studentID === x.id));
        state.lessonCanceled = lesson.lessonCanceled === 1;
        state.comments = lesson.comments;
        state.teacherID = lesson.teacherID;
        let startedAt = lesson.teacherHoursStart ? Utils.datetimeToDate(lesson.teacherHoursStart) : Utils.datetimeToDate(lesson.startedAt);
        let endedAt = lesson.teacherHoursEnd ? Utils.datetimeToDate(lesson.teacherHoursEnd) : Utils.datetimeToDate(lesson.endedAt);
        state.startDate = Utils.datetimeToDate(lesson.startedAt);
        state.startDatetime = startedAt;
        state.endDatetime = endedAt;
        state.lastLesson = lesson.lastLesson === 1;
        state.nextLessonStart = Utils.datetimeToDate(lesson.nextAtStart);
        state.nextLessonStartTime = Utils.datetimeToDate(lesson.nextAtStart);
        state.nextLessonEndTime = Utils.datetimeToDate(lesson.nextAtEnd);
        state.zoomURL = lesson.zoomURL;
        state.classroomID = lesson.classroomID;
        state.zoomMeetingData = lesson.zoomMeetingData;
        state.zoomMeetingParticipants = lesson.zoomMeetingParticipants?.filter((p) => p.status === 'in_meeting');
        state.commitedBy = lesson.commitedBy;
        state.zoomRecordings = lesson.zoomRecordings;
        state.nextLessonIsLast = lesson.nextLessonIsLast === 1;
    }
};

const onHide = (action = false) => {
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const onContactTeacher = () => {
    console.log('onContactTeacher');
    popupStore.show(Utils.Popups.NotificationDialog, { userID: state.teacherID, classTitle: classTitle.value, userForcedTemplate: 18 }, (action) => {
        console.log('onNotificationDialogClosed:' + action);
    });
};

const handleSubmit = async () => {
    submitted.value = true;

    loading.value = true;
    let res;

    //if the date are null return
    if (state.startDate == null || state.startDatetime == null || state.endDatetime == null) {
        confirm.require({
            message: 'Λείπουν πληροφορίες για την καταχώρηση των παρουσιών!',
            header: 'Προσοχή!',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'OK',
            rejectLabel: 'Άκυρο',
        });
        loading.value = false;
        return;
    }

    if (selectedStudents.value.find((x) => x.ban)) {
        confirm.require({
            message: 'Έχετε επιλέξει μαθητές με ban!',
            header: 'Προσοχή!',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'OK',
            rejectLabel: 'Άκυρο',
        });
        loading.value = false;
        return;
    }

    //check if the lesson has recordings
    if (state.zoomRecordings && state.zoomRecordings.length > 0) {
        console.log('state.zoomRecordings:', state.zoomRecordings);
        //check if all the recordings have status 'completed'
        let allCompleted = state.zoomRecordings.every((r) => r.status === 'completed');
        if (!allCompleted) {
            console.log('Not all recordings are completed!');
            confirm.require({
                message: 'Υπάρχουν recordings που δεν έχουν ολοκληρωθεί!',
                header: 'Προσοχή!',
                icon: 'pi pi-exclamation-triangle',
                acceptLabel: 'OK',
                rejectLabel: 'Άκυρο',
            });
            return;
        }
    }

    if (
        selectedStudents.value.find(
            (x) => x.status !== Utils.RequestStatus.Posted && x.status !== Utils.RequestStatus.OnClass && x.status !== Utils.RequestStatus.PreRegistered && x.status !== Utils.RequestStatus.Completed
        )
    ) {
        confirm.require({
            message: `Έχετε επιλέξει μαθητές χωρίς δικαίωμα παρουσιών στο μάθημα!`,
            header: 'Προσοχή!',
            icon: 'pi pi-exclamation-triangle',
            acceptLabel: 'OK',
            rejectLabel: 'Άκυρο',
        });
        loading.value = false;
        return;
    }

    if (state.lessonCanceled) {
        selectedStudents.value = [];
    }

    let startedAt = moment(state.startDate)
        .set({ hour: moment(state.startDatetime).hours(), minute: moment(state.startDatetime).minutes(), second: 0 })
        .toDate();
    let endedAt = moment(state.startDate)
        .set({ hour: moment(state.endDatetime).hours(), minute: moment(state.endDatetime).minutes(), second: 0 })
        .toDate();
    let nextLessonStartTime = moment(state.nextLessonStart)
        .set({ hour: moment(state.nextLessonStartTime).hours(), minute: moment(state.nextLessonStartTime).minutes(), second: 0 })
        .toDate();
    let nextLessonEndTime = moment(state.nextLessonStart)
        .set({ hour: moment(state.nextLessonEndTime).hours(), minute: moment(state.nextLessonEndTime).minutes(), second: 0 })
        .toDate();
    let payload = {
        lessonID: lessonID.value,
        classID: classID.value,
        studentIds: selectedStudents.value.map((s) => s.id),
        lessonTeacherStart: startedAt,
        lessonTeacherEnd: endedAt,
        comments: state.comments,
        lastLesson: state.lastLesson,
        lessonCanceled: state.lessonCanceled,
        nextAtStart: nextLessonStartTime,
        nextAtEnd: nextLessonEndTime,
        recordings: state.zoomRecordings,
        nextLessonIsLast: state.nextLessonIsLast ? 1 : 0,
    };

    if (startedAt != null && endedAt != null && isAdminOrOffice.value) {
        let lessonRes = await RPCApi(RPCMethods.Lessons.FetchLessonInfoById, { id: payload.lessonID });
        if (lessonRes.status === 'ok') {
            const lessonData = lessonRes.data.result.data;
            let lessonPayload = {
                id: payload.lessonID,
                classID: lessonData.classID,
                classroomID: lessonData.classroomID,
                teacherID: lessonData.teacherID,
                zoomMeeting: null, // dont update the zoom meeting, just the teacher hours
                preserveAttendances: true,
                startedAt: payload.lessonTeacherStart,
                endedAt: payload.lessonTeacherEnd,
            };

            await RPCApi(RPCMethods.Lessons.UpdateLessonForClass, lessonPayload);
        }
    }

    res = await RPCApi(RPCMethods.Attendances.UpdateForLesson, payload);
    if (res.status !== 'ok') {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε ξανά!', life: 3000 });
        loading.value = false;
        return;
    }

    const { futureLessonExists } = res.data.result.data;
    console.log('futureLessonExists:', futureLessonExists);

    if (state.nextLessonStart != null && state.nextLessonStartTime != null && state.nextLessonEndTime != null && isAdminOrOffice.value && !futureLessonExists) {
        console.log('Open new lesson dialog');
        popupStore.show(
            Utils.Popups.LessonDialog,
            {
                classID: payload.classID,
                teacherID: state.teacherID,
                classroomID: state.classroomID,
                zoomURL: state.zoomURL,
                teacherStartTime: payload.nextAtStart,
                teacherEndTime: payload.nextAtEnd,
            },
            async (action, lessonData) => {
                if (payload) {
                    onHide(true);
                    normalLessonNotify({
                        ...lessonData,
                        classID: payload.classID,
                        classTitle: classTitle.value,
                    });
                }
            }
        );
    }

    toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });
    loading.value = false;

    onHide(true);
};

const normalLessonNotify = (lessonData) => {
    popupStore.show(
        Utils.Popups.NotificationDialog,
        { classID: lessonData.classID, classTitle: lessonData.classTitle, lessonStartAt: lessonData.startedAt, zoomMeetingURL: lessonData.zoomMeetingURL, lessonID: lessonData.lessonID },
        (action) => {}
    );
};

const resetForm = () => {
    for (let field in state) {
        state[field] = null;
    }

    submitted.value = false;
    loading.value = false;
    allowCheck.value = true;
};

const loadStudentsForClass = async (classID) => {
    loadingStudents.value = true;
    students.value = [];
    let res = await RPCApi(RPCMethods.Class.FetchStudents, {
        classID,
        showStopped: false,
    });

    let d = [];
    if (res.status == 'ok') {
        const { students: studentsData } = res.data.result.data;
        if (studentsData != null) {
            for (let obj of studentsData) {
                d.push({
                    id: obj.id,
                    fullname: obj.studentName,
                    email: obj.email,
                    mobile: obj.mobile,
                    dues: obj.dues,
                    duesType: obj.dues < 0 ? 'negative' : obj.dues > 0 ? 'positive' : 'zero',
                    ban: obj.ban === 1 ? true : false,
                    requestID: obj.requestID,
                    status: obj.requestStatus,
                    sumHoursDuration: 0,
                    lessonID: null,
                });
            }
        }

        students.value = d;
        selectedStudents.value = [...students.value].filter(
            (x) => !x.ban && (x.status === Utils.RequestStatus.Posted || x.status === Utils.RequestStatus.OnClass || x.status === Utils.RequestStatus.Completed)
        );
    } else {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε αργότερα!', life: 3000 });
    }

    console.log('loadStudentsForClass', res);
    loadingStudents.value = false;
};

const totalStudents = computed(() => {
    return students.value.length;
});

const showStudentDialog = (studentID) => {
    popupStore.show(Utils.Popups.StudentDialog, { studentID }, (action) => {
        console.log('onStudentsDialogClosed:' + action);
        if (action) loadStudentsForClass(classID.value);
    });
};

const onNextLessonStartChanged = (e) => {
    state.nextLessonEndTime = moment(state.nextLessonStartTime).add(2, 'hours').toDate();
};

const formatFileSize = (bytes) => {
    return (bytes / (1024 * 1024)).toFixed(2) + ' MB';
};

const formatRecordingDate = (dateString) => {
    return moment(dateString).format('DD/MM/YYYY HH:mm');
};

const formatDuration = (start, end) => {
    const duration = moment.duration(moment(end).diff(moment(start)));
    const minutes = Math.floor(duration.asMinutes());
    const seconds = duration.seconds();

    if (minutes < 1) {
        return `${seconds} seconds`;
    }
    return `${minutes} minutes${seconds ? ` ${seconds} seconds` : ''}`;
};

const formatRecordingType = (type) => {
    const types = {
        audio_only: 'Audio Recording',
        shared_screen_with_speaker_view: 'Screen Share with Speaker',
        shared_screen_with_gallery_view: 'Screen Share with Gallery',
        shared_screen: 'Screen Share',
        active_speaker: 'Active Speaker',
        gallery_view: 'Gallery View',
    };
    return types[type] || type;
};

const confirmDeleteRecording = (recording) => {
    confirm.require({
        message: 'Είστε σίγουροι ότι θέλετε να διαγράψετε αυτό το recording;',
        header: 'Διαγραφή Recording',
        icon: 'pi pi-exclamation-triangle',
        acceptClass: 'p-button-danger',
        acceptLabel: 'Διαγραφή',
        rejectLabel: 'Άκυρο',
        accept: () => deleteRecording(recording),
        reject: () => {}, // do nothing on reject
    });
};

const deleteRecording = async (recording) => {
    console.log('deleteRecording - dont send to upload', recording);
    state.zoomRecordings = state.zoomRecordings.filter((r) => r.id !== recording.id);
};

const hasIncompleteRecordings = computed(() => {
    if (!state.zoomRecordings) return false;
    return state.zoomRecordings.some((r) => r.status !== 'completed');
});

defineExpose({
    show,
});
</script>

<style scoped>
.box {
    border-radius: 0.75rem;
    border: 2px solid #e3e8ef;
    background: #fff;
}

.status-badge {
    padding: 0.25rem 0.5rem;
    border-radius: 4px;
    font-size: 0.875rem;
    font-weight: 500;
}
.status-success {
    background-color: #def7ec;
    color: #03543f;
}
.status-warning {
    background-color: #fef3c7;
    color: #92400e;
}
.status-danger {
    background-color: #fee2e2;
    color: #991b1b;
}
.status-info {
    background-color: #e0e7ff;
    color: #3730a3;
}
.status-secondary {
    background-color: #e5e7eb;
    color: #1f2937;
}
</style>
