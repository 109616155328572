import ExcelJS from 'exceljs';
import Utils from '@/utils/Utils';
import moment from 'moment-timezone';

// Helper function to safely format dates
function safeFormatDate(dateValue) {
    if (!dateValue) return '--';
    const date = moment(dateValue);
    return date.isValid() ? date.format('DD/MM/YYYY') : '--';
}

export default async function generateFinancialStatementExcel(data) {
    const { studentInfo, generalDues, classDues, hourlyClassDues, payments, totalDues, sumGeneralDues, sumClassDues, sumHourlyClassDues, sumPayments, studentAttendances } = data;

    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Οικονομική Καρτέλα');

    // Set some properties
    workbook.creator = 'Vitali';
    workbook.lastModifiedBy = 'Vitali WebApp';
    workbook.created = new Date();
    workbook.modified = new Date();

    // Add a title row
    worksheet.mergeCells('A1:F1');
    const titleCell = worksheet.getCell('A1');
    titleCell.value = 'ΟΙΚΟΝΟΜΙΚΗ ΚΑΡΤΕΛΑ ΜΑΘΗΤΗ';
    titleCell.font = {
        size: 16,
        bold: true,
        color: { argb: '4487D4' },
    };
    titleCell.alignment = { horizontal: 'center' };

    // Add date row
    worksheet.mergeCells('A2:F2');
    const dateCell = worksheet.getCell('A2');
    dateCell.value = `ΗΜΕΡΟΜΗΝΙΑ: ${moment().format('DD/MM/YYYY')}`;
    dateCell.font = {
        size: 12,
    };
    dateCell.alignment = { horizontal: 'center' };

    // Add student info
    worksheet.mergeCells('A4:C4');
    worksheet.getCell('A4').value = 'ΣΤΟΙΧΕΙΑ ΜΑΘΗΤΗ';
    worksheet.getCell('A4').font = {
        size: 14,
        bold: true,
        color: { argb: '4487D4' },
    };

    worksheet.getCell('A5').value = 'Ονοματεπώνυμο:';
    worksheet.getCell('B5').value = studentInfo.username;
    worksheet.getCell('B5').font = { bold: true };

    worksheet.getCell('A6').value = 'Email:';
    worksheet.getCell('B6').value = studentInfo.email;

    worksheet.getCell('A7').value = 'Τηλέφωνο:';
    worksheet.getCell('B7').value = studentInfo.mobile;

    // Add a space
    worksheet.addRow([]);

    // Add financial summary
    worksheet.mergeCells('A9:C9');
    worksheet.getCell('A9').value = 'ΣΥΝΟΨΗ ΟΙΚΟΝΟΜΙΚΩΝ ΣΤΟΙΧΕΙΩΝ';
    worksheet.getCell('A9').font = {
        size: 14,
        bold: true,
        color: { argb: '4487D4' },
    };

    worksheet.getCell('A11').value = 'Συνολικές Χρεώσεις:';
    worksheet.getCell('B11').value = sumGeneralDues + sumClassDues + sumHourlyClassDues;
    worksheet.getCell('B11').numFmt = '€#,##0.00;[Red]-€#,##0.00';

    worksheet.getCell('A12').value = 'Συνολικές Πληρωμές:';
    worksheet.getCell('B12').value = sumPayments;
    worksheet.getCell('B12').numFmt = '€#,##0.00;[Red]-€#,##0.00';

    worksheet.getCell('A13').value = 'Υπόλοιπο:';
    worksheet.getCell('B13').value = totalDues;
    worksheet.getCell('B13').numFmt = '€#,##0.00;[Red]-€#,##0.00';
    worksheet.getCell('B13').font = {
        bold: true,
        color: { argb: totalDues > 0 ? 'FF0000' : '008000' },
    };

    // Create a combined array of all financial transactions and attendances
    const allTransactions = [];

    // Add general dues
    generalDues.forEach((due) => {
        allTransactions.push({
            date: moment(due.createdAt, 'DD/MM/YYYY - HH:mm').toDate(),
            type: 'Γενική Χρέωση',
            description: due.reason,
            amount: due.amount,
            balance: null, // Will calculate later
            classInfo: '',
            hours: '',
        });
    });

    // Add class dues - only for non-private classes
    classDues.forEach((due) => {
        // Only add class dues for non-private classes with valid status
        if (!due.isPrivate && due.requestStatus > 2 && due.requestStatus != 5 && due.requestStatus != 6) {
            allTransactions.push({
                date: moment(due.startDate || new Date(), 'YYYY-MM-DD').toDate(),
                type: 'Χρέωση Τμήματος',
                description: due.classTitle,
                amount: due.offer,
                balance: null,
                classInfo: '',
                hours: '',
            });
        }
    });

    // Add payments
    payments.forEach((payment) => {
        allTransactions.push({
            date: moment(payment.createdAt).toDate(),
            type: 'Πληρωμή',
            description: payment.comment || payment.code,
            amount: -payment.amount, // Negative for payments
            balance: null,
            classInfo: '',
            hours: '',
        });
    });

    // Add student attendances - for all classes (both private and non-private)
    studentAttendances.forEach((attendance) => {
        // Find the class info
        const classInfo = classDues.find((c) => c.classID === attendance.classID);
        // Only show hourly rate for private classes
        const hourlyRate = classInfo && classInfo.isPrivate ? classInfo.offer : 0;

        // Calculate amount for private classes with hourly rate
        const amount = classInfo && classInfo.isPrivate && hourlyRate > 0 ? hourlyRate * attendance.hoursDuration : 0;

        allTransactions.push({
            date: moment(attendance.date, 'DD/MM/YYYY').toDate(),
            type: 'Παρουσία',
            description: attendance.classTitle,
            amount: amount, // Use calculated amount for private classes
            balance: null,
            classInfo: attendance.classroomName,
            hours: attendance.hours,
            hourlyRate: hourlyRate,
            hoursDuration: attendance.hoursDuration,
            isPrivate: classInfo && classInfo.isPrivate,
        });
    });

    // Sort all transactions by date
    allTransactions.sort((a, b) => a.date - b.date);

    // Calculate running balance
    let runningBalance = 0;
    allTransactions.forEach((transaction) => {
        runningBalance += transaction.amount;
        transaction.balance = runningBalance;
    });

    // Add the combined transactions table
    worksheet.mergeCells('A15:G15');
    worksheet.getCell('A15').value = 'ΑΝΑΛΥΤΙΚΗ ΚΑΤΑΣΤΑΣΗ';
    worksheet.getCell('A15').font = {
        size: 14,
        bold: true,
        color: { argb: '4487D4' },
    };

    // Add headers
    worksheet.getCell('A17').value = 'Ημερομηνία';
    worksheet.getCell('B17').value = 'Τύπος';
    worksheet.getCell('C17').value = 'Περιγραφή';
    worksheet.getCell('D17').value = 'Αίθουσα';
    worksheet.getCell('E17').value = 'Ώρες';
    worksheet.getCell('F17').value = 'Χρέωση/Ώρα';
    worksheet.getCell('G17').value = 'Ποσό';
    worksheet.getCell('H17').value = 'Υπόλοιπο';

    // Style headers
    ['A17', 'B17', 'C17', 'D17', 'E17', 'F17', 'G17', 'H17'].forEach((cell) => {
        worksheet.getCell(cell).font = {
            bold: true,
            color: { argb: 'FFFFFF' },
        };
        worksheet.getCell(cell).fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: '4487D4' },
        };
        worksheet.getCell(cell).alignment = {
            horizontal: 'center',
            vertical: 'middle',
        };
    });

    // Add data
    let rowIndex = 18;
    allTransactions.forEach((transaction) => {
        worksheet.getCell(`A${rowIndex}`).value = moment(transaction.date).format('DD/MM/YYYY');
        worksheet.getCell(`B${rowIndex}`).value = transaction.type;
        worksheet.getCell(`C${rowIndex}`).value = transaction.description;
        worksheet.getCell(`D${rowIndex}`).value = transaction.classInfo || '';
        worksheet.getCell(`E${rowIndex}`).value = transaction.hours || '';

        if (transaction.type === 'Παρουσία' && transaction.hourlyRate) {
            worksheet.getCell(`F${rowIndex}`).value = transaction.hourlyRate;
            worksheet.getCell(`F${rowIndex}`).numFmt = '€#,##0.00';
        } else {
            worksheet.getCell(`F${rowIndex}`).value = '';
        }

        // Show amount for private classes with hourly rate, but not for normal classes
        if (transaction.type === 'Παρουσία') {
            if (transaction.isPrivate && transaction.hourlyRate) {
                worksheet.getCell(`G${rowIndex}`).value = transaction.amount;
                worksheet.getCell(`G${rowIndex}`).numFmt = '€#,##0.00;[Red]-€#,##0.00';
            } else {
                // Leave amount field empty for attendance entries of normal classes
                worksheet.getCell(`G${rowIndex}`).value = '';
            }
        } else {
            worksheet.getCell(`G${rowIndex}`).value = transaction.amount;
            worksheet.getCell(`G${rowIndex}`).numFmt = '€#,##0.00;[Red]-€#,##0.00';
        }

        worksheet.getCell(`H${rowIndex}`).value = transaction.balance;
        worksheet.getCell(`H${rowIndex}`).numFmt = '€#,##0.00;[Red]-€#,##0.00';

        // Style rows
        if (transaction.type === 'Πληρωμή') {
            worksheet.getCell(`G${rowIndex}`).font = {
                color: { argb: '008000' }, // Green for payments
            };
        } else if (transaction.type === 'Παρουσία') {
            // Light blue background for attendance rows
            ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'].forEach((col) => {
                worksheet.getCell(`${col}${rowIndex}`).fill = {
                    type: 'pattern',
                    pattern: 'solid',
                    fgColor: { argb: 'E6F2FF' },
                };
            });
        }

        rowIndex++;
    });

    // Set column widths
    worksheet.getColumn('A').width = 15; // Date
    worksheet.getColumn('B').width = 15; // Type
    worksheet.getColumn('C').width = 30; // Description
    worksheet.getColumn('D').width = 20; // Classroom
    worksheet.getColumn('E').width = 15; // Hours
    worksheet.getColumn('F').width = 15; // Hourly Rate
    worksheet.getColumn('G').width = 15; // Amount
    worksheet.getColumn('H').width = 15; // Balance

    // Generate Excel file
    const buffer = await workbook.xlsx.writeBuffer();
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, `Οικονομική_Καρτέλα_${studentInfo.username}.xlsx`);
}
