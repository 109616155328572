<template>
    <div class="main-area">
        <chat-window
            :height="chatH"
            :styles="styles"
            :current-user-id="myUserInfo.id"
            :room-id="roomId"
            :rooms="loadedRooms"
            :loading-rooms="loadingRooms"
            :messages="messages"
            :messages-loaded="messagesLoaded"
            :rooms-loaded="allRoomsLoaded"
            :room-actions="roomActions"
            :menu-actions="menuActions"
            :show-files="true"
            :message-actions="messageActions"
            :room-message="roomMessage"
            :text-messages="{
                ROOMS_EMPTY: 'Δεν υπάρχουν δωμάτια',
                ROOM_EMPTY: 'Δεν υπάρχει συνομιλία σε εξέλιξη',
                NEW_MESSAGES: 'Νέα μηνύματα',
                MESSAGE_DELETED: 'Αυτό το μήνυμα διαγράφηκε',
                MESSAGES_EMPTY: 'Κανένα μήνυμα',
                CONVERSATION_STARTED: 'Η συνομιλία ξεκίνησε :',
                TYPE_MESSAGE: 'Πληκτρολογήστε το μήνυμά σας',
                SEARCH: 'Εύρεση',
                IS_ONLINE: 'είναι online',
                LAST_SEEN: 'τελευταίο login ',
                IS_TYPING: 'πληκτρολογεί...',
            }"
            :show-add-room="false"
            :username-options="usernameOptions"
            :show-audio="false"
            :message-selection-actions="messageSelectionActions"
            @fetch-more-rooms="fetchMoreRooms"
            @fetch-messages="fetchMessages"
            @send-message="sendMessage"
            @edit-message="editMessage"
            @delete-message="deleteMessage"
            @open-file="openFile"
            @room-action-handler="menuActionHandler"
            @menu-action-handler="menuActionHandler"
            @send-message-reaction="sendMessageReaction"
        >
            <template #rooms-header>
                <div class="flex flex-column p-2 gap-2">
                    <span v-if="!isPriveOnlyChat" style="font-weight: 600">Σύνολο δωματίων: {{ roomsLoadedCount }}</span>
                    <Dropdown
                        v-if="isAdminOrOffice && !isPriveOnlyChat"
                        v-model="roomsSelectedFilter"
                        :options="roomsFilterOptions"
                        :option-label="(option) => option.label"
                        :option-value="(option) => option.id"
                        placeholder="Φίλτρο"
                        class="flex w-full"
                    >
                    </Dropdown>
                    <div class="flex flex-column gap-2 w-full">
                        <AutoComplete
                            v-if="isAdmin && !isPriveOnlyChat"
                            v-model="selectedChatUser"
                            :suggestions="filteredChatUsers"
                            force-selection
                            :field="userLblFunction"
                            placeholder="Ονοματεπώνυμο"
                            :delay="600"
                            :pt="{
                                input: { style: { width: '100%' } },
                            }"
                            style="width: 100%"
                            @item-select="onUserSelect"
                            @complete="searchUsersByName($event)"
                        >
                        </AutoComplete>
                        <Button v-if="isAdmin" class="flex w-full" label="Νέα συνομιλία" :disabled="selectedChatUser == null && isAdmin" icon="pi pi-plus" @click="askCreateFreshRoom"></Button>
                    </div>
                </div>
            </template>
            <template #room-header-info="slotProps">
                <div class="flex flex-column p-2 gap-2">
                    <a v-if="slotProps.room?.pickedUser" href="#" @click.stop="onShowProfile(slotProps.room)">{{ slotProps.room?.pickedUser?.username }} </a>
                    <a v-else-if="isAdmin" href="#" @click.stop="onShowProfile(slotProps.room)">{{ slotProps.room?.roomName }}</a>
                    <span v-else>{{ slotProps.room?.roomName }}</span>
                </div>
            </template>
        </chat-window>
    </div>
</template>

<script setup>
import useRPC from '@/composables/useRPC';
import { ref, computed, onMounted, onUnmounted, watch } from 'vue';
import ChatWindow from 'vue-advanced-chat';
import logo from '@/assets/images/logo.png';
import useFirebase from '@/composables/useFirebase';
import { useToast } from 'primevue/usetoast';
import 'vue-advanced-chat/dist/vue-advanced-chat.css';
import Utils from '@/utils/Utils';
import ChatUser from '@/components/ChatUser.vue';
import { usePopupStore } from '@/stores/PopupStore';
import { useConfirm } from 'primevue/useconfirm';
import { useDebounceFn } from '@vueuse/core';

const filteredChatUsers = ref([]);
const selectedChatUser = ref(null);
const confirm = useConfirm();
const popupStore = usePopupStore();
const { DeleteFileInFolder, UploadFileInFolder } = useRPC();
const { RPCApi, RPCMethods } = useRPC();
const { firestoreRef, ChatRoomMessages, DeleteDbField, ChatRooms, realtimeDB, realtimeDBInstance } = useFirebase();
const toast = useToast();
const availableUsers = ref([]);
const roomsPerPage = ref(100);
const rooms = ref([]);
const roomId = ref('');
const startRooms = ref(null);
const endRooms = ref(null);
const roomsLoaded = ref(false);
const loadingRooms = ref(true);
const loadingLastMessageByRoom = ref(0);
const roomsLoadedCount = ref(false);
const selectedRoom = ref(null);
const messagesPerPage = ref(20);
const messages = ref([]);
const messagesLoaded = ref(false);
const roomMessage = ref('');
const startMessages = ref(null);
const endMessages = ref(null);
const roomsListeners = ref([]);
const listeners = ref([]);
const roomActions = ref([{ name: 'deleteRoom', title: 'Διαγραφή Δωματίου' }]);
// const menuActions = ref([{ name: 'deleteRoom', title: 'Διαγραφή Δωματίου' }]);
// const roomActions = ref([]);
const roomsFilterOptions = ref([
    { id: 0, label: 'Όλα' },
    { id: Utils.Roles.Teacher, label: 'Καθηγητές' },
    { id: Utils.Roles.Student, label: 'Μαθητές' },
    { id: Utils.Roles.Office, label: 'Office' },
]);
const menuActions = ref([]);
const messageActions = ref([
    {
        name: 'replyMessage',
        title: 'Απάντηση',
    },
    {
        name: 'editMessage',
        title: 'Τροποποίηση',
        onlyMe: true,
    },
    {
        name: 'deleteMessage',
        title: 'Διαγραφή',
        onlyMe: true,
    },
    // {
    //     name: 'selectMessages',
    //     title: 'Επιλογή',
    // },
]);

const onShowProfile = (room) => {
    if (room.pickedUser) {
        const userID = room?.pickedUser?._id;
        const role = room?.pickedUser?.role;
        console.warn('userID:' + userID + ' role: ' + role);
        if (role === Utils.Roles.Student) popupStore.show(Utils.Popups.StudentDialog, { studentID: userID }, () => {});
        else if (role === Utils.Roles.Teacher) popupStore.show(Utils.Popups.TeacherDialog, { teacherID: userID }, () => {});
    } else {
        const userID = room.usersInfo.find((u) => u._id !== Utils.UserInfo.id)?._id;
        const role = room.usersInfo.find((u) => u._id !== Utils.UserInfo.id)?.role;
        console.warn('userID:' + userID + ' role: ' + role);
        if (role === Utils.Roles.Student) popupStore.show(Utils.Popups.StudentDialog, { studentID: userID }, () => {});
        else if (role === Utils.Roles.Teacher) popupStore.show(Utils.Popups.TeacherDialog, { teacherID: userID }, () => {});
    }
};

const props = defineProps({
    chatH: {
        type: String,
        default: '80vh',
    },
    priveOnlyUser: {
        type: Object,
        default: null,
    },
});

const messageSelectionActions = ref([
    {
        name: 'deleteMessages',
        title: 'Διαγραφή',
    },
    {
        name: 'forwardMessages',
        title: 'Προώθηση',
    },
]);
const usernameOptions = ref({ minUsers: 0, currentUser: false });
const styles = ref({ container: { borderRadius: '4px' } });
const roomsSelectedFilter = ref(roomsFilterOptions.value[0].id);
const allRoomsLoaded = computed(() => {
    return roomsLoaded.value && rooms.value.length === roomsLoadedCount.value;
});

const isPriveOnlyChat = computed(() => {
    return props.priveOnlyUser != null;
});

const loadedRooms = computed(() => {
    return rooms.value.slice(0, roomsLoadedCount.value);
});

const isAdmin = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Admin;
});

const isOffice = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Office;
});

const isStudent = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Student;
});

const isTeacher = computed(() => {
    return Utils.UserInfo.role === Utils.Roles.Teacher;
});

const isAdminOrOffice = computed(() => {
    return isAdmin.value || isOffice.value;
});

const storagePath = computed(() => {
    return 'chat/files';
});

const myUserInfo = computed(() => {
    return {
        id: Utils.UserInfo.id,
        email: Utils.UserInfo.email,
        username: Utils.UserInfo.fname + ' ' + Utils.UserInfo.lname,
        role: Utils.UserInfo.role,
    };
});

const usersInSelectedRoom = computed(() => {
    const room = rooms.value.find((r) => r.roomId === selectedRoom.value);
    if (room) {
        let s = [];
        for (let user of room.users) {
            if (isStudent.value && user.role === Utils.Roles.Student && user._id !== myUserInfo.value.id) continue;
            const statusRef = realtimeDBInstance().ref('/status/' + user._id);
            statusRef.on('value', (snapshot) => {
                if (snapshot.exists()) {
                    user.status = snapshot.val().state;
                }
            });
            s.push(user);
        }

        return s.sort((a, b) => {
            if (a.role === Utils.Roles.Teacher) return -1;
            return 0;
        });
    }
    return null;
});

watch(
    () => roomsSelectedFilter.value,
    async (value) => {
        if (value === null) return;
        await fetchRooms();
    }
);

const searchUsersByName = useDebounceFn(async (event) => {
    if (event.query.trim().length === 0) {
        filteredChatUsers.value = [];
        return;
    }

    console.log('search users: ' + event.query);
    let res = await RPCApi(RPCMethods.User.FetchUsersByName, { name: event.query.trim() });
    console.log('res:', res);
    if (res.status !== 'ok') {
        filteredChatUsers.value = [];
        return;
    }

    const { users } = res.data.result.data;
    let r = [];
    for (let user of users) {
        if (myUserInfo.value.role === Utils.Roles.Student && user.role === Utils.Roles.Student) continue;
        if (myUserInfo.value.role === Utils.Roles.Teacher && user.role === Utils.Roles.Teacher) continue;
        r.push({
            _id: user.id,
            username: user.name,
            email: user.email,
            role: user.role,
        });
    }

    filteredChatUsers.value = r;

    console.log(filteredChatUsers.value);
}, 300);

onMounted(async () => {
    if (isStudent.value || isTeacher.value) {
        roomActions.value = [];
        menuActions.value = [];
    }

    if (!isAdminOrOffice.value) {
        roomActions.value = [];
    }

    messageActions.value = [
        {
            name: 'replyMessage',
            title: 'Απάντηση',
        },
        {
            name: 'editMessage',
            title: 'Τροποποίηση',
            onlyMe: true,
        },
        {
            name: 'deleteMessage',
            title: 'Διαγραφή',
            onlyMe: isStudent.value,
        },
    ];

    if (props.priveOnlyUser != null) {
        console.warn('priveOnlyUser:', props.priveOnlyUser);
        selectedChatUser.value = {
            _id: props.priveOnlyUser._id,
            username: props.priveOnlyUser.username,
            email: props.priveOnlyUser.email,
            role: props.priveOnlyUser.role,
        };
    }

    await fetchUsers();
    await fetchRooms();
    updateUserOnlineStatus();

    //check if user is teacher or student and if he has a room with the office
    if (isStudent.value || isTeacher.value || (isOffice.value && props.priveOnlyUser != null)) {
        let room = rooms.value.find((r) => r.users.find((u) => u.role === Utils.Roles.Office));
        if (room == null) {
            console.log('No support room! Create one!!');
            askCreateFreshRoom();
        }
    }
});

const askCreateFreshRoom = async () => {
    //ADMIN PRIVE ROOM
    if (isAdmin.value) {
        console.log('selectedChatUser.value:', selectedChatUser.value);
        createAdminPriveRoom({
            owner: {
                _id: Utils.UserInfo.id,
                username: Utils.UserInfo.fname + ' ' + Utils.UserInfo.lname,
                email: Utils.UserInfo.email,
                role: Utils.UserInfo.role,
            },
            other: { _id: selectedChatUser.value._id, username: selectedChatUser.value.username, email: selectedChatUser.value.email, role: selectedChatUser.value.role },
        });
        // selectedChatUser.value = null;
        await fetchRooms();
        return;
    }

    //SUPPORT ROOM
    if (isStudent.value || isTeacher.value) {
        let roomOwner = null;
        roomOwner = {
            _id: Utils.UserInfo.id,
            username: Utils.UserInfo.fname + ' ' + Utils.UserInfo.lname,
            email: Utils.UserInfo.email,
            role: Utils.UserInfo.role,
        };
        await createRoom({ owner: roomOwner, prive: true });
        await fetchRooms();
        return;
    }

    //OFFICE SUPPORT ROOM WITH STUDENT
    if (props.priveOnlyUser != null) {
        let priveUser = {
            _id: props.priveOnlyUser._id,
            username: props.priveOnlyUser.username,
            email: props.priveOnlyUser.email,
            role: props.priveOnlyUser.role,
        };
        await ChatRooms().add({
            usersInfo: [...availableUsers.value, priveUser],
            lastUpdated: new Date(),
            owner: Utils.UserInfo.id,
            users: [...availableUsers.value.map((u) => u._id), priveUser._id],
            prive: true,
            adminPrive: false,
        });
        await fetchRooms();
    }
};

const createRoom = async (data) => {
    let roomUsers = [];
    console.warn(availableUsers.value);
    if (availableUsers.value.find((u) => u._id === data.owner._id)) roomUsers = [...availableUsers.value];
    else roomUsers = [...availableUsers.value, data.owner];
    console.log('createRoom with users: ', roomUsers);
    let usersIds = [...roomUsers.map((u) => u._id)];
    console.log('usersIds::', usersIds);
    await ChatRooms().add({
        usersInfo: roomUsers,
        lastUpdated: new Date(),
        owner: data.owner._id,
        users: usersIds,
        prive: data.prive,
        adminPrive: false,
    });
};

const userLblFunction = (user) => {
    if (user == null || user.email == null) return '';
    switch (user.role) {
        case Utils.Roles.Student:
            return `${user.username} (Μαθητής)`;
        case Utils.Roles.Teacher:
            return `${user.username} (Καθηγητής)`;
        case Utils.Roles.Office:
            return `${user.username} (Office)`;
    }
    return `${user.username} (Admin)`;
};

const onUserSelect = async () => {
    console.log('onUserSelect', selectedChatUser.value);
};

const fetchUsers = async () => {
    availableUsers.value = [];
    let res = null;
    res = await RPCApi(RPCMethods.User.FetchOfficeAndAdmin);
    if (res.status == 'ok') {
        availableUsers.value = res.data.result.data.users.map((u) => ({
            _id: u.id,
            username: u.lname + ' ' + u.fname,
            role: u.role,
            email: u.email,
        }));
    }
};

const resetRooms = async () => {
    loadingRooms.value = true;
    loadingLastMessageByRoom.value = 0;
    roomsLoadedCount.value = 0;
    rooms.value = [];
    roomsLoaded.value = true;
    startRooms.value = null;
    endRooms.value = null;
    roomsListeners.value.forEach((listener) => listener());
    roomsListeners.value = [];
    resetMessages();
};

const resetMessages = async () => {
    messages.value = [];
    messagesLoaded.value = false;
    startMessages.value = null;
    endMessages.value = null;
    listeners.value.forEach((listener) => listener());
    listeners.value = [];
};

const fetchRooms = async () => {
    resetRooms();
    await fetchMoreRooms();
};

const fetchMoreRooms = async () => {
    console.warn('fetchMoreRooms!');
    if (endRooms.value && !startRooms.value) return (roomsLoaded.value = true);
    let query = null;
    if (isAdmin.value) {
        query = ChatRooms().where('users', 'array-contains', myUserInfo.value.id).where('adminPrive', '==', true).orderBy('lastUpdated', 'desc').limit(roomsPerPage.value);
    } else {
        if (isStudent.value || isTeacher.value) query = ChatRooms().where('users', 'array-contains', myUserInfo.value.id).orderBy('lastUpdated', 'desc').limit(roomsPerPage.value);
        else if (props.priveOnlyUser == null)
            query = ChatRooms().where('users', 'array-contains', myUserInfo.value.id).where('hasMessages', '==', true).orderBy('lastUpdated', 'desc').limit(roomsPerPage.value);
        else query = ChatRooms().where('users', 'array-contains', myUserInfo.value.id).orderBy('lastUpdated', 'desc').limit(roomsPerPage.value);
    }

    const roomList = {};
    if (startRooms.value) query = query.startAfter(startRooms.value);
    let chatRooms = await query.get();

    roomsLoaded.value = chatRooms.empty || chatRooms.size < roomsPerPage.value;
    if (startRooms.value) endRooms.value = startRooms.value;
    startRooms.value = chatRooms.docs[chatRooms.docs.length - 1];

    for (let room of chatRooms.docs) {
        console.warn('room:' + room.id);
        if (room.data() && room.data().usersInfo && room.data().usersInfo.length > 0) {
            console.log(room.data().usersInfo);
            console.log('roomsSelectedFilter.value:' + roomsSelectedFilter.value);
            let selectedRoleExists = room.data().usersInfo.find((u) => u.role === roomsSelectedFilter.value);
            if (roomsSelectedFilter.value != null && roomsSelectedFilter.value > 0 && !selectedRoleExists) {
                console.log('DONT INCLUDE ROOM: ' + room.id);
                continue;
            }
            roomList[room.id] = { ...room.data(), users: [] };
            room.data().usersInfo.forEach((userInfo) => {
                roomList[room.id].users.push(userInfo);
            });
        }
    }

    const formattedRooms = [];
    for (let key in roomList) {
        const room = roomList[key];
        fetchUnreadMessages(key);
        let roomAvatar = Utils.GetGenericRoomAvatar(key);
        let pickedUser = null;
        if (room.prive) {
            if (room.users.length > 2) {
                roomAvatar = Utils.GetGenericRoomAvatar(key);
                if (isAdminOrOffice.value) {
                    pickedUser = room.users.find((u) => u.role === Utils.Roles.Student || u.role === Utils.Roles.Teacher);
                    roomAvatar = Utils.GetGenericAvatar(pickedUser?._id);
                    room.roomName = `${pickedUser?.username}`;
                    room.pickedUser = pickedUser;
                } else {
                    room.roomName = 'Support';
                }
            } else {
                const otherUser = room.users.find((u) => u._id !== myUserInfo.value.id);
                roomAvatar = Utils.GetGenericAvatar(room.users.find((u) => u._id !== myUserInfo.value.id)?._id);
                room.roomName = otherUser?.role === Utils.Roles.Admin ? 'Admin' : otherUser?.username;
            }
        } else {
            let roomName = '';
            if (room.classID != null) {
                let classInfo = await RPCApi(RPCMethods.Class.GetClassInfoById, { id: room.classID });
                console.warn('classInfo:', classInfo);
                if (classInfo.status == 'ok') {
                    roomName = classInfo.data.result.data.class.classTitle;
                }
            }
            room.roomName = roomName;
        }

        if (props.priveOnlyUser != null && room.users.find((u) => u._id === props.priveOnlyUser._id) == null) {
            continue;
        }

        formattedRooms.push({
            ...room,
            roomId: key,
            avatar: roomAvatar,
            index: room.lastUpdated.seconds,
            lastMessage: {
                content: '',
                timestamp: formatTimestamp(new Date(room.lastUpdated.seconds), room.lastUpdated),
            },
        });
    }
    if (formattedRooms.length > 0) {
        rooms.value = [...rooms.value, ...formattedRooms];
    }
    formattedRooms.map((room) => listenLastMessage(room));
    if (rooms.value && !rooms.value.length) {
        loadingRooms.value = false;
        roomsLoadedCount.value = 0;
    }
    listenUsersOnlineStatus(formattedRooms);
    listenRooms(query);
};

const listenRooms = (query) => {
    console.warn('listenRooms...');
    const listener = query.onSnapshot(async (data) => {
        for (let room of data.docs) {
            // console.warn('room snapshot:', room.data(), room.id);
            if (rooms.value) {
                const foundRoom = rooms.value.find((r) => r.roomId === room.id);
                if (foundRoom) {
                    foundRoom.index = room.data().lastUpdated.seconds;
                } else if (
                    room.data().usersInfo != null &&
                    (roomsSelectedFilter.value == null || roomsSelectedFilter.value === 0 || room.data().usersInfo.find((u) => u.role === roomsSelectedFilter.value) != null)
                ) {
                    if (props.priveOnlyUser != null && room.data().users.find((u) => u._id === props.priveOnlyUser._id) == null) {
                        continue;
                    }
                    console.warn('new room added by other user (prive maybe):', room);
                    let users = [];
                    room.data().usersInfo.forEach((userInfo) => {
                        users.push(userInfo);
                    });
                    const roomAvatar = Utils.GetGenericRoomAvatar(room.id);
                    const formattedRoom = {
                        ...room.data(),
                        users,
                        roomId: room.id,
                        avatar: roomAvatar,
                        index: room.data().lastUpdated.seconds,
                        lastMessage: {
                            content: '',
                            timestamp: formatTimestamp(new Date(room.data().lastUpdated.seconds), room.data().lastUpdated),
                        },
                        roomName: room.data().usersInfo.find((u) => u._id === room.data().owner)?.username,
                    };
                    rooms.value.push(formattedRoom);
                    listenLastMessage(formattedRoom);
                    listenUsersOnlineStatus([formattedRoom]);
                }
            }
        }
    });

    roomsListeners.value.push(listener);
};

const listenLastMessage = async (room) => {
    let listener = null;
    listener = ChatRoomMessages(room.roomId)
        .orderBy('timestamp', 'desc')
        .limit(1)
        .onSnapshot((c) => {
            // this.incrementDbCounter('Listen Last Room Message', messages.size)
            c.forEach((message) => {
                const lastMessage = formatLastMessage(message.data());
                const roomIndex = rooms.value.findIndex((r) => room.roomId === r.roomId);
                rooms.value[roomIndex].lastMessage = lastMessage;
                rooms.value = [...rooms.value];
            });
            if (loadingLastMessageByRoom.value < rooms.value.length) {
                loadingLastMessageByRoom.value++;
                if (loadingLastMessageByRoom.value === rooms.value.length) {
                    loadingRooms.value = false;
                    roomsLoadedCount.value = rooms.value.length;
                }
            }

            fetchUnreadMessages(room.roomId);
        });

    roomsListeners.value.push(listener);
};

const fetchUnreadMessages = async (roomId) => {
    let unreadCount = 0;
    let query = ChatRoomMessages(roomId);

    const messagesSnapshot = await query.get();
    for (let i = 0; i < messagesSnapshot.docs.length; i++) {
        const message = messagesSnapshot.docs[i];
        if (!message.data().seen || !message.data().seen[myUserInfo.value.id]) {
            unreadCount++;
        }
    }

    const roomIndex = rooms.value.findIndex((r) => roomId === r.roomId);
    if (roomIndex !== -1) {
        rooms.value[roomIndex].unreadCount = unreadCount;
        rooms.value = [...rooms.value];
    }
};

const formatLastMessage = (message) => {
    if (!message.timestamp) return;
    let content = message.content;
    if (message.files?.length) {
        const file = message.files[0];
        content = `${file.name}.${file.extension || file.type}`;
    }
    return {
        ...message,
        ...{
            content: content,
            timestamp: formatTimestamp(new Date(message.timestamp.seconds * 1000), message.timestamp),
            distributed: true,
            seen: message.sender_id === myUserInfo.value.id ? message.seen : null,
            new: message.sender_id !== myUserInfo.value.id && (!message.seen || !message.seen[myUserInfo.value.id]),
        },
    };
};

const fetchMessages = async ({ room, options = {} }) => {
    console.warn('fetchMessages for room', room);

    if (options.reset) {
        resetMessages();
        roomId.value = room.roomId;
    }

    if (endMessages.value && !startMessages.value) {
        return (messagesLoaded.value = true);
    }

    // let ref = isClassChat.value ? ChatRoomMessagesForClass(room.roomId) : ChatRoomMessages(room.roomId);
    let ref = ChatRoomMessages(room.roomId);
    let query = ref.orderBy('timestamp', 'desc').limit(messagesPerPage.value);
    if (startMessages.value) query = query.startAfter(startMessages.value);
    selectedRoom.value = room.roomId;
    console.warn('room.roomId:' + room.roomId);
    query.get().then((c) => {
        // this.incrementDbCounter('Fetch Room Messages', messages.size)
        if (selectedRoom.value !== room.roomId) return;
        if (messages.value.empty || c.docs.length < messagesPerPage.value) {
            messagesLoaded.value = true;
        }
        if (startMessages.value) endMessages.value = startMessages.value;
        startMessages.value = c.docs[c.docs.length - 1];
        let listenerQuery = ref.orderBy('timestamp');
        if (startMessages.value) {
            listenerQuery = listenerQuery.startAfter(startMessages.value);
        }
        if (endMessages.value) {
            listenerQuery = listenerQuery.endAt(endMessages.value);
        }
        if (options.reset) messages.value = [];
        c.forEach((message) => {
            markRoomMessagesAsSeen(room.roomId, message.id);
            const formattedMessage = formatMessage(room, message);
            messages.value.unshift(formattedMessage);
        });
        const listener = listenerQuery.onSnapshot((snapshots) => {
            // this.incrementDbCounter('Listen Room Messages', snapshots.size)
            listenMessages(snapshots, room);
        });
        listeners.value.push(listener);
    });
};

const listenMessages = async (msgs, room) => {
    msgs.forEach((message) => {
        const formattedMessage = formatMessage(room, message);
        const messageIndex = messages.value.findIndex((m) => m._id === message.id);
        if (messageIndex === -1) {
            messages.value = messages.value.concat([formattedMessage]);
        } else {
            messages.value[messageIndex] = formattedMessage;
            messages.value = [...messages.value];
        }
    });
};

const markRoomMessagesAsSeen = async (roomId, msgID) => {
    await ChatRoomMessages(roomId)
        .doc(msgID)
        .update({
            [`seen.${myUserInfo.value.id}`]: new Date(),
        });

    fetchUnreadMessages(roomId);
};

const formatMessage = (room, message) => {
    let senderUser = room.users.find((user) => message.data().sender_id === user._id);
    const { timestamp } = message.data();
    const isSupportRoom = room.users.some((user) => user.role === Utils.Roles.Office);
    const formattedMessage = {
        ...message.data(),
        ...{
            senderId: senderUser?.role === Utils.Roles.Office && myUserInfo.value.role === Utils.Roles.Office ? myUserInfo.value.id : message.data().sender_id,
            _id: message.id,
            seconds: timestamp.seconds,
            timestamp: Utils.ParseTimestamp(timestamp, 'HH:mm'),
            date: Utils.ParseTimestamp(timestamp, 'DD MMMM YYYY'),
            username: senderUser ? (senderUser.role === Utils.Roles.Admin ? 'Admin' : senderUser.role === Utils.Roles.Office ? 'Support' : senderUser.username) : null,
            avatar: senderUser ? Utils.GetGenericAvatar(senderUser._id) : null,
            distributed: true,
        },
    };
    if (message.data().replyMessage) {
        formattedMessage.replyMessage = {
            ...message.data().replyMessage,
            // ...{
            //     senderId: message.data().replyMessage.sender_id,
            // },
        };

        console.warn('formattedMessage.replyMessage:', formattedMessage.replyMessage);
    }

    return formattedMessage;
};

const formatTimestamp = (date, timestamp) => {
    const timestampFormat = Utils.IsSameDay(date, new Date()) ? 'HH:mm' : 'DD/MM/YY';
    const result = Utils.ParseTimestamp(timestamp, timestampFormat);
    return timestampFormat === 'HH:mm' ? `Σήμερα, ${result}` : result;
};
const sendMessage = async ({ roomId, content, files, replyMessage, usersTag }) => {
    const message = {
        sender_id: myUserInfo.value.id,
        content,
        timestamp: new Date(),
    };
    message.files = [];
    if (files) {
        for (let index = 0; index < files.length; index++) {
            try {
                const fileToUpload = prepareFileForUpload(files[index]);
                const uploadRes = await UploadFileInFolder(storagePath.value, fileToUpload);
                let publicURL = uploadRes.data.data.find((f) => f.id === fileToUpload.name)?.url;
                message.files.push(formattedFile(files[index], publicURL));
            } catch (e) {
                console.error(e);
            }
        }
    }
    if (replyMessage) {
        message.replyMessage = {
            _id: replyMessage._id,
            content: replyMessage.content,
            sender_id: replyMessage.senderId,
        };
        if (replyMessage.files) {
            message.replyMessage.files = replyMessage.files;
        }
    }
    message.roomId = roomId;
    let msgRes = await ChatRoomMessages(roomId).add(message);
    markRoomMessagesAsSeen(roomId, msgRes.id);

    console.log('roomId:' + roomId);
    RPCApi(RPCMethods.Chat.NewMessage, {
        roomID: roomId,
        collection: 'chat_rooms',
        content: message.content,
    });

    ChatRooms().doc(roomId).update({ lastUpdated: new Date(), hasMessages: true });
};

const editMessage = async ({ messageId, newContent, roomId, files }) => {
    const newMessage = { edited: new Date() };
    newMessage.content = newContent;
    newMessage.files = [];
    if (files) {
        for (let index = 0; index < files.length; index++) {
            if (files[index]?.blob) {
                try {
                    const fileToUpload = prepareFileForUpload(files[index]);
                    const uploadRes = await UploadFileInFolder(storagePath.value, fileToUpload);
                    let publicURL = uploadRes.data.data.find((f) => f.id === fileToUpload.name)?.url;
                    newMessage.files.push(formattedFile(files[index], publicURL));
                } catch (e) {
                    console.error(e);
                }
            }
        }
    } else {
        newMessage.files = DeleteDbField();
    }
    await ChatRoomMessages(roomId).doc(messageId).update(newMessage);
};

const deleteMessage = async ({ message, roomId }) => {
    await ChatRoomMessages(roomId).doc(message._id).update({ deleted: new Date() });

    const { files } = message;
    if (files) {
        console.warn('delete file!');
        for (let index = 0; index < files.length; index++) {
            try {
                let file = files[index];
                await DeleteFileInFolder(storagePath.value, file.name + '.' + file.extension || file.type);
            } catch (e) {
                console.error(e);
            }
        }
    }
};

const formattedFile = (file, url) => {
    const messageFile = {
        name: file.name,
        size: file.size,
        type: file.type,
        extension: file.extension || file.type,
        url: url,
    };
    if (file.audio) {
        messageFile.audio = true;
        messageFile.duration = file.duration;
    }
    return messageFile;
};

const openFile = ({ file }) => {
    window.open(file.file.url, '_blank');
};

const menuActionHandler = ({ action, roomId }) => {
    console.log('menuActionHandler!!');
    let currentRoom = rooms.value.find((x) => x.roomId === roomId);
    console.log('currentRoom.owner:' + currentRoom.owner + ', myUserInfo id:' + myUserInfo.value.id);
    switch (action.name) {
        case 'deleteRoom':
            return deleteRoom(roomId);
    }
};

const sendMessageReaction = async ({ reaction, remove, messageId, roomId }) => {
    const dbAction = remove ? firestoreRef().FieldValue.arrayRemove(myUserInfo.value.id) : firestoreRef().FieldValue.arrayUnion(myUserInfo.value.id);
    await ChatRoomMessages(roomId)
        .doc(messageId)
        .update({
            [`reactions.${reaction.unicode}`]: dbAction,
        });
};

const updateUserOnlineStatus = () => {
    const userStatusRef = realtimeDBInstance().ref('/status/' + myUserInfo.value.id);
    const isOfflineData = {
        state: 'offline',
        lastChanged: realtimeDB().ServerValue.TIMESTAMP,
    };
    const isOnlineData = {
        state: 'online',
        lastChanged: realtimeDB().ServerValue.TIMESTAMP,
    };
    realtimeDBInstance()
        .ref('.info/connected')
        .on('value', (snapshot) => {
            if (snapshot.val() === false) return;
            userStatusRef
                .onDisconnect()
                .set(isOfflineData)
                .then(() => {
                    userStatusRef.set(isOnlineData);
                });
        });
};

const listenUsersOnlineStatus = (r) => {
    // console.log('listenUsersOnlineStatus::', r);
    r.map((room) => {
        room.users.map((user) => {
            const listener = realtimeDBInstance()
                .ref('/status/' + user._id)
                .on('value', (snapshot) => {
                    if (!snapshot || !snapshot.val()) return;
                    const lastChanged = formatTimestamp(new Date(snapshot.val().lastChanged), new Date(snapshot.val().lastChanged));
                    user.status = { ...snapshot.val(), lastChanged };
                    const roomIndex = rooms.value.findIndex((r) => room.roomId === r.roomId);
                    rooms.value[roomIndex] = room;
                    rooms.value = [...rooms.value];
                });
            roomsListeners.value.push(listener);
        });
    });
};

const deleteRoom = async (roomId) => {
    confirm.require({
        message: 'Είστε σίγουροι ότι θέλετε να διαγράψετε το συγκεκριμένο δωμάτιο;',
        header: 'Επιβεβαίωση',
        icon: 'pi pi-exclamation-triangle',
        acceptLabel: 'Διαγραφή',
        rejectLabel: 'Άκυρο',
        acceptClass: 'p-button-danger',
        rejectClass: 'p-button-secondary',
        accept: async () => {
            let ref = ChatRoomMessages(roomId);

            ref.get().then((res) => {
                if (res.empty) return;
                res.docs.map((doc) => ref.doc(doc.id).delete());
            });
            await ChatRooms().doc(roomId).delete();

            fetchRooms();
        },
        reject: () => {
            console.log('do nothing');
        },
    });
};

const prepareFileForUpload = (file) => {
    let f = {
        file: file.blob,
        name: file.name + '.' + file.extension || file.type,
        fileType: file.type,
    };
    return new File([f.file], f.name, { type: f.fileType });
};

const onUserClicked = async (user) => {
    console.log('onUserClicked::', user);
};

const createAdminPriveRoom = async (data = null) => {
    let roomUsers = [];
    const { other, owner } = data;
    roomUsers = [other, owner];
    console.log('create prive Room with users: ', roomUsers);
    let query = ChatRooms().where('users', 'array-contains', other._id).where('adminPrive', '==', true);
    const chatRooms = await query.get();
    let roomExists = false;
    chatRooms.forEach((doc) => {
        if (doc.data().users.includes(other._id)) {
            roomExists = true;
        }
    });
    if (roomExists) {
        console.log('Room already exists!');
        selectedChatUser.value = null;
        toast.add({ severity: 'info', summary: 'Προσοχή', detail: 'Το δωμάτιο υπάρχει ήδη', life: 3000 });
        return;
    }

    let usersIds = [...roomUsers.map((u) => u._id)];
    console.log('usersIds::', usersIds);
    await ChatRooms().add({
        usersInfo: roomUsers,
        lastUpdated: new Date(),
        owner: data.owner._id,
        users: usersIds,
        adminPrive: true,
        prive: true,
    });
};
</script>

<style lang="scss" scoped>
.main-area {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;

    .scroller {
        width: 30%;
        overflow-y: scroll;
        overflow-x: hidden;
        border: 1px solid #efefef;
        padding: 0.5rem;

        .virtual-scroller {
            position: relative;
            overflow: auto;
            height: 100%;
            overflow-y: hidden;
            overflow-x: hidden;

            .virtual-scroller-content {
                position: absolute;
                top: 0;
                left: 0;
                min-height: 100%;
                min-width: 100%;

                .item {
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    justify-content: center;
                    align-content: center;
                    gap: 0.5rem;
                    width: 100%;
                    height: 3rem;
                    padding: 0 5px;
                    margin-bottom: 0.8rem;
                    cursor: pointer;
                    border-radius: 0.5625rem 0rem 0rem 0.5625rem;

                    &:hover {
                        background: #efefef;
                    }

                    .username {
                        flex: 1;
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        color: #000;
                        font-size: 1rem;
                        font-style: normal;
                        line-height: normal;
                    }
                }
            }
        }
    }
}
</style>
