<template>
    <Dialog
        v-model:visible="dialogShown"
        class="p-fluid"
        :close-on-escape="false"
        :dismissable-mask="false"
        :modal="true"
        :breakpoints="{ '960px': '75vw', '640px': '95vw' }"
        :style="{ width: '40vw' }"
    >
        <template #header>
            <h4>{{ headerTitle }}</h4>
        </template>

        <div class="col-12">
            <div class="field">
                <label :class="{ 'p-error': v$.amount.$invalid && submitted }">Ποσό (σε ευρώ)*</label>
                <InputNumber
                    v-model="v$.amount.$model"
                    mode="decimal"
                    :min-fraction-digits="2"
                    locale="el-GR"
                    autocomplete="off"
                    spellcheck="off"
                    placeholder="0"
                    :class="{ 'p-invalid': v$.amount.$invalid && submitted }"
                />
                <small v-if="(v$.amount.$invalid && submitted) || v$.amount.$pending.$response" class="p-error">{{ v$.amount.required.$message }}</small>
            </div>
            <!-- <div class="field">
                <label>Τύπος δόσης</label>
                <Dropdown v-model="installmentType" option-label="label" :options="installmentOptions" placeholder="Επιλογή" @change="onTypeChanged">
                    <template #value="slotProps">
                        <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                        <span v-else>Επιλογή</span>
                    </template>
                    <template #option="slotProps">
                        <span>{{ slotProps.option.label }}</span>
                    </template>
                </Dropdown>
                <small v-if="installmentType.value === 1 && (userClasses == null || userClasses.length === 0)" class="p-error">-- Δεν έχει τμήματα! --</small>
            </div> -->
            <div v-if="installmentType.value === 1 && userClasses && userClasses.length > 0" class="field">
                <label>Για το τμήμα</label>
                <Dropdown v-model="selectedClass" option-label="label" :options="userClasses" placeholder="Επιλογή">
                    <template #value="slotProps">
                        <span v-if="slotProps.value != null">{{ slotProps.value.label }}</span>
                        <span v-else>Επιλογή</span>
                    </template>
                    <template #option="slotProps">
                        <span>{{ slotProps.option.label }}</span>
                    </template>
                </Dropdown>
            </div>
            <div v-if="installmentType.value === 0" class="field">
                <label>Πληρωμή μέχρι (ημερομηνία)</label>
                <Calendar v-model="state.dueDate" date-format="dd/mm/yy" placeholder="" show-icon />
            </div>
            <div v-else-if="userClasses && userClasses.length > 0" class="field">
                <label>Πληρωμή μέχρι (ώρες)</label>
                <InputNumber v-model="state.dueHours" mode="decimal" :min-fraction-digits="2" locale="el-GR" autocomplete="off" spellcheck="off" placeholder="" />
            </div>
        </div>

        <template #footer>
            <Button label="Άκυρο" icon="pi pi-times" class="p-button-outlined w-8rem mr-3" @click="onHide(false)" />
            <Button label="Αποθήκευση" icon="pi pi-check" class="p-button-primary w-10rem" :loading="loading" @click="handleSubmit(!v$.$invalid)" />
        </template>
    </Dialog>
</template>

<script setup>
import { useToast } from 'primevue/usetoast';
import { onMounted, reactive, ref, computed, watchEffect, toRefs } from 'vue';
import { required, email } from '@vuelidate/validators';
import { useVuelidate } from '@vuelidate/core';
import moment from 'moment-timezone';
import useRPC from '@/composables/useRPC';
import Utils from '@/utils/Utils';
const { RPCApi, RPCMethods } = useRPC();
const dialogShown = ref(false);
const submitted = ref(false);
const headerTitle = ref('');
const state = reactive({
    id: null,
    amount: null,
    dueDate: null,
    dueHours: null,
});
const rules = {
    amount: { required },
};
const v$ = useVuelidate(rules, state);
const toast = useToast();
const editMode = ref(false);
const userID = ref(null);

const installmentOptions = ref([
    {
        label: 'Ημερομηνία',
        value: 0,
    },
    {
        label: 'Ώρες μαθημάτων',
        value: 1,
    },
]);
const installmentType = ref(installmentOptions.value[0]);
const userClasses = ref([]);
const selectedClass = ref(null);
const closeCB = ref(null);

const show = async (data, onCloseCB) => {
    console.log('installment:', data);
    closeCB.value = onCloseCB;
    installmentType.value = installmentOptions.value[0];
    resetForm();
    dialogShown.value = true;
    submitted.value = false;
    editMode.value = data.id != null;
    userID.value = data.userID;
    headerTitle.value = editMode.value ? `Τροποποίηση Δόσης` : `Νέα δόση`;

    userClasses.value = [];
    const res = await RPCApi(RPCMethods.User.FetchClasses, { userId: userID.value });
    let d = [];
    if (res.status === 'ok') {
        const { classes } = res.data.result.data;
        for (let obj of classes) {
            d.push({
                label: obj.title,
                value: obj.id,
            });
        }
        userClasses.value = d;
    }

    if (editMode.value) {
        state.id = data.id;
        state.amount = data.amount;
        installmentType.value = data.classID != null ? installmentOptions.value[1] : installmentOptions.value[0];
        if (data.classID != null) {
            selectedClass.value = userClasses.value.find((x) => x.value === data.classID);
            state.dueHours = data.dueHours;
            state.dueDate = null;
        } else {
            selectedClass.value = null;
            state.dueHours = null;
            state.dueDate = Utils.datetimeToDate(data.dueDate);
        }
    }
};
const loading = ref(false);

const onHide = (action = false) => {
    console.log('onHide!!!!');
    dialogShown.value = false;
    resetForm();
    closeCB.value(action);
};

const handleSubmit = async (isFormValid) => {
    submitted.value = true;

    if (!isFormValid) return;

    console.log('installmentType.value:' + installmentType.value);
    console.log('selectedClass.value:' + selectedClass.value);

    if (installmentType.value.value === 1 && selectedClass.value == null) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Δεν έχει επιλεγεί τμήμα!', life: 3000 });
        return;
    }

    if (installmentType.value.value === 0 && state.dueDate == null) {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Δεν έχει επιλεγεί ημερομηνία!', life: 3000 });
        return;
    }

    console.log('ok submit');
    loading.value = true;
    let res;
    let payload = {
        amount: state.amount,
        dueDate: state.dueDate,
        dueHours: state.dueHours,
        userID: userID.value,
        classID: installmentType.value.value === 0 ? null : selectedClass.value?.value,
    };

    console.log(payload);

    if (editMode.value) {
        payload.id = state.id;
        res = await RPCApi(RPCMethods.Installments.Update, payload);
    } else {
        res = await RPCApi(RPCMethods.Installments.Add, payload);
    }

    if (res.status !== 'ok') {
        toast.add({ severity: 'error', summary: 'Σφάλμα', detail: 'Προσπαθήστε ξανά!', life: 3000 });
        loading.value = false;
        return;
    }
    toast.add({ severity: 'success', summary: 'Η διαδικασία ολοκληρώθηκε', detail: 'Επιτυχής διαδικασία!', life: 3000 });

    loading.value = false;
    onHide(true);
};
const resetForm = () => {
    for (let k in state) state[k] = null;

    submitted.value = false;
    loading.value = false;
};

const onTypeChanged = (data) => {
    console.log('onTypeChanged:', data);
    state.dueDate = null;
    state.dueHours = null;
};

defineExpose({
    show,
});
</script>
